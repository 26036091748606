/*-----------------------------------------------
    32. Header Cart Section CSS
-----------------------------------------------*/

.woocommerce-mini-cart {
  position: absolute;
  right: 0;
  top: 200%;
  transition: all 0.3s ease;
  width: 270px;
  opacity: 0;
  padding-top: 120px;
  visibility: hidden;
  overflow: hidden;

  .cart-bottom-part {
    padding: 30px 20px;
    background: $light-blue;
  }

  &.left-view {
    right: unset;
    left: 0;
  }

  li {
    border-bottom: 1px solid rgba(170, 170, 170, 0.25);
    padding: 0 0 20px 0 !important;
    margin: 0 0 20px 0 !important;
    margin-right: 0 !important;
    border: none !important;
    display: flex !important;
    justify-content: center;
    align-items: center;

    &:last-child {
      margin: 0 !important;
    }
  }

  .product-info {
    padding: 0 26px;

    a {
      color: $white !important;
      font-weight: 700;

      &:hover {
        color: $middle-blue !important;
      }
    }
  }

  .total-price {
    padding: 0 0 20px;
  }

  .quantity, .total-price {
    color: $light-gray;
  }

  .icon-cart {
    i {
      color: $white;
      width: 18px;
      height: 18px;
      line-height: 16px;
      border: 1px solid $white;
      border-radius: 30px;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      transition: all 0.3s ease;

      &:hover {
        color: $middle-blue;
      }
    }

    margin-top: 2px;
  }

  .product-image {
    margin-top: 2px;
    display: block;
    float: right;
    text-align: right;
    width: 56px;
  }

  .crt-btn {
    padding: 5px 10px;
    background: $middle-blue;
    color: $white;
    display: inline-block;
    border-radius: 3px;

    &:hover {
      background: $deep-blue;
      color: $white;
    }

    &.btn1 {
      margin-right: 10px;
    }
  }
}

.mini-cart-active:hover .woocommerce-mini-cart {
  visibility: visible;
  opacity: 1;
  top: 0;
}