/*----------------------------------------
    29. Shop Section CSS
----------------------------------------*/

.rs-shop-part {
  .woocommerce-result-count {
    font-size: 15px;
    line-height: 26px;
    color: $mid-gray;
    font-weight: 400;
    margin: 0;
  }

  .from-control {
    float: right;
    font-size: 15px;
    color: $mid-gray;
    font-weight: 400;
    vertical-align: top;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
  }

  .product-list {
    .image-product {
      position: relative;

      .overley i {
        position: absolute;
        bottom: 150px;
        right: 13px;
        background: transparent;
        opacity: 0;
        visibility: hidden;
        transition: 0.4s;

        &:before {
          font-size: 30px;
          color: $mid-gray;
          font-weight: 400;
          background: $white;
          border: none;
          padding: 8px 10px 8px;
          height: 45px;
          width: 45px;
          line-height: 45px;
        }
      }

      .onsale {
        font-size: 13px;
        color: $white;
        font-weight: 700;
        line-height: 40px;
        background: $middle-blue;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 13px;
        right: 13px;
      }
    }

    .content-desc {
      .loop-product-title {
        margin: 0;
        font-size: 18px;
        line-height: 50px;
        color: $mid-gray;
        font-weight: 700;

        a {
          color: $dark;
          font-size: 22px;
          font-weight: 600;

          &:hover {
            color: $light-blue;
          }
        }
      }

      .price {
        font-size: 20px;
        line-height: 30px;
        color: $light-blue;
        font-weight: 700;
        margin: 0;

        del {
          opacity: 0.6;
          padding-right: 10px;
        }
      }
    }

    &:hover .image-product .overley i {
      opacity: 1;
      visibility: visible;
      bottom: 13px;
    }
  }

  &.part2 {
    /*Shop Single CSS*/

    .img-part a {
      border: 1px solid $mid-gray;
      font-size: 15px;
      line-height: 26px;
      font-weight: 400;
      color: $mid-gray;
      background: $white;
    }

    .rs-single-product .tab-area {
      ul.nav-tabs {
        margin-bottom: 25px;
        border: none;
        border-bottom: 1px solid #e6ebee;

        li {
          margin-right: 40px;

          a {
            display: inline-block;
            font-size: 18px;
            line-height: 31px;
            font-weight: 700;
            color: $white;
            padding: 18px 40px 18px;
            background: $middle-blue;

            &.active {
              color: $mid-gray;
              background: transparent;

              &:hover {
                color: $white;
                background: $middle-blue;
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .tab-content .tab-pane {
        .tab-title {
          margin-bottom: 25px;
          font-size: 30px;
          font-weight: 600;
        }

        .dsc-p {
          margin: 0;
        }

        .reviews-grid {
          .top-area {
            margin-bottom: 5px;

            p {
              margin-bottom: 23px;
            }

            h4 {
              font-weight: 400;
              color: unset;
            }
          }

          .comment-form {
            p.comment-notes {
              margin-bottom: 15px;
            }

            .placeholder-cntrl {
              label {
                font-weight: 600;
                min-width: 100px;
              }

              input {
                border: 1px solid rgba(54, 54, 54, 0.1);
                padding: 10px 15px;
                margin-bottom: 10px;
                outline: none;
                width: 50%;
              }

              textarea {
                display: block;
                width: 100%;
                padding: 10px 15px;
                height: 75px;
                border: 1px solid rgba(54, 54, 54, 0.1);
                margin-bottom: 20px;
                outline: none;
              }
            }

            .ratings {
              font-weight: 600;
              margin-bottom: 18px;
              display: block;

              ul li {
                display: inline;

                i {
                  color: #f5d109;
                  font-weight: normal;
                }
              }
            }

            .readon {
              font-weight: 500;
              font-size: 15px;
              padding: 13px 45px;
              border: none;
              outline: none;
            }
          }
        }
      }
    }

    .content-part {
      .loop-product {
        font-size: 25px;
        line-height: 25px;
        color: $dark;
        font-weight: 700;
      }

      .price {
        font-size: 20px;
        line-height: 34px;
        color: $light-blue;
        font-weight: 800;
        display: block;
      }

      .desc-part {
        font-size: 15px;
        line-height: 26px;
        color: $mid-gray;
        font-weight: 400;
      }

      .add-cart {
        font-size: 15px;
        line-height: 25px;
        color: $white;
        font-weight: 400;
        background: $middle-blue;
        text-transform: uppercase;
        border-radius: 5px;
        padding: 10px 30px 10px;
        border: 2px solid $light-blue;

        &:hover {
          background: transparent;
          color: $light-blue;
        }
      }
    }
  }

  &.orange-color .product-list .content-desc {
    .loop-product-title a:hover, .price {
      color: $orange;
    }
  }
}