/* -----------------------------------
    12. Course Single Section CSS
-------------------------------------*/
/*Intro Section*/

.intro-section {
  position: relative;

  /*Video Column Style*/

  /*Intro Tabs Style*/

  .video-column {
    position: relative;
    z-index: 1;

    .intro-video {
      position: relative;
      text-align: center;
      width: 100%;
      display: block;

      h4 {
        position: absolute;
        width: 100%;
        text-align: center;
        color: $white;
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .intro-video-box {
        position: relative;
        width: 60px;
        height: 60px;
        z-index: 99;
        color: #00aa15;
        font-weight: 400;
        font-size: 24px;
        text-align: center;
        border-radius: 50%;
        padding-left: 7px;
        line-height: 60px;
        display: inline-block;
        background-color: $white;
        transition: all 900ms ease;
        -moz-transition: all 900ms ease;
        -webkit-transition: all 900ms ease;
        -ms-transition: all 900ms ease;
        -o-transition: all 900ms ease;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      }

      &:before {
        position: absolute;
        content: '';
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .course-features-info {
      margin: 50px 0;
      border-radius: 3px;
      padding: 30px 40px 30px;
      background: $white;
      box-shadow: 0 0 30px $box-shadow-gray;

      ul li {
        display: block;
        padding: 10px 0;

        i {
          color: $orange;
        }

        .label {
          padding-left: 10px;
        }

        .value {
          float: right;
          padding-right: 5px;
        }
      }
    }

    .btn-part {
      background: $white;
      box-shadow: 0 0 35px $box-shadow-gray;
      padding: 50px 50px 35px;
      border-radius: 5px;

      .btn {
        margin: 0 0 15px;
        width: 100%;
      }
    }
  }

  .intro-tabs {
    position: relative;
    border: none;

    .tab-btns {
      position: relative;
      text-align: center;
      width: 20%;

      .tab-btn {
        position: relative;
        display: block;
        width: 100%;
        font-size: 14px;
        background: none;
        background: $white;
        color: $gray-62;
        border: 1px solid $box-shadow-gray;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
        float: left;
        padding: 12px 40px 10px;
        text-transform: capitalize;
        transition: all 500ms ease;

        &:hover, &.active {
          color: $white;
          background: $orange;
          border-color: $orange;
        }
      }
    }

    .tabs-content {
      position: relative;
      overflow: hidden;
      background-color: $white;

      .minutes {
        position: relative;
        color: $light-blue;
        font-size: 14px;
        font-weight: 500;
        margin-top: 12px;
      }
    }
  }
}

/* Course Overview */

.course-overview {
  position: relative;

  .instructor-title {
    position: relative;
    color: $dark;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3em;
    margin-bottom: 18px;
  }

  .inner-box {
    position: relative;
    padding: 0px 40px 40px;

    h4 {
      position: relative;
      color: $dark;
      font-weight: 600;
      line-height: 1.3em;
      margin-bottom: 15px;
    }

    h3 {
      position: relative;
      color: $dark;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3em;
      margin-bottom: 18px;
    }

    p {
      position: relative;
      color: $gray-62;
      font-size: 16px;
      line-height: 1.8em;
      margin-bottom: 15px;
    }

    .student-list {
      position: relative;
      margin-top: 25px;
      margin-bottom: 30px;

      li {
        position: relative;
        color: #393939;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.3em;
        margin-right: 80px;
        display: inline-block;
        list-style: disc;
        margin-bottom: 10px;

        .fa {
          color: $orange;
          margin: 0px 2px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .review-list {
      position: relative;
      margin-bottom: 20px;

      li {
        position: relative;
        color: $gray-62;
        font-size: 16px;
        margin-bottom: 14px;
        padding-left: 30px;

        &:before {
          position: absolute;
          content: "\f101";
          left: 0px;
          top: 5px;
          color: $orange;
          font-size: 14px;
          line-height: 1em;
          font-family: "FontAwesome";
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

/*Course Review*/

.cource-review-box {
  position: relative;
  padding: 0px 40px;

  h4 {
    position: relative;
    color: $dark;
    font-weight: 700;
    line-height: 1.3em;
    margin-bottom: 15px;
  }

  .rating {
    position: relative;
    color: $gray-62;
    font-size: 16px;

    .fa {
      position: relative;
      color: $orange;
      margin: 0px 2px;
      font-size: 16px;
    }
  }

  .total-rating {
    position: relative;
    color: $orange;
    font-size: 16px;
    font-weight: 600;
    margin-right: 5px;
  }

  .text {
    position: relative;
    color: $gray-62;
    font-size: 16px;
    line-height: 1.8em;
    margin-top: 15px;
  }

  .helpful {
    position: relative;
    color: #393939;
    font-size: 16px;
    line-height: 1.8em;
    margin-top: 12px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .like-option {
    position: relative;

    li {
      position: relative;
      margin-right: 10px;
      display: inline-block;

      i {
        position: relative;
        width: 36px;
        height: 36px;
        color: $gray-62;
        text-align: center;
        line-height: 36px;
        border-radius: 50px;
        display: inline-block;
        background-color: #eaeff5;

        &:hover {
          color: $light-blue;
        }
      }
    }

    .report {
      position: relative;
      color: #333;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        color: $light-blue;
      }
    }
  }

  .more {
    position: relative;
    color: $orange;
    font-size: 14px;
    font-weight: 500;
    margin-top: 30px;
    display: inline-block;
  }
}