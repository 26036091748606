/* ------------------------------------
    14. Why Choose US Section CSS
---------------------------------------*/

.why-choose-us {
  .choose-us-part .facilities-part .single-facility {
    .icon-part {
      position: relative;
      float: left;
      margin-right: 5px;

      .shape-img {
        position: relative;
      }

      i {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $light-blue;

        &:before {
          font-size: 28px;
        }
      }
    }

    .text-part .title {
      font-size: 22px;
      color: $dark;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  &.style2 {
    .facilities-two .content-part {
      display: flex;
      align-items: center;

      .icon-part {
        width: 80px;
        line-height: 80px;
        text-align: center;
        border-radius: 50%;
        margin-right: 20px;
        position: relative;
        transition: all 0.3s ease;

        img {
          -webkit-transition: all 0.4s ease;
          transform: scale(1);
        }

        &.purple-bg {
          background: $deep-purple;
        }

        &.pink-bg {
          background: #ff6666;
        }

        &.orange-bg {
          background: #ffaf40;
        }

        &.green-bg {
          background: #95cc47;
        }

        &:hover {
          transform: scale(0.9);
        }
      }

      .text-part .title {
        font-size: 22px;
        line-height: 32px;
        font-weight: 800;
        color: $deep-purple;
        margin-bottom: 0;
      }
    }

    .video-wrap {
      position: relative;
      display: inline-block;

      .popup-videos {
        color: $deep-purple;
        display: block;
        overflow: hidden;
        max-width: 270px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        i {
          width: 70px;
          height: 70px;
          line-height: 61px;
          border: 5px solid $deep-purple;
          border-radius: 50%;
          text-align: center;
          font-size: 25px;
          float: left;
          margin-right: 20px;

          &:before {
            padding-left: 5px;
          }
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &.style3 {
    background: url(assets/images/bg/home12/why-learn-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 200px 0;

    .services-part {
      display: flex;
      box-shadow: 0 0 30px $box-shadow-gray;
      background: $white;
      padding: 15px 30px 20px 30px;

      .services-icon {
        margin-right: 29px;

        img {
          width: 90px;
        }
      }

      .services-text {
        .title {
          font-size: 20px;
          line-height: 36px;
          font-weight: 700;
          padding-bottom: 5px;
          margin: 0;
          color: $dark-gray;
        }

        .services-txt {
          font-size: 16px;
          line-height: 1.2;
          font-weight: 400;
          margin: 0;
          color: $middle-gray;
        }
      }
    }
  }
}