/* ------------------------------------
    21. Gallery Section CSS
---------------------------------------*/

.rs-gallery {
  .gallery-item {
    padding: 0 0 0 0;

    .gallery-img a img {
      border-radius: 5px;
    }

    .title {
      padding-top: 25px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      color: $dark;
    }

  }


  &.style2 .gallery-part {
    position: relative;
    overflow: hidden;

    .gallery-img {
      position: relative;
      overflow: hidden;

      a {
        img {
          transform: scale(1);
          transition: all .8s ease;
          height: 220px;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: $dark-gray;
          opacity: 0;
          display: block;
          transition: all .8s ease;
          z-index: 1;
        }
      }
    }

    &:hover .gallery-img a {
      img {
        transform: scale(1.1);
      }

      &:before {
        opacity: .4;
      }
    }
  }

  &.home11-style .gallery-part {
    .gallery-img {
      position: relative;

      img {
        border-radius: 30px;
      }

      .content-part {
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: $deep-purple;
        padding: 5px 20px;
        display: inline-block;
        color: $white;
        border-radius: 30px;
        right: 0;
        transition: all .4s ease;

        .title {
          font-size: 22px;
          line-height: 34px;
          font-weight: 700;
          color: $white;
          padding: 15px 0 15px;
          margin: 0;
        }
      }

      .gallery-info {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .4s ease;
        z-index: 1;
        text-align: center;
        width: 100%;

        .title-part {
          font-size: 22px;
          line-height: 34px;
          font-weight: 700;
          color: $white;
          margin-bottom: 12px;
        }

        p {
          font-size: 16px;
          line-height: 28px;
          font-weight: 400;
          color: $white;
          padding-right: 60px;
          padding-left: 60px;
          margin-bottom: 15px;
        }

        .btn-part a {
          font-size: 16px;
          line-height: 28px;
          font-weight: 400;
          color: $white;

          i {
            padding-left: 6px;

            &:before {
              font-size: 16px;
            }
          }
        }
      }

      &:before {
        content: '';
        background: $deep-purple;
        position: absolute;
        visibility: hidden;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        opacity: 0;
        display: block;
        transition: all .4s ease;
        z-index: 1;
      }
    }

    &:hover {
      .content-part {
        visibility: hidden;
        opacity: 0;
      }

      .gallery-img {
        .gallery-info {
          opacity: 1;
          visibility: visible;

          .title-part, p, .btn-part a {
            color: $white;
          }
        }

        &:before {
          visibility: visible;
          opacity: 0.9;
        }
      }
    }
  }
}
.gallery-image-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.full-height {
  height: 100%;
}

.gallery-img {
  width: 280px;
  height: 200px;
}
.gallery-thumbnail{
  max-width: 290px;
  max-height: 200px;
}

.gallery-content {
  padding-top: 50px !important;
  margin-top: 0 !important;
  padding-bottom: 49px !important;

  h3.gallery-title {
    margin: 0 !important;
  }
}

.gallery-view-center {
  margin-left: auto;
  margin-right: auto;
}
