@media print {
  .cc-window,
  .cc-revoke {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal;
  }
}

/* dimensions for 'iPhone6 Plus' and lower */
@media screen and (max-width: 414px) and (orientation: portrait),
  screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window {
    &.cc-top {
      top: 0;
    }
    &.cc-bottom {
      bottom: 0;
    }
    &.cc-banner,
    &.cc-floating,
    &.cc-right,
    &.cc-left {
      left: 0;
      right: 0;
    }
    
    &.cc-banner {
      flex-direction: column;
      align-items: unset;
      .cc-compliance {
        flex: 1 1 auto;
      }
      .cc-message {
        margin-right: 0;
        margin-bottom: 1em;
      }
    }
    &.cc-floating {
      max-width: none;
    }
    &.cc-type-categories {
      flex-direction: column;
    }
    .cc-message {
      margin-bottom: 1em;
    }
    .cc-categories{
      flex-direction: column;
      width: 100%;
      margin-right: 8px;
    }
    .cc-category {
      margin: 4px 0;
      .cc-btn:not(.cc-info){
        width: calc( 100% - 16px);
        min-width: 140px;
      }
    }
  } 
}
@media screen and (max-width: 854px) {
  .cc-window.cc-type-categories .cc-btn.cc-save {
    margin: 8px 0;
  }
}

@media screen and (max-width: 790px) {
  .cc-window.cc-type-categories .cc-categories{
    display: flex;
    flex-direction: column;
  }
  .cc-categories .cc-category{
    margin: 4px 0;
  }
  .form {
    width    : 100%;
    max-width: 350px;
  }
  .cc-btn:not(.cc-info):not(.cc-save){
    width: calc( 100% - 16px);
  }
}
