/* ------------------------------------
    15. Team Section CSS
---------------------------------------*/

.rs-team {
  &.style1 {
    .team-item {
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }

      .content-part {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        text-align: center;
        z-index: 1;
        transition: all 0.3s ease;
        opacity: 0;

        .name {
          margin-bottom: 8px;
          font-size: 22px;

          a {
            color: $white;


          }
        }

        .designation {
          color: $white;
          margin-bottom: 16px;
          display: block;
        }

        .social-links li {
          display: inline;
          margin-right: 35px;

          a {
            display: inline-block;
            color: $white;

            &:hover {
              color: $dark;
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      }

      &:after {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, 100%) scale(0.1);
        background: $light-blue;
        opacity: 0;
        border-radius: 3px;
        transition: all 0.3s ease;
      }

      &:hover {
        cursor: pointer;

        .content-part {
          transform: translate(-50%, -50%);
          opacity: 1;
        }

        &:after {
          transform: translate(-50%, -50%) scale(1);
          opacity: 0.9;
        }
      }
    }

    .owl-item.center .team-item {
      .content-part {
        transform: translate(-50%, -50%);
        opacity: 1;
      }

      &:after {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.9;
      }
    }

    .nav-style {
      position: relative;

      .owl-nav {
        display: block;
        position: absolute;
        top: -80px;
        right: 0;

        .owl-prev, .owl-next {
          display: inline-block;
        }

        .owl-prev i, .owl-next i {
          transition: all 0.3s ease;
        }

        .owl-prev i:before, .owl-next i:before {
          font-family: Flaticon;
          font-size: 22px;
        }

        .owl-prev:hover i, .owl-next:hover i {
          color: $light-blue;
        }

        .owl-prev {
          i:before {
            content: "\f134";
          }

          &:after {
            content: "/";
            padding: 0 5px 0 5px;
            position: relative;
            top: -3px;
          }
        }

        .owl-next i:before {
          content: "\f133";
        }
      }
    }

    &.orange-color .team-item:after {
      background: $blue;
    }
  }

  &.style7 .team-item {
    position: relative;
    overflow: hidden;

    &:after {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, 100%) scale(0.1);
      background: #c701f1 !important;
      opacity: 0;
      border-radius: 3px;
      transition: all 0.3s ease;
    }

    &:hover {
      .content-part {
        transform: translate(-50%, -50%);
        opacity: 1;
      }

      &:after {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.9;
      }
    }
  }

  &.inner-style .team-item {
    overflow: hidden;

    .content-part {
      top: unset !important;
      bottom: -205px;
      transform: translateX(-50%) !important;
      width: 100% !important;
      height: 320px !important;
      background: $white;
      border-radius: 50%;
      padding-top: 30px;
      opacity: 1 !important;
      transition: all 0.3s ease;

      .name a {
        color: $dark !important;

        &:hover {
          color: $light-blue !important;
        }
      }

      .designation {
        color: $mid-gray !important;
      }

      .social-links {
        display: none;
      }
    }

    &:hover .content-part {
      bottom: unset;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
      width: unset !important;
      height: unset !important;
      background: transparent;
      border-radius: unset;
      padding-top: 0;
      opacity: 1 !important;

      .name a {
        color: $white !important;

        &:hover {
          color: $dark !important;
        }
      }

      .designation {
        color: $white !important;
      }

      .social-links {
        display: block;
      }
    }
  }

  &.home11-style .team-item .team-thumbnail {
    position: relative;
    overflow: hidden;

    .team-img {
      position: relative;
      overflow: hidden;
      border-radius: 6px;

      .team-social-link {
        display: grid;
        border: 30px solid $deep-purple;
        border-radius: 50%;
        width: 196px;
        height: 193px;
        grid-template-columns: auto auto;
        position: absolute;
        right: -196px;
        bottom: -193px;
        padding: 30px 30px 30px 30px;
        opacity: 0;
        z-index: 9;
        transition: all 0.3s ease;

        li {
          display: inline-block;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: $white;
            cursor: pointer;
            font-size: 16px;

            &:hover {
              color: $light-blue;
            }
          }
        }
      }

      &:before {
        content: '';
        transition: all ease-in-out 0.4s;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        z-index: 2;
        opacity: 0;
      }
    }

    .team-header {
      text-align: center;
      padding-top: 35px;

      .name {
        font-size: 22px;
        line-height: 32px;
        font-weight: 800;
        margin-bottom: 5px;

        a {
          color: $deep-purple;

          &:hover {
            color: $light-blue;
          }
        }
      }

      .subject {
        font-size: 15px;
        line-height: 26px;
        font-weight: 400;
        color: $gray-blue;
        margin-bottom: 10px;
      }
    }

    &:hover .team-img {
      .team-social-link {
        right: -50px;
        bottom: -45px;
        opacity: 1;

        li a:hover {
          color: $light-blue;
        }
      }

      &:before {
        opacity: 0.4;
      }
    }
  }

  &.home-style15 .team-item .team-wrap {
    position: relative;
    overflow: hidden;

    .team-img {
      position: relative;
      overflow: hidden;
      border-radius: 6px;

      .team-social-link {
        position: absolute;
        top: 10px;
        right: -20px;
        opacity: 0;
        visibility: hidden;
        transition: .4s;
        background: $orange;
        padding: 5px 5px 5px 10px;
        border-radius: 3px;

        li {
          display: block;
          color: $white;
        }
      }
    }

    .team-content {
      text-align: center;
      padding-top: 35px;

      .name {
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 5px;

        a {
          color: $orange;

          &:hover {
            color: $orange;
          }
        }
      }

      .subject {
        font-size: 15px;
        line-height: 26px;
        font-weight: 400;
        color: $gray-blue;
        margin-bottom: 10px;
      }
    }

    &:hover .team-img .team-social-link {
      opacity: 1;
      visibility: visible;
      right: 10px;

      li {
        display: block;
        color: $white;

        a {
          color: $white;

          &:hover {
            color: $dark-gray;
          }
        }
      }
    }
  }
}

.margin75-60 {
  margin: 75px 0 60px 0 !important;
}

.teacher-font-size {
  font-size: 28px;
}