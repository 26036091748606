/* ------------------------------------
    22. CTA Section CSS
---------------------------------------*/

.rs-cta {
  position: relative;

  .cta-img img {
    min-height: 425px;
    width: 100%;
  }

  .cta-content {
    position: absolute;
    width: 100%;
    max-width: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.style1 {
    background: url(assets/images/bg/pattern1.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;

    .partition-bg-wrap {
      position: relative;

      &:after, &:before {
        position: absolute;
        content: '';
        width: 50%;
        height: 100%;
        bottom: 0;
        z-index: -1;
      }

      &:after {
        background: $light-blue;
        left: 0;
      }

      &:before {
        background: $middle-blue;
        right: 0;
      }
    }
  }

  &.style2 {
    .video-wrap .popup-videos {
      color: $white;
      display: block;
      overflow: hidden;
      max-width: 270px;

      i {
        width: 70px;
        height: 70px;
        line-height: 61px;
        border: 5px solid $white;
        border-radius: 50%;
        text-align: center;
        font-size: 25px;
        float: left;
        margin-right: 20px;

        &:before {
          padding-left: 5px;
        }
      }

      .title {
        color: $white;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .partition-bg-wrap {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 50%;
        height: 100%;
        top: 0;
        bottom: 0;
        z-index: -1;
      }

      &:before {
        position: absolute;
        content: '';
        width: 50%;
        height: 100%;
        top: 0;
        bottom: 0;
        z-index: -1;
        background: url(assets/images/cta/style2/left-bg.jpg);
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:after {
        background: url(assets/images/cta/style2/right-bg.jpg);
        right: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left;
      }

      &.inner-page {
        &:before {
          background: url(assets/images/cta/style2/left-bg2.png);
          background-size: cover;
        }

        &:after {
          background: url(assets/images/cta/style2/right-bg2.png);
          background-size: cover;
        }
      }
    }
  }

  &.style3 {
    background: url(assets/images/bg/pattern1.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;

    .partition-bg-wrap {
      position: relative;

      &:after, &:before {
        position: absolute;
        content: '';
        width: 50%;
        height: 100%;
        bottom: 0;
        z-index: -1;
      }

      &:after {
        background: $light-blue;
        left: 0;
      }

      &:before {
        background: $middle-blue;
        right: 0;
      }
    }
  }

  &.style7 .partition-bg-wrap {
    background: none !important;
    position: relative;

    &:after, &:before {
      position: absolute;
      content: '';
      width: 50%;
      height: 100%;
      bottom: 0;
      z-index: -1;
    }

    &:after {
      background: url(assets/images/cta/home7/1.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:before {
      background: url(assets/images/cta/home7/2.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      right: 0;
    }
  }

  &.main-home .partition-bg-wrap {
    background: none !important;
    position: relative;
    padding: 130px 0;

    &:after, &:before {
      position: absolute;
      content: '';
      width: 50%;
      height: 100%;
      bottom: 0;
      z-index: -1;
    }

    &:after {
      background: url(assets/images/cta/home1.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:before {
      background: url(assets/images/cta/main-home.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      right: 0;
    }
  }

  &.home9-style .partition-bg-wrap {
    background: none !important;
    position: relative;
    padding: 100px 0;

    &:after, &:before {
      position: absolute;
      content: '';
      width: 50%;
      height: 100%;
      bottom: 0;
      z-index: -1;
    }

    &:after {
      background: url(assets/images/cta/covid-19.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:before {
      background: $warm-blue;
      background-size: cover;
      background-repeat: no-repeat;
      right: 0;
    }
  }

  &.section-wrap {
    .content-wrap {
      padding: 100px 100px 100px 105px;
      background: $middle-blue;

      .text-part {
        padding-right: 400px;

        .sub-title {
          font-size: 18px;
          line-height: 28px;
          font-weight: 400;
          color: $white;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        .title {
          font-size: 36px;
          line-height: 46px;
          font-weight: 700;
          color: $white;
          margin-bottom: 25px;
        }

        .desc {
          font-size: 16px;
          line-height: 26px;
          font-weight: 400;
          color: $white;
          margin-bottom: 40px;
        }
      }
    }

    .content {
      max-width: 700px;
      padding-left: 90px;
      padding-right: 15px;
    }
  }

  &.home11-style {
    background: url(assets/images/cta/home11.jpg);
    background-repeat: no-repeat;
    background-position: bottom;
  }

  &.home-style14 {
    max-width: 700px;
    margin: 0 auto;
    background: url(assets/images/bg/home14/cta.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 44px 30px 44px 30px;
    border-radius: 5px 5px 5px 5px;

    .content-part {
      .sub-text {
        font-family: "Nunito", Sans-serif;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        color: $orange;
        display: block;
        margin-bottom: 10px;
      }

      .title {
        font-family: "Nunito", Sans-serif;
        font-size: 22px;
        font-weight: 800;
        letter-spacing: 1px;
        color: $dark-black;
        margin: 0px 0px 0px 0px;
      }
    }
  }

  &.effects-layer .effects-bg {
    .content-part {
      padding: 56px 244px 60px 50px;

      .title {
        font-size: 24px;
        line-height: 40px;
        font-weight: 800;
        color: $dark-black;
        margin-bottom: 13px;
      }
    }

    &.apply-bg {
      background-color: $light-gray4;
      background-image: url(assets/images/cta/style3/1-2.png);
      background-position: bottom right;
      background-repeat: no-repeat;
      border-radius: 4px 4px 4px 4px;
      margin-top: 20px;
    }

    &.enroll-bg {
      background-color: $light-gray4;
      background-image: url(assets/images/cta/style3/2.png);
      background-position: bottom right;
      background-repeat: no-repeat;
      border-radius: 4px 4px 4px 4px;
      margin-top: 20px;
    }
  }
}