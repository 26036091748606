/*------------------------------------
    37. Footer Section CSS
------------------------------------*/

.rs-footer {
  background-color: $light-blue;
  background-image: url(assets/images/bg/footer-bg.png);
  background-size: cover;

  .footer-top {
    padding: 50px 0 25px;

    .widget-title {
      color: $white;
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 35px;
    }

    .site-map li {
      padding-left: 15px;
      position: relative;
      margin-bottom: 11px;

      a {
        color: $white-e8;

        &:hover {
          color: $ips-middle-green;
        }
      }

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        background: $white;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .address-widget li {
      position: relative;
      margin-bottom: 16px;

      i {
        position: absolute;

        &:before {
          color: $white;
        }
      }

      .desc {
        padding-left: 35px;
        color: $white;

        a {
          color: $white;

          &:hover {
            color: $ips-middle-green;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.no-gap {
      padding-top: 93px;
    }
  }

  .footer-bottom {
    padding: 40px 0;
    background: transparent;
    position: relative;

    .copyright {
      p {
        margin-bottom: 0;
        color: $light-gray;
      }

      a {
        color: $light-blue;
      }
    }

    .footer-logo a {
      display: inline-block;

      img {
        max-width: 190px;
      }
    }

    .footer-social li {
      display: inline;
      margin-right: 5px;

      a {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 100%;
        background: $light-blue;
        color: $white;
        text-align: center;

        &:hover {
          color: $white;
          background: $middle-blue;
        }
      }

      &:last-child {
        margin: 0;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 100%;
      max-width: 1240px;
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &.style6 {
    .footer-top {
      padding: 100px 0 93px;

      .site-map li {
        a {
          color: $white-e8;

          &:hover {
            color: $light-blue;
          }
        }

        &:before {
          background: $yellow !important;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .address-widget li i:before {
        color: $yellow !important;
      }
    }

    .footer-bottom {
      background: none;

      .main-part {
        padding-top: 30px;
        padding-bottom: 18px;

        p a {
          color: $yellow !important;

          &:hover {
            color: $light-blue !important;
          }
        }

        .footer-social li a {
          color: $dark !important;
          background: $yellow !important;
        }
      }
    }
  }

  &.style7 .footer-top {
    padding: 100px 0 93px;

    .address-widget li i:before {
      color: $light-blue !important;
    }
  }

  &.style8 {
    .footer-top {
      padding: 100px 0 93px;

      .site-map li {
        a {
          color: $white-e8;

          &:hover {
            color: $orange;
          }
        }

        &:before {
          background: $orange !important;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .address-widget li {
        i:before, .desc a:hover {
          color: $orange !important;
        }
      }
    }

    .footer-bottom {
      .copyright p a {
        color: $orange !important;

        &:hover {
          color: $red !important;
        }
      }

      .footer-social li a {
        background: $orange !important;
      }
    }
  }

  &.orange-footer {
    background-color: #151515;
    background-image: url(assets/images/bg/footer-bg2.jpg);

    .footer-top {
      .widget-title {
        color: $white;
      }

      .site-map li {
        a:hover {
          color: $orange;
        }

        &:before {
          background: $orange;
        }
      }

      .address-widget li {
        i:before {
          color: $white;
        }

        .desc {
          color: $white;

          a {
            color: $white;

            &:hover {
              color: $orange;
            }
          }
        }
      }
    }

    .footer-bottom {
      .copyright a {
        color: $orange;
      }

      .footer-social li a {
        background: $orange;

        &:hover {
          background: $red;
        }
      }
    }
  }

  &.home9-style {
    .footer-top {
      padding: 218px 0 93px;

      .widget-title {
        color: $white;
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 35px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          height: 2px;
          width: 40px;
          background: $yellow;
        }
      }

      .site-map li {
        padding-left: 15px;
        position: relative;
        margin-bottom: 11px;

        a {
          color: $white-e8;

          &:hover {
            color: $yellow;
          }
        }

        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          background: $yellow;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .address-widget li {
        position: relative;
        margin-bottom: 16px;

        i {
          position: absolute;

          &:before {
            color: $yellow;
          }
        }

        .desc {
          padding-left: 35px;
          color: $white;

          a {
            color: $white;

            &:hover {
              color: $yellow;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.no-gap {
        padding-top: 93px;
      }

      .recent-post {
        display: flex;

        .post-img {
          width: 130px;
          float: left;
          padding-right: 15px;

          img {
            border-radius: 4px;
          }
        }

        .post-item {
          .post-desc a {
            font-size: 14px;
            color: $white;
            font-weight: 400;
            line-height: 22px;
            text-transform: capitalize;

            &:hover {
              color: $yellow;
            }
          }

          .post-date {
            font-size: 13px;
            color: $white;
            font-weight: 400;

            i {
              font-size: 13px;
              color: $yellow;
              padding-right: 5px;
            }
          }
        }
      }
    }

    .footer-bottom {
      padding: 40px 0;
      background: transparent;
      position: relative;

      .copyright {
        p {
          margin-bottom: 0;
          color: $light-gray;
        }

        a {
          color: $yellow;

          &:hover {
            color: $light-blue;
          }
        }
      }

      .footer-logo a {
        display: inline-block;

        img {
          max-width: 190px;
        }
      }

      .footer-social li {
        display: inline;
        margin-right: 5px;

        a {
          display: inline-block;
          width: 30px;
          height: 30px;
          line-height: 30px;
          border-radius: 100%;
          background: $yellow;
          color: $white;
          text-align: center;

          &:hover {
            color: $white;
            background: $middle-blue;
          }
        }

        &:last-child {
          margin: 0;
        }
      }

      &:before {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    &.main-home {
      background: #151515;

      .footer-top {
        .widget-title:before {
          background: $orange;
        }

        .footer-logo a {
          display: inline-block;

          img {
            max-width: 190px;
          }
        }

        .textwidget p {
          color: $white !important;
        }

        .footer_social li {
          display: inline-block;
          margin-right: 6px;
          color: $white;

          a {
            color: $white;
            width: auto;
            height: auto;
            line-height: 16px;
            font-size: 16px;
            margin-right: 10px;

            &:hover {
              color: $orange;
            }
          }
        }

        .site-map li {
          a {
            color: $white-e8;

            &:hover {
              color: $orange;
            }
          }

          &:before {
            background: $orange;
          }
        }

        .address-widget li {
          i:before, .desc a:hover {
            color: $orange;
          }
        }

        &.no-gap {
          padding-top: 93px;
        }

        .recent-post {
          .post-img img {
            border-radius: 4px;
          }

          .post-item {
            .post-desc a:hover {
              color: $orange;
            }

            .post-date i {
              font-size: 13px;
              color: $orange;
              padding-right: 5px;
            }
          }
        }
      }

      .footer-bottom {
        padding: 40px 0;
        background: transparent;
        position: relative;

        .copyright {
          p {
            margin-bottom: 0;
            color: $light-gray;
          }

          a {
            color: $white;

            &:hover {
              color: $orange;
            }
          }
        }

        .copy-right-menu li {
          display: inline-block;
          position: relative;
          padding: 0px 19px 0 25px;

          a {
            color: $white-e8;

            &:hover {
              color: $orange;
            }
          }

          &:before {
            display: block;
            content: "";
            position: absolute;
            font-size: 18px;
            background: $orange;
            left: 0;
            top: 50%;
            width: 6px;
            height: 6px;
            transform: translateY(-50%);
            border-radius: 50%;
          }

          &:first-child:before {
            display: none;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 1px;
          width: 100%;
          max-width: 1240px;
          background: rgba(255, 255, 255, 0.1);
        }
      }

      &.home13-style {
        background: url(assets/images/bg/home13/footer.jpg);
        background-repeat: no-repeat;
        background-size: cover;

        .footer-top {
          padding: 100px 0 93px;
        }
      }

      &.home14-style {
        background: $light-gray4;

        .footer-top {
          padding: 100px 0 93px;

          .widget-title {
            color: $dark-black;
            text-transform: capitalize;

            &:before {
              bottom: -8px;
            }
          }

          .textwidget p {
            color: $middle-gray !important;
          }

          .footer_social li a {
            color: $dark-black;

            &:hover {
              color: $orange;
            }
          }

          .address-widget li {
            i:before {
              color: $orange;
            }

            .desc {
              color: $dark-black;

              a {
                color: $mid-gray;

                &:hover {
                  color: $orange;
                }
              }
            }
          }

          .site-map li {
            a {
              color: $dark-black;

              &:hover {
                color: $orange;
              }
            }

            &:before {
              background: $orange;
            }
          }

          .recent-post .post-item {
            .post-desc {
              color: $mid-gray;

              a {
                color: $mid-gray;

                &:hover {
                  color: $orange;
                }
              }
            }

            .post-date {
              color: $mid-gray;

              i {
                color: $orange;
              }
            }
          }
        }

        .footer-bottom {
          .copyright {
            p {
              color: $dark-black;
            }

            a {
              color: $dark-black;

              &:hover {
                color: $orange;
              }
            }
          }

          .copy-right-menu li {
            a {
              color: $dark-black;

              &:hover {
                color: $orange;
              }
            }

            &:before {
              background: $orange;
            }

            &:first-child:before {
              display: none;
            }
          }

          &:before {
            background: #f0ecee;
          }
        }
      }

      &.home15 .footer-top {
        padding: 190px 0 68px;
      }
    }

    &.home12-style {
      background: #d0f4e4;

      .footer-top {
        .widget-title {
          color: $dark-black;

          &:before {
            background: $green;
          }
        }

        .footer-logo a {
          display: inline-block;

          img {
            max-width: 190px;
          }
        }

        .footer_social li {
          display: inline-block;
          margin-right: 6px;
          color: $middle-gray;

          a {
            color: $middle-gray;
            width: auto;
            height: auto;
            line-height: 16px;
            font-size: 16px;
            margin-right: 10px;

            &:hover {
              color: $green;
            }
          }
        }

        .site-map li {
          a {
            color: $middle-gray;

            &:hover {
              color: $green;
            }
          }

          &:before {
            background: $green;
          }
        }

        .address-widget li {
          i:before {
            color: $green;
          }

          .desc {
            color: $middle-gray;

            a {
              color: $middle-gray;

              &:hover {
                color: $green;
              }
            }
          }
        }

        &.no-gap {
          padding-top: 93px;
        }

        .recent-post {
          .post-img img {
            border-radius: 4px;
          }

          .post-item {
            .post-desc a {
              color: $middle-gray;

              &:hover {
                color: $green;
              }
            }

            .post-date {
              color: $middle-gray;

              i {
                font-size: 13px;
                color: $green;
                padding-right: 5px;
              }
            }
          }
        }
      }

      .footer-bottom {
        padding: 40px 0;
        background: transparent;
        position: relative;

        .copyright {
          p {
            margin-bottom: 0;
            color: $middle-gray;
          }

          a {
            color: $middle-gray;

            &:hover {
              color: $green;
            }
          }
        }

        .copy-right-menu li {
          display: inline-block;
          position: relative;
          padding: 0px 19px 0 25px;

          a {
            color: $middle-gray;

            &:hover {
              color: $green;
            }
          }

          &:before {
            display: block;
            content: "";
            position: absolute;
            font-size: 18px;
            background: $green;
            left: 0;
            top: 50%;
            width: 6px;
            height: 6px;
            transform: translateY(-50%);
            border-radius: 50%;
          }

          &:first-child:before {
            display: none;
          }
        }

        &:before {
          background: #8fcea4;
        }
      }
    }
  }

  &.home11-style {
    background: $deep-purple;

    .footer-top {
      padding: 100px 0 93px;

      .site-map li {
        a:hover {
          color: $orange;
        }

        &:before {
          background: $orange !important;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .footer-bottom {
      padding: 40px 0;
      background: transparent;
      position: relative;

      .copyright p {
        color: $white;
      }

      .footer-logo a {
        display: inline-block;

        img {
          max-width: 190px;
        }
      }

      .footer-social li a {
        color: $deep-purple;
        background: $white;

        &:hover {
          color: $orange;
        }
      }
    }
  }
}

.fa-facebook {
  padding-top: 5px;
}

@media (max-width: 991px) {
  .footer-bottom {
    text-align: center !important;
  }
}