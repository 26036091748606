/* ------------------------------------
    05. Banner Section CSS
---------------------------------------*/

.rs-banner {
  &.style1 {
    padding: 125px 0 425px;
    background: url(assets/images/banner/home1.jpg);
    background-size: cover;
    background-position: center;

    .banner-content {
      max-width: 750px;
      margin: 0 auto;

      .banner-title {
        font-size: 55px;
        margin-bottom: 16px;
      }

      .desc {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  &.style3 {
    background: url(assets/images/banner/home3.jpg);
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: grid;
    align-items: center;

    .banner-content {
      .banner-title {
        font-size: 70px;
        line-height: 1.2;
        margin-bottom: 19px;
      }

      .banner-desc {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 41px;
        position: relative;
        z-index: 9;
      }

      .banner-btn li {
        display: inline;
        margin-right: 30px;

        &:last-child {
          margin: 0;
        }
      }

      .banner-image {
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &.style4 {
    background: url(assets/images/banner/home4.jpg);
    background-size: cover;
    background-position: center;
    min-height: 935px;
    display: grid;
    align-items: center;

    .banner-content .banner-title {
      font-size: 70px;
      line-height: 1.2;
      margin-bottom: 5px;
    }

    .banner-line-shape {
      position: absolute;
      top: -205px;
      left: -25px;
    }

    .banner-circle-shape {
      text-align: right;
      position: absolute;
      bottom: -125px;
      right: -15px;
    }

    .banner-dots-shape {
      position: absolute;
      bottom: -220px;
      left: -200px;
    }
  }

  &.style5 {
    position: relative;
    background-color: #f3fafc;
    min-height: 750px;
    display: grid;
    align-items: flex-end;
    z-index: 9;

    .banner-content {
      padding-bottom: 200px;

      .sub-title {
        color: $dark;
        font-size: 22px;
        line-height: 1.2;
        font-weight: 500;
        margin-bottom: 20px;
      }

      .banner-title {
        color: $dark;
        font-size: 75px;
        line-height: 1.2;
        margin-bottom: 39px;
      }
    }

    .banner-img {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &.style6 {
    background: url(assets/images/banner/home6/bg.png);
    background-size: cover;
    background-position: center;
    margin-bottom: 54px;

    .container {
      position: relative;
    }

    .banner-content {
      padding: 232px 0 388px;

      .banner-title {
        font-weight: 700;
        line-height: 80px;
        font-size: 60px;
      }

      .desc {
        font-size: 18px;
        line-height: 30px;
      }
    }

    .shape-img {
      position: absolute;
      bottom: -54px;

      &.left {
        left: 15px;
        z-index: 1;
      }

      &.center {
        left: 50%;
        transform: translateX(-50%);

        .inner {
          width: 810px;
          height: 385px;
          margin: 0 auto;
          position: relative;

          .spiner {
            position: absolute;
            content: '';

            &.one {
              top: 0;
              left: 37%;

              img:nth-child(1) {
                position: relative;
                right: 4px;
                top: 9px;
              }
            }

            &.two {
              top: 56%;
              left: 0;

              img {
                &:nth-child(1) {
                  max-width: 16px;
                }

                &:nth-child(2) {
                  position: relative;
                  right: -3px;
                  top: -4px;
                  max-width: 25px;
                }
              }
            }

            &.three {
              top: 40%;
              right: 0;

              img {
                &:nth-child(1) {
                  max-width: 16px;
                  position: relative;
                  top: 29px;
                  left: 31px;
                }

                &:nth-child(2) {
                  position: relative;
                  right: 7px;
                  top: 1px;
                  max-width: 25px;
                }
              }
            }
          }
        }
      }

      &.right {
        right: 15px;
      }
    }
  }

  &.style7 {
    background: url(assets/images/banner/home7/banner-bg.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    min-height: 870px;

    .banner-content {
      position: relative;
      padding: 200px 0;

      .banner-title {
        font-size: 70px;
        line-height: 80px;
        font-weight: 800;
      }
    }

    .icons {
      position: absolute;

      &.one {
        top: 136px;
        right: 50px;
      }

      &.two {
        left: 50%;
        bottom: 100px;
      }

      &.three {
        top: 95px;
        left: -160px;
      }

      &.four {
        bottom: 210px;
        left: -62px;
      }
    }

    .img-part {
      position: absolute;
      right: 0;
      bottom: 88px;
    }
  }

  &.style8 {
    background: url(assets/images/banner/home9.jpg);
    background-size: cover;
    background-position: center;
    display: grid;
    align-items: center;
    min-height: 960px;

    .banner-content {
      margin-top: 30px;

      .sl-sub-title {
        font-size: 48px;
        line-height: 50px;
        font-weight: 400;
        color: $white;
        margin-bottom: 22px;
      }

      .sl-title {
        font-size: 90px;
        line-height: 90px;
        font-weight: 700;
        color: $white;
        padding-right: 374px;
        margin-bottom: 45px;
      }
    }
  }

  &.style9 {
    background: url(assets/images/banner/home11/banner.jpg);
    background-size: cover;
    display: grid;
    align-items: center;
    min-height: 880px;
    position: relative;

    .banner-content {
      max-width: 615px;

      .banner-title {
        font-size: 70px;
        line-height: 1.3;
        color: $deep-purple;
      }

      .desc {
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        color: $deep-purple;
      }
    }

    .shape-img .spiner {
      position: absolute;
      bottom: 0;

      &.one {
        top: -40%;
        right: 70%;
      }

      &.two {
        left: 56%;
      }

      &.three {
        left: 290px;
        bottom: -118px;
      }

      &.four {
        top: 0%;
        left: -14%;
      }
    }

    .social-icon {
      position: absolute;
      bottom: 35px;
      left: 70px;

      .icon-cart li {
        display: inline;
        margin-right: 8px;

        a {
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 44px;
          border-radius: 100%;
          background: $white;
          color: $deep-purple;
          text-align: center;

          &:hover {
            color: $light-blue;
          }
        }

        i {
          font-size: 20px;
        }
      }
    }
  }

  &.style10 {
    background: url(assets/images/banner/home12/banner-home12.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    align-items: center;
    min-height: 820px;
    position: relative;

    .banner-content {
      padding: 120px 0 100px;

      .sl-sub-title {
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        color: $green;
        text-transform: uppercase;
        padding-bottom: 10px;
      }

      .sl-title {
        font-size: 50px;
        line-height: 70px;
        font-weight: 800;
        color: $dark-gray;
        text-transform: uppercase;
        padding-bottom: 10px;
      }
    }

    .img-part {
      width: 550px;
      position: absolute;
      top: 70px;
      left: 17%;
      z-index: 111;
    }

    .banner-intro-box {
      .shape-img {
        position: absolute;
        top: 55px;
        left: 45px;

        img {
          width: 110px;
        }
      }

      .intro-img {
        position: absolute;
        right: 8%;
        top: 60px;

        img {
          width: 600px;
        }
      }
    }
  }

  &.style11 {
    background: url(assets/images/banner/home13/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: grid;
    align-items: center;
    min-height: 950px;
    z-index: 1;

    .content-part {
      padding: 180px 0 100px;

      .sub-title {
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
        color: $orange;
        text-transform: uppercase;
        display: block;
        margin-bottom: 15px;
      }

      .title {
        font-size: 70px;
        line-height: 75px;
        font-weight: 700;
        color: $white;
        margin-bottom: 32px;
      }
    }

    .images-part {
      padding-top: 100px;

      img {
        max-width: unset;
        width: 700px;
      }
    }

    .icons-spiner {
      .circle {
        top: 18%;
        right: 81%;
        position: absolute;
      }

      .squre-img {
        position: absolute;
        right: 60%;
        bottom: 20%;
      }

      .dot-img {
        position: absolute;
        right: 15%;
        top: 20%;
        z-index: -111;
      }
    }
  }

  &.style12 {
    background-color: $light-gray4;
    background-image: url(assets/images/banner/home14/border.png);
    background-position: bottom left;
    background-repeat: no-repeat;
    padding: 100px 0px 100px 0px;

    .banner-content {
      .sub-text {
        font-size: 16px;
        line-height: 27px;
        font-weight: 500;
        color: $orange;
        text-transform: uppercase;
        display: block;
        margin-bottom: 10px;
      }

      .title {
        font-size: 48px;
        line-height: 62px;
        font-weight: 800;
        color: $dark-black;
        margin-bottom: 30px;

        span {
          color: $orange;
        }
      }

      .desc {
        font-size: 20px;
        line-height: 32px;
        font-weight: 400;
        color: $middle-gray;
        margin-bottom: 45px;
      }

      .search-widget .search-wrap {
        position: relative;

        [type=search] {
          outline: none;
          padding: 20px 30px;
          border: none;
          border-radius: 3px;
          box-shadow: none;
          padding-right: 77px;
          width: 100%;
        }

        button {
          background: transparent;
          border: medium none;
          color: $orange;
          padding: 11px 15px 12px;
          position: absolute;
          display: block;
          right: 10px;
          top: 10px;
          z-index: 10;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }

    .banner-img img {
      max-width: unset;
      width: 620px;
    }
  }

  &.style13 {
    background-image: url(assets/images/banner/home15/banner-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 136px 0px 0px 0px;
    position: relative;
    z-index: 111;
    overflow: hidden;

    .banner-content {
      padding: 30px 0 0;

      .sub-text {
        font-size: 16px;
        font-weight: 700;
        color: $orange;
        text-transform: uppercase;
        font-family: "Nunito", Sans-serif;
        letter-spacing: 1px;
        display: block;
        margin-bottom: 10px;
      }

      .title {
        font-size: 52px;
        line-height: 68px;
        font-weight: 700;
        color: $white;
        margin-bottom: 30px;
      }

      .desc {
        font-size: 20px;
        line-height: 32px;
        font-weight: 400;
        color: $white;
        margin-bottom: 35px;
      }

      .search-widget .search-wrap {
        position: relative;

        [type=search] {
          outline: none;
          padding: 20px 30px;
          border: none;
          border-radius: 3px;
          box-shadow: none;
          padding-right: 77px;
          width: 100%;
        }

        button {
          background: transparent;
          border: medium none;
          color: $orange;
          padding: 11px 15px 12px;
          position: absolute;
          display: block;
          right: 10px;
          top: 10px;
          z-index: 10;
          font-size: 20px;
          font-weight: 700;

          i:before {
            font-size: 20px;
          }
        }
      }

      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */

        color: $darker-gray;
        opacity: 0.3;
      }

      ::-moz-placeholder {
        /* Firefox 19+ */

        color: $darker-gray;
        opacity: 0.3;
      }

      :-ms-input-placeholder {
        /* IE 10+ */

        color: $darker-gray;
        opacity: 0.3;
      }

      :-moz-placeholder {
        /* Firefox 18- */

        color: $darker-gray;
        opacity: 0.3;
      }
    }

    .banner-img img {
      position: relative;
      max-width: 925px;
      right: 5%;
      bottom: -8px;
    }

    .rs-animation-part .animate-style {
      position: absolute;
      bottom: 0;
      z-index: -111;

      &.one {
        left: 10%;
        top: 42%;
      }

      &.two {
        left: 10%;
        bottom: 10%;
      }

      &.three {
        left: 30%;
        top: 3%;
      }

      &.four {
        left: 48%;
        top: 10%;
      }

      &.five {
        right: 25%;
        top: 17%;
      }

      &.six {
        left: 12%;
        top: 40%;
      }
    }
  }

  .left-shape {
    position: absolute;
    top: -30px;
    left: 40px;
    z-index: -1;
  }

  .right-shape {
    position: absolute;
    top: 65px;
    right: -90px;
    z-index: -1;
  }

  .bottom-shape {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
    }
  }
}