/* ------------------------------------
    07. About Section CSS
---------------------------------------*/

.rs-about {
  .histort-part {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: none;
    display: block;

    .single-history {
      display: block;
      margin-bottom: 30px;

      a {
        padding: 28px 20px 22px;
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 5px;
        width: 230px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: $white;
        background: $dark-gray;
        transition: all 0.3s ease;

        .icon-part {
          display: block;
          margin-bottom: 15px;

          i:before {
            font-size: 45px;
            line-height: 1;
            color: $white;
          }
        }

        &:hover, &.active {
          background: $orange;
        }
      }
    }

    .last-item {
      margin-bottom: 0;
    }
  }

  .history-list {
    .title {
      font-size: 22px;
      padding: 0;
      margin-bottom: 15px;
      font-family: 'Nunito', sans-serif;
      font-weight: 700;
      color: $dark;

      &:before {
        display: none;
      }
    }

    li {
      position: relative;
      padding-left: 40px;
      margin-bottom: 10px;

      &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 25px;
        width: 5px;
        height: 5px;
        background: $dark;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.style1 {
    .about-part {
      background: #e7f4f6;
      padding: 60px 60px 70px;
      border-radius: 5px;
      margin-top: -60px;

      .desc {
        font-size: 18px;
        line-height: 30px;
        color: $mid-gray;
      }

      .sign-part {
        display: flex;
        align-items: center;

        .img-part {
          padding-right: 30px;
        }

        .author-part {
          span {
            display: block;
          }

          .post {
            font-size: 18px;
          }
        }
      }
    }

    .notice-bord.style1 {
      margin-top: -60px;

      .title {
        background: $light-blue;
        font-size: 20px;
        text-transform: uppercase;
        padding: 18px 25px;
        text-align: center;
        font-weight: 600;
        color: $white;
        margin-bottom: 10px;
      }

      li {
        position: relative;
        background: #e7f4f6;
        margin-bottom: 12px;
        border-radius: 3px;
        padding: 20px;
        padding-left: 0 !important;
        overflow: hidden;

        .date {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          color: $light-blue;
          border-right: 1px solid $light-blue;
          padding-right: 10px;
          font-weight: 600;
          width: 60px;

          span {
            font-size: 22px;
            font-family: 'Nunito', sans-serif;
            display: block;
          }
        }

        .desc {
          padding-left: 95px;
          font-weight: 500;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .img-part {
      position: relative;

      .shape {
        position: absolute;
      }

      .top-center {
        top: -30px;
        left: 49%;
        z-index: -1;
      }

      .left-bottom {
        left: 0;
        bottom: 0;

        &.second {
          bottom: -40px;
        }
      }
    }
  }

  &.style2 {
    .about-intro {
      border-radius: 3px;
      background: url(assets/images/bg/about-intro-bg.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      padding: 88px 50px 95px;
      box-shadow: 0 0 20px 0px rgba(17, 41, 88, 0.2);
    }

    .couter-area .counter-item {
      text-align: center;
      padding: 34px 15px;
      border-radius: 3px;

      .number {
        margin-bottom: 7px;
        font-size: 42px;
      }

      .title {
        font-size: 22px;
        color: $blue-gray;
      }

      &.one {
        background: #fbf8cc;
      }

      &.two {
        background: #e9fbd5;
      }

      &.three {
        background: #dcf1fd;
      }
    }

    .grid-area .image-grid img {
      border-radius: 3px;
    }
  }

  &.style3 .couter-area .counter-item {
    text-align: center;
    padding: 34px 15px;
    border-radius: 10px;

    .count-img {
      display: block;
      margin: 0 auto 23px;
    }

    .number {
      margin-bottom: 7px;
      font-size: 42px;
      color: $dark;
    }

    .title {
      font-size: 22px;
      color: $mid-gray;
    }

    &.one {
      background: #fbf8cc;
    }

    &.two {
      background: #e9fbd5;
    }

    &.three {
      background: #dcf1fd;
    }
  }

  &.style4 {
    .img-part {
      position: relative;

      .about-main {
        position: relative;
        z-index: 9;
      }

      .shape {
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
      }

      .circle-bg {
        z-index: 1;
      }
    }

    .about-content {
      .title {
        font-size: 36px;
        font-weight: 800;
      }

      .sub-title {
        color: $gray-blue;
        font-size: 20px;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 20px;
      }

      .contact-part li {
        display: inline-flex;
        align-items: center;
        margin: 0 30px 0 0;
        float: left;

        span {
          display: block;
        }

        .img-part {
          float: left;
          width: 70px;
          height: 70px;
          line-height: 60px;
          border-radius: 50%;
          border: 5px solid #e5e8eb;
          text-align: center;
          margin-right: 30px;
        }

        .desc {
          font-size: 18px;
          font-weight: 500;
          color: $gray-blue;

          span a {
            font-size: 24px;
            color: $dark;
          }

          a {
            color: $ocean-blue;
            font-size: 24px;
            font-weight: bold;
            margin-top: 10px;
            display: block;

            &:hover {
              color: $light-blue;
            }
          }
        }

        &:last-child {
          margin: 0;
        }
      }

      .book-part {
        position: absolute;
        right: 0;
        bottom: -25px;
        width: 775px;

        .single-book {
          padding: 20px 60px;
          background: $light-gray;
          float: left;
        }
      }
    }
  }

  &.style5 {
    background-image: url(assets/images/bg/about-bg.jpg);
    background-size: cover;
    background-position: center;

    .about-content {
      .title {
        font-size: 42px;
        font-weight: 800;
      }

      .sub-title {
        color: $mid-gray;
        font-size: 20px;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 20px;
      }

      .contact-part li {
        position: relative;
        margin-bottom: 40px;

        span {
          display: block;
        }

        .img-part {
          position: absolute;
          left: 0;
          width: 70px;
          height: 70px;
          line-height: 60px;
          border-radius: 50%;
          border: 5px solid #e5e8eb;
          text-align: center;
          margin-right: 30px;
        }

        .desc {
          font-size: 18px;
          font-weight: 500;
          color: $mid-gray;
          padding-left: 95px;

          p {
            color: $ocean-blue;
            font-size: 24px;
            font-weight: bold;
            margin: 10px 0 0;
          }

          a {
            color: $dark;
            font-size: 24px;
            font-weight: bold;
            margin-top: 10px;
            display: block;

            &:hover {
              color: $light-blue;
            }
          }

          .address {
            color: $dark;
            font-size: 22px;
            font-weight: bold;
            margin-top: 10px;
            display: block;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.style6 {
    .desc {
      font-size: 16px;
      color: $mid-gray;
      font-weight: 400;
    }

    .shape-animate {
      position: relative;

      .transparent {
        position: absolute;

        &.left {
          left: 27%;
        }

        &.right {
          right: 20%;
        }
      }
    }
  }

  &.style9 .content {
    .sub-title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      color: $yellow;
      text-transform: uppercase;
    }

    .sl-title {
      font-size: 36px;
      line-height: 45px;
      font-weight: 700;
      color: $dark;
    }

    .desc {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: $mid-gray;
    }
  }

  &.video-style {
    position: relative;

    .icon-shape {
      position: absolute;
      top: -130px;
      left: -130px;
    }

    .dot-shape {
      position: absolute;
      right: -90px;
      bottom: 30px;
    }

    .about-content {
      padding: 70px 70px 70px 160px;
      margin-left: -120px;
      margin-top: -70px;
    }
  }

  &.video-img {
    background: url(assets/images/video/bg.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    border-radius: 10px;
    position: relative;
    padding-bottom: 500px;

    .shape-img {
      position: absolute;
      bottom: 0;

      &.left {
        left: 100px;
      }

      &.center {
        left: 50%;
        transform: translateX(-50%);
      }

      &.right {
        right: 100px;
      }
    }
  }

  &.style8 .main-content {
    padding: 40px 33px;
    background: $light-white;
    position: relative;
    border-radius: 6px;

    .img-part {
      position: absolute;
      left: -240px;
      top: -50px;
      z-index: 111;

      img {
        border-radius: 6px;
      }
    }

    .images-title img {
      border-radius: 6px;
    }
  }

  &.style9 .content-part {
    background-color: $light-white;
    padding: 65px 30px 70px 100px;
    position: relative;

    .about-img {
      position: absolute;
      z-index: 111;
      left: -480px;
      top: 83px;

      img {
        border-radius: 6px;
      }
    }
  }

  &.orange-color .histort-part .single-history .icon-part i:before {
    color: $orange;
  }

  &.style10 {
    position: relative;

    .shape-icons .shape {
      position: absolute;
      bottom: 0;

      &.one {
        right: 49%;
        bottom: 28%;
      }

      &.two {
        top: 19%;
        left: 23%;
      }
    }
  }
}