/* -----------------------------------
    35. Register Section CSS
-------------------------------------*/

.register-section {
  position: relative;

  .register-box {
    position: relative;
    max-width: 970px;
    margin: 0 auto;
    padding: 45px 40px 35px;
    background: $light-white;

    .styled-form {
      max-width: 600px;
      margin: 0 auto;

      .form-group .title-box {
        position: relative;
        text-align: center;
        margin-bottom: 32px;

        h2 {
          position: relative;
          color: #03382e;
          font-weight: 700;
          line-height: 1.3em;
        }

        .text {
          position: relative;
          color: #03382e;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.8em;
          margin-top: 12px;
        }
      }
    }
  }
}

/* Styled Form */

.styled-form {
  position: relative;

  .form-group {
    position: relative;

    /* CheckBox */

    /* RadioBox */

    .eye-icon {
      position: absolute;
      right: 20px;
      top: 52px;
      color: $black-gray;
      font-size: 18px;
      z-index: 1;
      opacity: 0.5;
    }

    label {
      position: relative;
      color: $gray-62;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 15px;
    }

    input {
      position: relative;
      height: 50px;
      padding: 6px 30px;
      width: 100%;
      color: $black-gray;
      font-size: 16px;
      border-radius: 0px;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      border: none;
      box-shadow: 0 0 30px $box-shadow-gray;

      &:focus {
        border-color: #ff6262;
      }
    }

    textarea {
      position: relative;
      height: 150px;
      width: 100%;
      resize: none;
      padding: 15px 15px;
      border-radius: 50px;
      border: 1px solid $border-white;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
    }

    .users {
      position: relative;
      color: $gray-62;
      font-size: 16px;
      text-align: center;

      a {
        position: relative;
        color: $orange;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
          color: $red;
        }
      }
    }

    .check-box {
      position: relative;
      margin-bottom: 3px;
      margin-top: 7px;

      label {
        position: relative;
        display: block;
        width: 100%;
        line-height: 22px;
        padding: 4px 10px 0px;
        padding-left: 25px;
        background: $white;
        font-size: 16px;
        font-weight: 400;
        color: $gray-62;
        cursor: pointer;
        margin-bottom: 0;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
        font-family: 'Poppins', sans-serif;

        &:before {
          position: absolute;
          left: 0;
          top: 6px;
          height: 15px;
          width: 15px;
          background: $white;
          content: "";
          border-radius: 3px;
          -webkit-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          transition: all 300ms ease;
          border: 1px solid $light-blue;
        }

        &:after {
          position: absolute;
          left: 0px;
          top: 0;
          height: 15px;
          line-height: 15px;
          max-width: 0;
          font-size: 14px;
          color: $white;
          font-weight: 800;
          overflow: hidden;
          background: transparent;
          text-align: center;
          font-family: 'FontAwesome';
          -webkit-transition: max-width 500ms ease;
          -moz-transition: max-width 500ms ease;
          -ms-transition: max-width 500ms ease;
          -o-transition: max-width 500ms ease;
          transition: max-width 500ms ease;
        }
      }

      input[type="checkbox"]:checked + label {
        border-color: #25a9e0;
      }
    }

    .radio-box {
      position: relative;
      margin-bottom: 3px;
      margin-top: 7px;

      label {
        position: relative;
        display: block;
        height: 30px;
        width: 100%;
        line-height: 9px;
        padding: 4px 10px 0px;
        padding-left: 25px;
        background: $white;
        font-size: 16px;
        font-weight: 400;
        color: $gray-62;
        cursor: pointer;
        margin-bottom: 0;
        font-family: 'Poppins', sans-serif;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          height: 15px;
          width: 15px;
          background: $white;
          content: "";
          border-radius: 3px;
          -webkit-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          transition: all 300ms ease;
          border: 1px solid $light-blue;
        }

        &:after {
          position: absolute;
          left: 0px;
          top: 0;
          height: 15px;
          line-height: 15px;
          max-width: 0;
          font-size: 14px;
          color: $white;
          font-weight: 800;
          overflow: hidden;
          background: transparent;
          text-align: center;
          font-family: 'FontAwesome';
          -webkit-transition: max-width 500ms ease;
          -moz-transition: max-width 500ms ease;
          -ms-transition: max-width 500ms ease;
          -o-transition: max-width 500ms ease;
          transition: max-width 500ms ease;
        }
      }
    }
  }

  .forgot {
    position: relative;
    font-weight: 500;
    color: $gray-62;
    font-size: 16px;
    margin-top: 10px;
    display: inline-block;
    text-decoration: underline;
  }

  .form-group {
    .form-group .check-box label {
      padding-left: 30px;
      padding-top: 1px;
      cursor: pointer;
    }

    .check-box input[type="checkbox"] {
      display: none;

      &:checked + label {
        &:before {
          border: 5px solid $light-blue;
          background: $white;
        }

        &:after {
          max-width: 20px;
          opacity: 1;
        }
      }
    }

    .radio-box input[type="radio"]:checked + label {
      border-color: #25a9e0;
    }

    .form-group .radio-box label {
      padding-left: 30px;
      padding-top: 1px;
      cursor: pointer;
    }

    .radio-box input[type="radio"] {
      display: none;

      &:checked + label {
        &:before {
          border: 5px solid $light-blue;
          background: $white;
        }

        &:after {
          max-width: 20px;
          opacity: 1;
        }
      }
    }
  }
}

.rs-free-contact {
  background: $green;
  padding: 50px 50px 50px 50px;

  .from-control {
    width: 100%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: $green;
    background-color: #127C4B;
    border-radius: 3px;
    padding: 10px 18px;
  }

  textarea {
    height: 140px;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */

    color: $white;
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */

    color: $white;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* IE 10+ */

    color: $white;
    opacity: 1;
  }

  :-moz-placeholder {
    /* Firefox 18- */

    color: $white;
    opacity: 1;
  }
}