/* ------------------------------------
    17. Testimonial Section CSS
---------------------------------------*/

.rs-testimonial {
  &.style1 {
    .testi-item {
      background: $light-gray;
      max-width: 1000px;
      margin: 0 0 0 auto;
      position: relative;

      .img-part {
        position: absolute;
        left: -240px;
        top: 50px;

        img {
          border-radius: 5px;
          max-width: 450px;
        }
      }

      .content-part {
        padding: 98px 0 98px 320px;

        .desc {
          font-family: 'Nunito', sans-serif;
          font-weight: 600;
          color: $dark;
          font-size: 36px;
          line-height: 46px;
          position: relative;

          &:before, &:after {
            content: "\f151";
            font-family: Flaticon;
            font-size: 20px;
            color: $dark;
          }

          &:before {
            transform: rotateY(180deg);
            position: absolute;
            left: -30px;
            top: -13px;
          }

          &:after {
            position: relative;
            bottom: -7px;
            right: -8px;
          }

          &.title-color {
            color: $dark;
          }
        }

        .name {
          margin: 0;
          color: $dark;
          font-weight: 500;
        }
      }
    }

    .rs-carousel.nav-style2 .owl-nav {
      bottom: 35px;
      right: 50px;
      top: unset;
    }
  }

  &.style2 {
    .donation-part img {
      border-radius: 5px;
      margin-bottom: 22px;
    }

    .testi-wrap {
      overflow: hidden;
      background: $light-gray;
      border-radius: 5px;
      padding: 30px;

      .img-part {
        max-width: 209px;
        float: left;
        margin-right: 58px;

        img {
          border-radius: 5px;
        }
      }

      .content-part {
        .desc {
          font-size: 22px;
          font-weight: 600;
          line-height: 30px;
          font-family: 'Nunito', sans-serif;
          color: $dark-blue;
          display: inline;
          position: relative;

          &:before {
            content: "\f151";
            font-family: Flaticon;
            font-size: 20px;
            color: $dark-blue;
          }

          &:after {
            content: "\f151";
            font-family: Flaticon;
            font-size: 20px;
            color: $dark-blue;
            position: relative;
            bottom: -7px;
            right: -8px;
          }

          &:before {
            transform: rotateY(180deg);
            position: absolute;
            left: -30px;
            top: -13px;
          }

          &.title-color {
            color: $dark;
          }
        }

        .info {
          margin-top: 20px;
          color: $mid-gray;

          .name {
            margin: 0;
            font-weight: 600;
          }
        }

        &.new-content .desc {
          font-size: 22px;
          font-weight: 600;
          line-height: 30px;
          font-family: 'Nunito', sans-serif;
          color: $dark;
          display: inline;
          position: relative;

          &:before {
            content: "\f151";
            font-family: Flaticon;
            font-size: 20px;
            color: $dark;
          }

          &:after {
            content: "\f151";
            font-family: Flaticon;
            font-size: 20px;
            color: $dark;
            position: relative;
            bottom: -7px;
            right: -8px;
          }

          &:before {
            transform: rotateY(180deg);
            position: absolute;
            left: -30px;
            top: -13px;
          }
        }
      }
    }
  }

  &.style3 {
    .testi-item {
      background-color: $light-gray;
      background-image: url(assets/images/testimonial/cloud-pattern.png);
      background-repeat: no-repeat;
      background-size: 130%;
      background-position: 0 70px;
      border-radius: 10px;
      padding: 40px 45px;

      .user-info {
        img {
          max-width: 84px;
          height: auto;
          margin-bottom: 22px;
        }

        .name {
          font-size: 20px;
          margin-bottom: 5px;
        }

        .designation {
          font-size: 14px;
          color: #555555;
          font-weight: 500;
        }

        .ratings {
          margin-top: 10px;

          li {
            display: inline;

            i {
              color: $ochre;
            }
          }
        }
      }

      .desc {
        font-size: 17px;
        font-style: italic;
      }
    }

    .owl-dots {
      text-align: center;
      margin-top: 47px;

      .owl-dot {
        display: inline-block;
        width: 15px;
        height: 5px;
        border-radius: 2.5px;
        background: $light-blue;
        margin: 0 5px;
        opacity: 0.5;

        &.active {
          opacity: 1;
        }
      }
    }
  }

  &.style4 {
    .testi-item {
      border-radius: 10px;
      padding: 30px;
      padding-right: 20px;
      background: $white;
      border: 1px solid rgba(33, 167, 208, 0.1);
      transition: all 0.3s ease;

      .user-img img {
        max-width: 100px;
        height: auto;
      }

      .user-info {
        padding-left: 20px;

        .name {
          font-size: 18px;
          line-height: 1.2;
          font-weight: 600;
          margin: 0;
          color: $dark;

          &:hover {
            color: $light-blue;
          }
        }

        .designation {
          display: block;
        }

        .desc {
          margin-bottom: 20px;
        }

        .quote {
          color: $dark;
          font-weight: bold;
        }
      }
    }

    .center .testi-item {
      border: none;
      box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.1);
    }

    .owl-dots {
      text-align: center;
      margin-top: 47px;

      .owl-dot {
        display: inline-block;
        width: 15px;
        height: 5px;
        border-radius: 2.5px;
        background: $light-blue;
        margin: 0 5px;
        opacity: 0.5;

        &.active {
          opacity: 1;
        }
      }
    }

    .owl-stage-outer {
      padding: 10px;
      margin: -10px;
    }
  }

  &.style5 {
    background: url(assets/images/bg/testi-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    .testi-item {
      .author-desc {
        position: relative;
        text-align: center;
        padding: 35px 15px;

        .desc {
          position: relative;
          background: $white;
          padding: 25px 25px 55px;

          .quote {
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 10px;
          }

          &:before {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: #dbf2f7;
            z-index: -1;
            transform: rotate(10deg);
            border-radius: 10px;
          }
        }

        .author-img {
          position: absolute;
          bottom: -55px;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;

          img {
            width: auto;
            border-radius: 50%;
          }
        }
      }

      .author-part {
        text-align: center;

        .name {
          display: block;
          font-size: 18px;
          font-weight: 600;
          color: $dark;
          margin-top: 15px;
        }
      }
    }
  }

  &.style6 .testimonial-item {
    display: flex;

    .content-part {
      padding: 0 30px;

      .content-wrap {
        .text p {
          font-size: 24px;
          line-height: 40px;
          color: $black-gray;
          font-style: italic;
        }

        .info {
          .name {
            font-size: 22px;
            line-height: 38px;
            color: $ocean-blue;
            margin-bottom: 10px;
          }

          .position {
            font-size: 16px;
            line-height: 28px;
            color: $gray-blue;
          }
        }
      }
    }
  }

  &.style7 {
    background: #e7f8fb;

    .testi-item {
      background: $white !important;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.05) !important;

      .user-info img {
        width: 80px !important;
        height: 80px !important;
        border-radius: 50% !important;
        display: block !important;
      }
    }
  }

  &.style8 {
    .testi-item {
      .author-desc {
        position: relative;
        text-align: center;
        padding: 35px 15px;

        .desc {
          position: relative;
          background-color: $light-white;
          padding: 25px 25px 55px;

          .quote {
            width: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 10px;
          }

          &:before {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: $light-white;
            z-index: -1;
            transform: rotate(10deg);
            border-radius: 10px;
          }
        }

        .author-img {
          position: absolute;
          bottom: -55px;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;

          img {
            margin: auto;
            max-width: 70px;
            border-radius: 50%;
          }
        }
      }

      .author-part {
        text-align: center;

        .name {
          display: block;
          font-size: 18px;
          font-weight: 600;
          color: $dark;
          margin-top: 15px;
        }
      }
    }

    .owl-dots {
      text-align: center;
      margin-top: 47px;

      .owl-dot {
        width: 25px;
        height: 10px;
        display: inline-block;
        margin: 0 6px;
        padding: 3px 0;
        border-radius: 30px;
        background: $red;
        border: none;
        transition: all 0.3s ease;

        &:hover, &.active {
          width: 40px;
          opacity: 1;
          background: $orange;
        }
      }
    }

    .owl-stage-outer {
      padding: 10px;
      margin: -10px;
    }
  }

  &.main-home {
    background: url(assets/images/bg/main-home.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .testi-item {
      .author-desc {
        padding: 70px 15px;
        background-color: $dark-gray;
        border-radius: 5px 5px 5px 5px;

        .desc {
          text-align: center;
          position: relative;
          color: $white;
          padding: 80px 40px 30px;
          font-size: 23px;
          line-height: 33px;
          font-weight: 500;
          font-style: italic;

          .quote {
            width: 66px;
            top: -13px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }

          &:before {
            content: none;
          }
        }

        .author-img {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 80px;

          img {
            width: auto;
            border-radius: 50%;
          }
        }
      }

      .author-part {
        text-align: center;
        padding-top: 45px;

        .name {
          font-size: 20px;
          font-weight: 700;
          line-height: 45px;
          color: $white;
          display: block;
        }

        .designation {
          font-size: 16px;
          color: $white;
        }
      }
    }

    .owl-dots {
      text-align: center;
      margin-top: 47px;

      .owl-dot {
        width: 25px;
        height: 10px;
        display: inline-block;
        margin: 0 6px;
        padding: 3px 0;
        border-radius: 30px;
        background: $red;
        border: none;
        transition: all 0.3s ease;

        &:hover, &.active {
          width: 40px;
          background: $orange;
          opacity: 1;
        }
      }
    }

    .owl-stage-outer {
      padding: 10px;
      margin: -10px;
    }
  }

  &.home9-style .testi-item {
    p {
      font-size: 25px;
      font-style: italic;
      color: $dark;
      padding: 15px 0px 15px 0px;
      margin-bottom: 30px;
    }

    .testi-information {
      color: $mid-gray;

      .name {
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        color: $dark;
        margin-bottom: 15px;
      }

      .designation {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }

  &.home-style1 {
    .testi-item {
      .author-desc {
        padding: 70px 15px;
        background-color: $light-gray;
        border-radius: 5px 5px 5px 5px;

        .desc {
          text-align: center;
          position: relative;
          color: $mid-gray;
          padding: 80px 40px 30px;
          font-size: 23px;
          line-height: 33px;
          font-weight: 500;
          font-style: italic;

          .quote {
            width: 66px;
            top: -13px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }

          &:before {
            content: none;
          }
        }

        .author-img {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 80px;

          img {
            width: auto;
            border-radius: 50%;
          }
        }
      }

      .author-part {
        text-align: center;
        padding-top: 45px;

        .name {
          font-size: 20px;
          font-weight: 700;
          line-height: 45px;
          color: $white;
          display: block;
        }

        .designation {
          font-size: 16px;
          color: $white;
        }
      }
    }

    .owl-dots {
      text-align: center;
      margin-top: 47px;

      .owl-dot {
        width: 40px;
        height: 10px;
        display: inline-block;
        margin: 0 6px;
        padding: 3px 0;
        border-radius: 30px;
        background: $light-blue;
        border: none;

        &:hover, &.active {
          background: $light-blue;
          opacity: 1;
        }
      }
    }

    .owl-stage-outer {
      padding: 10px;
      margin: -10px;
    }
  }

  &.home11-style {
    .rs-carousel .owl-dots {
      text-align: center;

      .owl-dot {
        position: relative;
        border: none;
        border-radius: 50%;
        background: $light-blue;
        width: 10px;
        height: 10px;
        margin-bottom: 10px;
        transition: all 0.3s ease;
        margin-right: 11px;

        &:before {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          left: -4px;
          top: -4px;
          border-radius: 50%;
          border-width: 1px;
          border-style: solid;
          border-color: $deep-purple;
          border-image: initial;
        }
      }

      .active {
        background: $deep-purple;
      }
    }

    .testi-item .user-info {
      position: relative;
      padding: 25px 30px;
      background: $light-gray;
      border: 1px solid $light-gray;

      .desc {
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
        color: $gray-blue;
        margin-bottom: 15px;
      }

      .name {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: $deep-purple;
        margin-bottom: 0;

        &:hover {
          color: $light-blue;
        }
      }

      .position {
        font-size: 15px;
        margin-bottom: 0;

        span {
          font-weight: 500;
        }
      }

      &::before {
        content: "";
        height: 0;
        width: 0;
        top: 30px;
        right: 100%;
        border: solid transparent;
        position: absolute;
        pointer-events: none;
      }

      &:before {
        border-right-color: $light-gray;
        border-width: 15px;
      }
    }
  }

  &.orange-color {
    .testi-item .user-info .ratings li i {
      color: $orange;
    }

    .owl-dots .owl-dot {
      border-color: $orange !important;
      background: $orange !important;
    }
  }

  &.home12-style {
    background: url(assets/images/bg/home12/testi-bg.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding: 150px 0;

    .testi-item {
      padding: 100px 40px 40px 40px;
      background-color: $white;
      margin-right: 14px;
      margin-bottom: 0px;

      .item-content-basic {
        position: relative;
        text-align: center;

        .desc {
          font-style: italic;
          margin-bottom: 28px;

          .quote {
            width: 46px;
            top: -70px;
            position: absolute;
            z-index: 1;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .testi-content {
          .img-wrap {
            margin-bottom: 20px;

            img {
              height: 65px;
              width: 65px;
              border-radius: 50%;
              position: relative;
              margin: 0 auto;
            }
          }

          .name {
            font-size: 20px;
            color: $black;
            font-weight: 700;
            font-family: 'Nunito', sans-serif;
          }

          .designation {
            font-size: 16px;
            line-height: 27px;
            font-weight: 400;
            color: #555555;
          }
        }
      }
    }
  }

  &.home13-style {
    .content {
      margin-bottom: -170px;

      .sub-title {
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
        color: $orange;
        text-transform: uppercase;
      }

      .title {
        font-size: 42px;
        line-height: 52px;
        font-weight: 600;
        color: $white;
      }
    }

    .slick-part {
      .slider-nav .slick-list {
        padding: 0 7px !important;
        margin-bottom: 32px;
      }

      .slider {
        .images-single {
          padding: 0 0 0 6.5px;

          img {
            border-radius: 3px;
          }

          .polite {
            background: #f00;
          }
        }

        .images-slide-single {
          .single-testimonial {
            display: flex;
            align-items: center;

            .images-part, .content-part {
              width: 50%;
            }
          }

          .content-part {
            width: 50%;
            padding: 0px 0px 0px 34px;

            .quote {
              width: 40px;
              margin-bottom: 20px;
            }

            p {
              font-size: 22px;
              font-weight: 400;
              font-style: italic;
              color: $white;
              padding: 0px 0px 30px 0px;
            }

            .name {
              font-size: 22px;
              font-weight: 600;
              line-height: 32px;
              color: $white;
            }
          }
        }

        button {
          border: none;
          font-size: 0px;
          background: none;

          &.slick-prev:before {
            content: "\f137";
            font-family: Flaticon;
            color: $white;
            font-size: 18px;
            background: $orange;
            width: 45px;
            display: block;
            height: 45px;
            line-height: 45px;
            border-radius: 35px;
            right: 40%;
            bottom: 50px;
            position: absolute;
            z-index: 10;
          }
        }
      }
    }
  }

  &.home14-style {
    .owl-stage-outer {
      padding: 10px 10px 10px;
      margin: 0 10px;
    }

    .testi-item {
      padding: 38px 37px 30px 37px;
      background: $white;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);

      .image-wrap {
        position: relative;
        margin-bottom: 25px;

        img {
          height: 65px;
          width: 65px;
          border-radius: 50%;
          position: relative;
        }
      }

      .item-content {
        position: relative;

        .quote-positions {
          width: 18px;
          top: 136px;
          position: absolute;
          z-index: 1;
          left: 154px;
        }

        p {
          font-size: 18px;
          line-height: 31px;
          font-weight: 400;
          color: $mid-gray;
          margin-bottom: 18px;
        }
      }

      .testi-content {
        .testi-name {
          font-size: 20px;
          line-height: 34px;
          font-weight: 500;
          color: $dark-black;
        }

        .testi-title {
          font-size: 18px;
          line-height: 31px;
          font-weight: 400;
          color: $mid-gray;
          display: block;
        }
      }
    }
  }
}