/* ------------------------------------
    19. Download Section CSS
---------------------------------------*/

.rs-download-app .mobile-img {
  display: flex;

  .apps-image img {
    max-width: 195px;
  }
}
