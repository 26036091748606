/* ------------------------------------
    18. Blog Section CSS
---------------------------------------*/

.rs-blog {
  &.style1 {
    .blog-item {
      border-radius: 3px;
      overflow: hidden;
      border: 1px solid #dfe9eb;

      .image-part {
        overflow: hidden;
        height: 100%;

        a img {
          transition: all 0.5s;
          transform: scale(1);
          height: 100%;
          width: 100%;
        }
      }

      .blog-content {
        padding: 37px 19px 30px;

        .title {
          margin-bottom: 14px;
          font-size: 24px;
          line-height: 34px;

          a {
            color: $dark;

            &:hover {
              color: $light-blue;
            }
          }
        }

        .blog-meta {
          margin-bottom: 11px;

          li {
            position: relative;
            margin-right: 5px;
            padding-right: 15px;
            display: inline-block;

            i {
              padding-right: 3px;
              font-size: 14px;

              &.fa-calendar {
                padding-right: 7px;
              }
            }

            a {
              color: $mid-gray;
            }

            &:last-child {
              margin: 0;
              padding: 0;
            }
          }
        }

        .readon-arrow {
          text-transform: uppercase;
        }
      }

      &:hover .image-part a img {
        transform: scale(1.07);
      }
    }

    .events-short {
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      background: $white;
      padding: 30px;
      border: 1px solid #dfe9eb;
      border-radius: 3px;

      .date-part {
        position: absolute;
        background: #f3f8f9;
        width: 100px;
        text-align: center;
        padding: 11px 0 22px;
        border-radius: 3px;

        .month {
          margin-bottom: 10px;
          display: block;
        }

        .date {
          font-size: 50px;
          font-family: 'Nunito', sans-serif;
          color: $light-blue;
          font-weight: 700;
        }
      }

      .content-part {
        padding-left: 120px;

        .categorie a {
          color: $mid-gray;

          &:hover {
            color: $light-blue;
          }
        }

        .title {
          line-height: 1.5;

          a {
            color: $dark;

            &:hover {
              color: $light-blue;
            }
          }
        }
      }

      &.new-style {
        padding: unset;
        border: none;

        .date-part {
          position: absolute;
          width: 100px;
          text-align: center;
          padding: 11px 0 22px;
          border-radius: 3px;

          .month, .date {
            color: $white;
          }

          &.bg1 {
            background: $deep-purple;
          }

          &.bg2 {
            background: #ff6666;
          }

          &.bg3 {
            background: #ffaf40;
          }

          &.bg4 {
            background: #95cc47;
          }
        }

        .content-part {
          .categorie {
            .timesec {
              color: #54647b;
            }

            a {
              color: $deep-purple;
            }
          }

          .title {
            padding-right: 62px;
            font-size: 22px;

            a {
              color: $deep-purple;

              &:hover {
                color: $light-blue;
              }
            }
          }
        }
      }
    }

    &.modify1 {
      .blog-item {
        border: 1px solid #c7e8f0;

        .blog-content {
          padding: 33px 30px 34px;
        }
      }

      .events-short {
        overflow: visible;
        background: $white;
        padding: 6px 0;
        border: none;
        border-radius: 0;

        .date-part {
          background: #f3f8f9;

          &.bgc1 {
            background: #dcf1fd;
          }

          &.bgc2 {
            background: #eaddf8;
          }

          &.bgc3 {
            background: #f8f6d1;
          }

          &.bgc4 {
            background: #f9e8e8;
          }
        }

        .content-part .title {
          font-size: 22px;
        }
      }
    }

    &.home11-style .blog-item {
      border-radius: 3px;
      overflow: hidden;
      border: 1px solid #dfe9eb;

      .blog-content {
        padding: 37px 25px 30px;

        .title a {
          color: $deep-purple;

          &:hover {
            color: $light-blue;
          }
        }

        .blog-meta {
          margin-bottom: 11px;

          li {
            color: #54647b;
          }
        }

        .btn-part .readon-arrow {
          color: $deep-purple;

          &:after {
            color: $deep-purple;
          }

          &:hover {
            color: $light-blue;

            &:after {
              color: $light-blue;
            }
          }
        }
      }

      &:hover .image-part a img {
        transform: scale(1.07);
      }
    }
  }

  &.style2 {
    .blog-item {
      border-radius: 3px;
      background: $white;
      overflow: hidden;

      .image-part {
        overflow: hidden;
        height: 100%;

        img {
          transition: all 0.5s;
          transform: scale(1);
        }
      }

      .blog-content {
        padding: 21px 30px 30px;
        color: $mid-gray;
        background: #fcfcfc;

        .title {
          margin-bottom: 14px;
          font-size: 24px;
          line-height: 34px;

          a {
            color: $dark;

            &:hover {
              color: $light-blue;
            }
          }
        }

        .categories, .desc {
          color: $mid-gray;
        }

        .blog-meta {
          margin-bottom: 11px;
          color: $mid-gray;

          li {
            position: relative;
            margin-right: 5px;
            padding-right: 15px;
            display: inline-block;

            i {
              padding-right: 3px;
              font-size: 14px;

              &.fa-calendar {
                padding-right: 7px;
              }
            }

            a {
              color: $middle-blue;
            }

            &:last-child {
              margin: 0;
              padding: 0;
            }
          }
        }

        .blog-bottom {
          overflow: hidden;
          padding-top: 21px;
          margin-top: 21px;
          border-top: 1px solid #dcdfe6;

          li {
            display: inline;

            &.cmnt-part a {
              color: $middle-blue;

              &:hover {
                color: $light-blue;
              }
            }

            &.btn-part {
              float: right;

              .readon-arrow {
                text-transform: uppercase;
                color: $middle-blue;

                &:hover {
                  color: $light-blue;
                }
              }
            }
          }
        }

        &.primary-border {
          border: 1px solid rgba(33, 167, 208, 0.1);
          border-top: none;
        }

        &.new-style {
          .title a {
            color: #112958;

            &:hover {
              color: $light-blue;
            }
          }

          .blog-bottom li {
            display: inline;

            &.cmnt-part a {
              color: $mid-gray;

              &:hover {
                color: $light-blue;
              }
            }

            &.btn-part {
              float: right;

              .readon-arrow {
                text-transform: uppercase;
                color: #112958;

                &:hover {
                  color: $light-blue;
                }
              }
            }
          }
        }

        &.new-style2 .blog-bottom li {
          &.cmnt-part a {
            color: $mid-gray;

            &:hover {
              color: $light-blue;
            }
          }

          &.btn-part {
            float: right;

            .readon-arrow {
              text-transform: uppercase;
              color: $dark;

              &:hover {
                color: $light-blue;
              }
            }
          }
        }
      }

      &:hover .image-part img {
        transform: scale(1.07);
      }
    }

    &.modify .blog-item .blog-content {
      border: none;
      background: $white;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);

      .blog-bottom .btn-part {
        font-weight: 600;
        color: $dark;

        .readon-arrow {
          font-weight: 600;
          color: $dark;
        }
      }
    }

    &.modify2 {
      .owl-stage-outer {
        padding-bottom: 10px;
        margin: -10px;
        padding-left: 10px;
      }

      .blog-item {
        overflow: visible !important;

        .blog-content {
          background: $white !important;
          box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.2) !important;
          transition: all 500ms ease;
          position: relative;

          .categories {
            &.color-bg1 {
              color: #c701f1;
            }

            &.color-bg2 {
              color: #065ce8;
            }

            &.color-bg3 {
              color: #f6075b;
            }
          }
        }

        &:hover .blog-content {
          transform: translateY(-10px);
        }
      }
    }
  }

  &.main-home {
    .owl-stage-outer {
      padding: 0 10px 10px;
      margin: 0 -10px;
    }

    .blog-item {
      background: $white;
      transition: all 500ms ease;
      border-radius: 5px;

      .blog-content {
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
        padding: 40px 35px 16px;

        .blog-meta {
          margin-bottom: 11px;

          li {
            position: relative;
            margin-right: 5px;
            padding-right: 15px;
            display: inline-block;

            i {
              padding-right: 3px;
              font-size: 14px;

              &:before {
                padding-right: 7px;
                color: $light-blue;
              }
            }

            a {
              color: $mid-gray;
            }

            &:last-child {
              margin: 0;
              padding: 0;
            }
          }
        }

        .title {
          margin-bottom: 14px;
          font-size: 24px;
          line-height: 34px;

          a {
            color: #101010;

            &:hover {
              color: $light-blue;
            }
          }
        }

        .desc {
          color: $mid-gray;
          padding-bottom: 20px;
        }

        .btn-btm {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #f4f0f0;
          padding-top: 20px;

          .cat-list .post-categories {
            display: flex;
            position: relative;
            padding-left: 22px;

            &:before {
              content: "\f02d";
              font-family: fontawesome;
              font-size: 15px;
              color: $light-blue;
              position: absolute;
              left: 0px;
              top: 1px;
            }

            li {
              font-size: 14px;
              text-transform: capitalize;

              a {
                color: $mid-gray;

                &:hover {
                  color: $light-blue;
                }
              }
            }
          }

          .rs-view-btn a {
            padding: 0px 0px 0px 0px;
            font-family: "Rubik", Sans-serif;
            font-weight: 400;
            color: $mid-gray;
            font-size: 15px;
            position: relative;

            &:after {
              content: "\f054";
              font-family: fontawesome;
              font-size: 12px;
              position: relative;
              right: 0px;
              top: 0px;
              display: inline-block;
              margin-left: 3px;
            }

            &:hover {
              color: $light-blue;
            }
          }
        }
      }

      &:hover {
        transform: translateY(-10px);
      }
    }

    &.modify1 .blog-item .blog-content {
      background-color: #F3FCF8;
      padding: 30px 25px 30px 25px;
      border-radius: 0 0 5px 5px;
      box-shadow: unset;

      .blog-meta {
        font-size: 14px;
        text-transform: capitalize;
        display: flex;
        justify-content: space-between;

        .date, .admin {
          color: #333333;
          font-size: 14px;

          i:before {
            color: #0c8b51;
            padding-right: 5px;
          }
        }
      }

      .title {
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;

        a {
          color: #101010;

          &:hover {
            color: #0c8b51;
          }
        }
      }

      .btn-btm {
        .cat-list .post-categories {
          &:before {
            color: #0c8b51;
          }

          li {
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;

            a {
              color: #333333;

              &:hover {
                color: #0c8b51;
              }
            }
          }
        }

        .rs-view-btn a {
          font-weight: 500;
          color: #333333;
          font-size: 15px;

          &:hover {
            color: #0c8b51;
          }
        }
      }
    }
  }

  &.home9-style {
    .blog-item {
      border-radius: 3px;
      overflow: hidden;
      border: 1px solid #dfe9eb;

      .image-part {
        overflow: hidden;
        height: 100%;

        a img {
          transition: all 0.5s;
          transform: scale(1);
          height: 100%;
          width: 100%;
        }
      }

      .blog-content {
        padding: 10px 20px 0px 30px;

        .title {
          margin-bottom: 14px;
          font-size: 24px;
          line-height: 34px;

          a {
            color: #101010;

            &:hover {
              color: #f4bf00;
            }
          }
        }

        .txt {
          font-size: 16px;
          color: $mid-gray;
          font-weight: 400;
          margin: 0;
        }

        .blog-meta {
          margin-bottom: 11px;

          li {
            position: relative;
            margin-right: 5px;
            padding-right: 15px;
            display: inline-block;
            font-size: 16px;
            color: $mid-gray;
            font-weight: 400;

            i {
              padding-right: 3px;
              font-size: 14px;

              &:before {
                color: #f4bf00;
                padding-right: 5px;

                &:last-child {
                  padding-right: 0;
                }
              }
            }

            a {
              color: $mid-gray;
            }

            &:last-child {
              margin: 0;
              padding: 0;
            }
          }
        }
      }

      &:hover .image-part a img {
        transform: scale(1.07);
      }
    }

    .events-short-top {
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      border: 1px solid #dfe9eb;
      padding: 25px 39px 38px 25px;
      align-items: flex-start;
      background: $white;

      .date-part {
        background: #1C335F;
        min-width: 100px;
        text-align: center;
        padding: 11px 0 22px;
        border-radius: 3px;
        margin-right: 25px;
        transition: all 500ms ease;

        .month {
          margin-bottom: 10px;
          display: block;
          color: $white;
        }

        .date {
          font-size: 50px;
          color: #f4bf00;
          font-weight: 700;
          line-height: 25px;
        }

        &:hover {
          transform: translateY(-10px);
        }
      }

      .content-part {
        .categorie a {
          color: $mid-gray;

          &:hover {
            color: $light-blue;
          }
        }

        .title {
          line-height: 1.5;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 6px;

          a {
            color: #101010;

            &:hover {
              color: #f4bf00;
            }
          }
        }

        .txt {
          font-size: 16px;
          color: $mid-gray;
          margin: 0;
        }
      }
    }

    .short {
      position: relative;
      display: flex;
      overflow: hidden;
      background: $white;
      padding: 30px;
      border: 1px solid #dfe9eb;
      border-radius: 3px;

      .date-part {
        position: absolute;
        background: #1c335f;
        width: 100px;
        text-align: center;
        padding: 11px 0 22px;
        border-radius: 3px;

        .month {
          margin-bottom: 10px;
          display: block;
        }

        .date {
          font-size: 50px;
          font-family: 'Nunito', sans-serif;
          color: $light-blue;
          font-weight: 700;
        }
      }

      .content-part {
        padding-left: 120px;

        .categorie a {
          color: $mid-gray;

          &:hover {
            color: $light-blue;
          }
        }

        .title {
          line-height: 1.5;

          a {
            color: $dark;

            &:hover {
              color: $light-blue;
            }
          }
        }
      }
    }
  }

  &.orange-color {
    .blog-item .blog-content {
      .title a:hover {
        color: $light-blue;
      }

      .readon-arrow:hover {
        color: $light-blue;

        &:after {
          color: $light-blue;
        }
      }
    }

    .events-short .content-part .title a:hover {
      color: $light-blue;
    }
  }

  &.gym-home .blog-item {
    transition: all 500ms ease;
    border-radius: 5px;

    .blog-content {
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-color: #252525;
      background-color: #1D0E15;
      padding: 26px 26px 30px 26px;

      .post-categories li {
        margin-bottom: 10px;

        a {
          font-size: 15px;
          color: $light-blue;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
      }

      .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 18px;

        a {
          color: $white;

          &:hover {
            color: $light-blue;
          }
        }
      }

      .blog-meta {
        font-size: 14px;
        color: $white;
        display: flex;
        justify-content: space-between;

        li i {
          padding-right: 3px;
          font-size: 14px;
          color: $light-blue;

          &.fa-calendar {
            padding-right: 7px;
          }
        }
      }
    }

    &:hover {
      transform: translateY(-10px);
    }
  }
}

.rs-inner-blog {
  .blog-item {
    background: $white;
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);

    .blog-content {
      padding: 40px 46px 45px;
      overflow: hidden;

      .blog-title {
        margin: 4px 0 10px;
        font-size: 26px;
        line-height: 35px;

        a {
          color: $dark;

          &:hover {
            color: $light-blue;
          }
        }
      }

      .blog-meta .btm-cate {
        overflow: hidden;
        margin: 0 0 5px;
        padding: 0 0 12px;
        font-size: 13px;
        display: flex;
        align-items: center;

        li {
          margin-right: 15px;

          .blog-date {
            display: inline-block;
            font-weight: 400;
            font-size: 13px;
            color: $mid-gray;

            i {
              color: $light-blue;
              margin-right: 3px;
            }
          }

          .author {
            display: inline-block;
            padding: 0;
            margin-right: 0;
            line-height: normal;
            color: #555;

            i {
              color: $light-blue;
              margin-right: 3px;
            }
          }

          .tag-line {
            position: relative;
            padding-left: 2px;
            color: #555;

            i {
              margin-right: 3px;
              color: $light-blue;

              &:before {
                font-size: 14px;
                font-weight: 600;
              }
            }

            a {
              color: #555;
            }
          }
        }
      }

      //.blog-desc {
      //  font-size: 16px;
      //  line-height: 27px;
      //  font-weight: 400;
      //  color: $mid-gray;
      //  margin-bottom: 35px;
      //  height: 80px;
      //}
    }
  }

  .widget-area {
    .widget-title {
      color: $dark;
      font-size: 20px;
      line-height: 26px;
      font-weight: 600;
      position: relative;
      z-index: 1;
      padding-bottom: 12px;

      &:before {
        content: "";
        position: absolute;
        border: 0;
        width: 50px;
        height: 2px;
        background: $light-blue;
        z-index: 1;
        margin-left: 0;
        bottom: 0;
        left: 0;
      }
    }

    .search-widget {
      background: $white;
      padding: 40px 30px 40px;
      box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);

      .search-wrap {
        position: relative;

        [type=search] {
          border: 1px solid #ddd;
          color: #444444;
          padding: 12px 17px;
          width: 100%;
          border-radius: 5px;
          position: relative;
        }

        button {
          background: transparent;
          border: medium none;
          color: $mid-gray;
          padding: 11px 15px 12px;
          position: absolute;
          display: block;
          right: 0px;
          top: 0;
          z-index: 10;
          font-size: 20px;
          border-radius: 0 5px 5px;

          i:before {
            font-weight: 600;
            font-size: 18px;
          }

          &:hover {
            color: $light-blue;
          }
        }
      }
    }

    .recent-posts {
      background: $white;
      padding: 40px 30px 40px;
      box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);

      ul li {
        margin-top: 13px;
        padding-top: 13px;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        font-weight: 400;
        font-size: 16px;

        a {
          color: $mid-gray;

          &:hover {
            color: $light-blue;
          }
        }
      }
    }

    .recent-posts-widget {
      background: $white;
      padding: 40px 30px 40px;
      box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);

      .show-featured {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        padding-bottom: 15px;
        margin-bottom: 15px;

        .post-img {
          max-width: 150px;
          padding-right: 15px;
          border-radius: 3px;
          overflow: hidden;
        }

        .post-desc {
          a {
            font-size: 15px;
            line-height: 22px;
            color: $dark;
            font-weight: 400;

            &:hover {
              color: $light-blue;
            }
          }

          .date {
            font-size: 12px;
            line-height: 20px;
            color: $light-blue;
            font-weight: 400;
            display: block;

            i {
              font-size: 12px;
              line-height: 12px;
              color: $light-blue;
              font-weight: 400;
            }
          }
        }

        &:last-child {
          margin: 0;
          padding: 0;
          border: none;
        }
      }
    }

    .widget-archives {
      background: $white;
      padding: 40px 30px 40px;
      box-shadow: 0 6px 25px rgba(0, 0, 0, 0.07);

      ul li {
        border: none;
        position: relative;
        padding: 6px 0px 6px 14px;
        margin: 0;
        font-size: 16px;

        a {
          color: $mid-gray;

          &:after {
            content: "\f105";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            position: absolute;
            left: 0;
            top: 6px;
            color: $middle-blue;
          }

          &:hover {
            color: $light-blue;
          }
        }
      }
    }
  }

  .blog-deatails {
    background: $white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    margin-bottom: 0;

    .blog-full {
      padding: 40px 45px 40px;

      .single-post-meta {
        display: flex;
        align-items: center;
        padding: 0 0 30px;

        li .p-date {
          color: #555;
          font-size: 14px;
          margin-right: 8px;

          i {
            font-size: 13px;
            margin-right: 3px;
            color: $light-blue;

            &:before {
              margin-right: 5px;
            }
          }
        }

        .Post-cate {
          list-style: none;
          display: inline-block;
          padding-right: 10px;
          color: #555;
          font-size: 14px;

          .tag-line {
            i {
              color: $light-blue;
              margin-right: 3px;

              &:before {
                margin-right: 4px;
              }
            }

            a {
              font-weight: 400;
              color: #555;
              font-size: 14px;

              &:hover {
                color: $light-blue;
              }
            }
          }
        }

        .post-comment {
          color: #555;
          font-size: 14px;

          i:before {
            color: $light-blue;
          }
        }
      }

      //.blog-desc p {
      //  font-size: 16px;
      //  font-weight: 400;
      //  color: $mid-gray;
      //}

      .title {
        color: $dark;
        font-size: 36px;
        font-weight: 700;
        line-height: 40px;
      }

      .sm-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        color: $dark;
      }

      .unorder-list li {
        display: block;
        position: relative;
        padding-left: 25px;
        padding-bottom: 15px;
        font-weight: 500;
        line-height: 25px;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "\f105";
          font-family: FontAwesome;
          color: $light-blue;
          font-size: 20px;
        }
      }
    }
  }

  .ps-navigation {
    border-top: 1px solid rgba(170, 170, 170, 0.25);
    border-bottom: 1px solid rgba(170, 170, 170, 0.25);
    padding: 30px 0;
    margin: 60px 0 0;

    ul li {
      text-align: right;

      a {
        display: inline-block;

        .next-link {
          text-transform: uppercase;
          color: #909090;
          margin-top: 0;
          display: block;

          &:hover {
            color: $light-blue;
          }

          i:before {
            font-size: 16px;
            font-style: normal;
            color: $mid-gray;
          }
        }

        .link-text {
          font-size: 16px;
          font-weight: 500;
          color: #1c1b1b;

          &:hover {
            color: $light-blue;
          }
        }
      }
    }
  }

  .comment-area .comment-full {
    .reply-title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 30px;
      margin-top: 45px;
      position: relative;
      padding: 0 0 12px;
      color: $dark;
    }

    p span {
      font-size: 16px;
      color: $mid-gray;
    }

    .from-group {
      width: 100%;
      height: 50px;
      padding: 5px 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    .submit-btn .submit {
      border: 2px solid;
      border-color: $light-blue;
      background: $light-blue;
      position: relative;
      display: inline-block;
      padding: 12px 22px;
      border-radius: 3px;
      line-height: normal;
      font-size: 14px;
      font-weight: 500;
      color: $white;
      transition: all .3s ease 0s;
      text-transform: uppercase;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  &.orange-color {
    .blog-btn:hover {
      color: $light-blue;

      &::after {
        color: $light-blue;
      }
    }

    .widget-area .recent-posts ul li a:hover, .blog-item .blog-content .blog-title a:hover, .widget-area .search-widget .search-wrap button:hover {
      color: $light-blue;
    }

    .blog-item .blog-content .blog-meta .btm-cate li {
      .author i, .tag-line i, .blog-date i {
        color: $light-blue;
      }
    }

    .widget-area .recent-posts-widget .show-featured .post-desc {
      .date {
        color: $middle-blue;

        i {
          color: $middle-blue;
        }
      }

      a:hover {
        color: $middle-blue;
      }
    }

    .blog-deatails .blog-full {
      .single-post-meta {
        .Post-cate .tag-line i, .post-comment i::before, .Post-cate .tag-line a:hover, li .p-date i {
          color: $light-blue;
        }
      }

      .unorder-list li::before {
        color: $light-blue;
      }
    }

    .ps-navigation ul li a {
      .link-text:hover {
        color: $light-blue;
      }

      .next-link:hover {
        color: $light-blue;

        i::before {
          color: $light-blue;
        }
      }
    }

    blockquote::before {
      background: $light-blue;
    }

    .comment-area .comment-full .submit-btn .submit {
      border-color: $light-blue;
      background: $light-blue;

      &:hover {
        border-color: $red;
        background: $red;
      }
    }
  }
}

.article-image-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul.pagination {
  display: inline-block;
  padding: 0 5px 0 10px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0px 8px 26px 0px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 450;

  li {
    position: relative;
    display: inline-block;
    padding: 0 20px 0 15px;
    text-align: center;
    cursor: pointer;

    a {
      display: inline-flex;
      align-items: center;
      color: $dark;

      &:hover {
        color: $light-blue;
      }
    }

    i {
      margin-left: 10px;

      &:before {
        font-size: 28px;
        margin: 0;
        line-height: 60px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $ips-middle-blue;
    }

    &:last-child:before {
      display: none;
    }

    &:hover a, &.active a {
      color: $light-blue;
    }
  }
}

.full-height {
  height: 100%;
}

.blog-img {
  width: 393px;
  height: 250px;
}

.empty-menu-title {
  color: $light-blue;
  font-size: 56px;
}

.empty-menu-text {
  font-size: 22px;
  color: $light-blue;
}

.box-height {
  height: 220px;
}

.btn-blue-background {
  background-color: $light-blue;
  &:hover {
    background-color: $ips-middle-blue;
    border-color: $ips-middle-blue;
  }
}

@media (max-width: 991px) {
  .article-gallery {
    margin-top: 30px;
  }
}

.blog-desc {
  p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  li {
    display: list-item;
  }

}