/*----------------------------------------
    34. Login Section CSS
----------------------------------------*/

.rs-login .noticed {
  background: $light-white;
  max-width: 800px;
  padding: 70px;
  margin: 0 auto;

  .main-part {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    border-radius: 5px;

    .method-account {
      .login {
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        color: $dark;
      }

      label {
        font-size: 15px;
        line-height: 30px;
        font-weight: 600;
        color: $mid-gray;
      }

      input {
        margin-bottom: 30px;
        font-size: 15px;
        font-weight: 400;
        color: $mid-gray;
        width: 100%;
        display: block;
        border: none;
        height: 45px;
        padding: 10px 18px;
        border-radius: 5px;
        box-shadow: 0 0 30px $box-shadow-gray;
      }

      span {
        font-size: 15px;
        line-height: 30px;
        font-weight: 600;
        color: $mid-gray;
        padding-left: 10px;
      }

      .last-password p {
        color: $mid-gray;
        margin: 0;

        a {
          font-size: 15px;
          line-height: 26px;
          font-weight: 400;
          color: $orange;

          &:hover {
            color: $mid-gray;
          }
        }
      }
    }
  }
}