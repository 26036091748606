/*-------------------------------------
    36. Contact Section CSS
--------------------------------------*/

.contact-page-section {
  .inner-part {
    .title {
      font-size: 36px;
      color: $dark-black;
      line-height: 46px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    .title2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.25em;
      color: $dark;
    }

    p {
      font-size: 19px;
      color: $darker-gray;
      line-height: 32px;
      font-weight: 400;
    }
  }

  .rs-quick-contact {
    background-color: $light-white;
    padding: 70px 150px 70px;

    .from-control {
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-color: #F1F1F1;
      box-shadow: 0 0 30px $box-shadow-gray;
      width: 100%;
      max-width: 100%;
      opacity: 1;
      border-radius: 3px;
      border: 1px solid $white;
      color: $gray-76;
      background: $white;
      padding: 10px 18px;
    }

    textarea {
      height: 140px;
    }

    .form-group .btn-send {
      padding: 16px 0px 16px 0px;
      text-transform: uppercase;
      box-shadow: 0px 28px 50px 0px rgba(0, 0, 0, 0.05);
      outline: none;
      border: none;
      padding: 12px 40px;
      border-radius: 3px;
      display: inline-block;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 500;
      color: $white;
      background: $middle-blue;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;
      width: 100%;

      &:hover {
        opacity: 0.90;
      }
    }

    &.new-style {
      padding: 70px 60px 70px;
    }
  }

  .rs-contact-box .address-item {
    padding: 50px 40px 50px 40px;
    background: $white;
    box-shadow: 0 0 30px $white;
    text-align: center;
    margin: 0px 20px 0px 0px;
    border-radius: 5px 5px 5px 5px;

    @media (max-width: 991px) {
      margin: 0 !important;
    }

    .icon-part {
      padding-bottom: 20px;

      img {
        width: 65px;
      }
    }

    .address-text {
      .label {
        display: block;
        color: $dark-black;
        font-size: 22px;
        font-weight: 600;
        padding-bottom: 10px;
      }

      .des {
        font-size: 17px;
        color: $ocean-blue;

        a {
          color: $ocean-blue;

          &:hover {
            color: $orange;
          }
        }
      }
    }
  }

  .rs-contact-wrap {
    padding: 40px 35px 30px 35px;
    background-color: $light-white;
    border-radius: 5px 5px 5px 5px;

    .address-item {
      display: flex;
      position: relative;
      margin-bottom: 40px;

      .address-icon {
        margin-right: 20px;

        i {
          font-size: 35px;
          color: $orange;
        }
      }

      .address-text {
        .label {
          display: block;
          color: $mid-gray;
          font-size: 15px;
          font-weight: 700;
          padding-bottom: 10px;
          line-height: 1.2;
        }

        .des {
          font-size: 18px;
          color: $dark;
          font-weight: 500;

          a {
            color: $dark;
            font-weight: 500;

            &:hover {
              color: $orange;
            }
          }
        }
      }
    }
  }

  .contact-comment-box {
    .from-control {
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-color: #F1F1F1;
      box-shadow: 0 0 30px #eee;
      color: $darker-gray;
      width: 100%;
      max-width: 100%;
      opacity: 1;
      border-radius: 3px;
      border: 1px solid $white;
      background: $white;
      padding: 10px 18px;
    }

    .form-control:focus {
      outline: none !important;
      box-shadow: none;
      color: $gray-76;
    }

    .help-block {
      color: $red;
    }

    #userTerms {
      padding-top: 17px;
      color: $middle-blue;
    }

    textarea {
      height: 140px;
    }

    .form-group .btn-send {
      padding: 16px 0px 16px 0px;
      box-shadow: 0px 28px 50px 0px rgba(0, 0, 0, 0.05);
      outline: none;
      border: none;
      padding: 12px 40px;
      border-radius: 3px;
      display: inline-block;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 500;
      color: $white;
      background: $orange;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;
      width: 100%;
      text-transform: uppercase;

      &:hover {
        opacity: 0.90;
      }
    }

    &.new-style {
      padding: 60px 70px 60px !important;
    }
  }

  .contact-map {
    overflow: hidden;

    iframe {
      float: left;
      width: 100%;
      height: 550px;
      border: none;
    }
  }

  .contact-map2 {
    overflow: hidden;

    iframe {
      float: left;
      width: 100%;
      height: 720px;
      border: none;
    }
  }

  .contact-map3 {
    overflow: hidden;

    iframe {
      float: left;
      width: 100%;
      height: 600px;
      border: none;
    }
  }

  .contact-address-section {
    text-align: center;
    margin: 0 0 50px;

    .contact-info {
      background: $lightest-gray;
      display: flex;
      text-align: left;
      align-items: center;
      padding: 0 30px;
      min-height: 175px;
      border-radius: 5px;
      position: relative;
      transition: all 0.3s ease;

      .icon-part {
        margin-right: 30px;

        i {
          color: $orange;
          font-size: 30px;
          margin: 0;
          padding: 0;
          width: 70px;
          height: 70px;
          line-height: 58px;
          border-radius: 50%;
          text-align: center;
          background: transparent;
          border: 6px solid #f9ede9;
        }
      }

      .content-part {
        .info-subtitle {
          font-size: 18px;
          font-weight: 700;
          color: $mid-gray;
          margin: 0 0 5px;
        }

        .info-title {
          font-size: 24px;
          font-weight: 700;
          color: $dark;
          margin: 0;
        }

        a {
          color: $dark;
          display: block;

          &:hover {
            color: $orange;
          }
        }
      }

      &:hover {
        transform: translateY(-5px);
      }
    }

    &.style2 {
      margin: 0;
    }
  }

  .contact-comment-section {
    padding: 50px;

    h3 {
      font-size: 36px;
      margin: 0;
      padding-bottom: 15px;
      font-weight: 700;
    }

    form .form-group {
      input {
        height: 43px;
        padding: 0 15px;
        border: none;
        background: $white;
        border-radius: 0;
        box-shadow: none;
      }

      textarea {
        border: none;
        background: $white;
        border-radius: 0;
        box-shadow: none;
      }

      label {
        color: $mid-gray;
        font-weight: 400;
      }

      input.btn-send {
        text-transform: uppercase;
        color: $white;
        background-color: $orange;
        margin-top: 15px;
        border: none;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-weight: 600;
        padding: 0 50px;
        cursor: pointer;
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -ms-transition: 0.4s;

        &:hover {
          background: $red;
        }
      }
    }
  }

  .contact-bg1 {
    background: $lightest-gray;
  }
}

.from-control {
  input, textarea {
    border: none !important;

  }

  label {
    display: flex;
    color: #101010;
    align-items: center;
  }

  input[type="checkbox"] {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    accent-color: $blue;

    &:hover {
      accent-color: $blue;
    }
  }
}

.error-block {
  padding-top: 5px;
}

@media (max-width: 991px) {
  .two-column-contact {
    display: none;
  }
}

@media (min-width: 992px) {
  .one-column-contact {
    display: none;
  }
}

#userTerms {
  label {
    display: block;
  }
}