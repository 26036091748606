/*----------------------------------------
    31. Cart Section CSS
----------------------------------------*/

.rs-cart {
  .cart-wrap table.cart-table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 25px;
      text-align: center;
      border: 1px solid $ccc-gray;
    }

    th {
      padding: 25px;
      text-align: center;
      border: 1px solid $ccc-gray;
      border: none;
      font-size: 18px;
      padding: 25px;
      text-align: center;
      vertical-align: middle;
      font-weight: 700;
    }

    td {
      border-bottom: none;
      border-left: none;
      border-right: none;
      border-top: 1px solid $border-right-white;
    }

    .product-remove a {
      margin: 0 auto;
      color: $dark;
      display: block;
      border-radius: 100%;
      border: 1px solid $gray-ddd;
      font-size: 16px;
      font-weight: 400;
      height: 20px;
      width: 20px;
      line-height: 17px;
      text-align: center;

      &:hover {
        background: #ff0000;
        color: $white;
      }
    }

    .product-thumbnail {
      min-width: 32px;

      a img {
        width: 80px;
        height: auto;
      }
    }

    .product-name a {
      color: $mid-gray;
      font-weight: 700;

      &:hover {
        color: $light-blue;
      }
    }

    .product-price {
      font-weight: 700;
    }

    .product-quantity input {
      border: 1px solid $border-right-white;
      border-radius: 4px;
      padding: 0 0 0 10px;
      max-width: 65px;
      margin: 0 auto;
      outline: none;
    }

    .action .coupon {
      float: left;

      input {
        box-sizing: border-box;
        border: 1px solid #d3ced2;
        padding: 6px 6px 5px;
        margin: 0 4px 0 0;
        outline: none;
        width: 320px;
        border-radius: 4px;
        height: 45px;
        margin-right: 20px;
        text-align: left;
        padding-left: 22px;
      }
    }
  }

  .cart-collaterals {
    width: 100%;
    overflow: hidden;

    .cart-totals {
      float: right;
      width: 48%;

      .title {
        font-weight: 700;
        color: $mid-gray;
        text-transform: capitalize;
      }

      table.cart-total-table {
        text-align: left;
        width: 100%;
        border-collapse: collapse;
        border-radius: 5px;

        tr {
          border-top: 1px solid rgba(0, 0, 0, 0.1);

          th {
            font-size: 18px;
            font-weight: 700;
          }

          td, th {
            padding: 30px 10px;
          }
        }
      }
    }
  }

  &.orange-color .cart-wrap table.cart-table .product-name a:hover {
    color: $orange;
  }
}