/* ------------------------------------
    39. Preloader CSS
---------------------------------------*/

.loader {
  background-color: $white;
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 9999999;

  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 140px;
    border: 5px solid #ebebec;
    border-radius: 50%;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 140px;
      height: 140px;
      border-top: 4px solid $light-blue;
      border-radius: 50%;
      animation: loaderspin 1.8s infinite ease-in-out;
      -webkit-animation: loaderspin 1.8s infinite ease-in-out;
    }
  }

  .loader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;

    img {
      animation: loaderpulse alternate 900ms infinite;
    }
  }

  &.orange-color .loader-container:before {
    border-color: $orange;
  }

  &.yellow-color .loader-container:before {
    border-color: $yellow;
  }

  &.purple-color .loader-container:before {
    border-color: $deep-purple;
  }

  &.green-color .loader-container:before {
    border-color: $green;
  }
}

@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}
