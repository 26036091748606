$white : #ffffff;
$white-e8 : #e8e8e8;
$light-gray : #f3f8f9;
$box-shadow-gray : #eee;
$light-gray4 : #F9F7F8;
$light-gray3 : #e7f9fb;
$light-white : #f9f8f8;
$lightest-gray : #f9f9f9;
$border-white : #f2f2f2;
$border-right-white : #E6E6E6;
$bc-gray : #BCBCBC;
$gray-ddd : #ddd;
$ccc-gray : #ccc;
$gray-76 : #767676;
$lighter-gray : #666;
$gray-62 : #626262;
$mid-gray : #505050;
$gray-49 : #494949;
$darker-gray : #363636;
$middle-gray : #333333;
$black-gray : #222;
$dark : #111111;
$dark-black : #101010;
$black : #000000;
$dark-gray : #171F32;
$ocean-blue : #031a3d;
$dark-blue : #112958;
$warm-blue : #1c335f;
$middle-blue : #0266B3;
$deep-blue  : #2e4778;
$sea-blue : #0266B3;
$light-blue : #0266B3;
$gray-blue : #54647b;
$blue-gray : #394459;
$green : #0C8B51;
$light-purple : #625eaa;
$deep-purple : #4e49a1;
$ochre : #fcb903;
$yellow : #F4BF00;
$orange : #096AB9;
$red : #ed3600;
$blue : #096AB9;
$ips-dark-green : #70B537;
$ips-middle-green : #96C668;
$ips-light-green : #BFD781;
$ips-light-blue : #AEDEFB;
$ips-middle-blue : #28A6DD;
$new-blue : #2285ba;


