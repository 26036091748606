@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700,800');

@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800,900');

@import 'core/variables';
@import 'core/mixins';

@import "blocks/blog";
@import "blocks/general";
@import "blocks/global-class";
@import "blocks/header-section";
@import "blocks/sticky-menu";
@import "blocks/banner-section";
@import "blocks/slider-section";
@import "blocks/breadcrumbs-section";
@import "blocks/about-section";
@import "blocks/services-section";
@import "blocks/subject-section";
@import "blocks/categories-section";
@import "blocks/popular-courses-section";
@import "blocks/course-single-section";
@import "blocks/acordion-section";
@import "blocks/why-choose-us-section";
@import "blocks/team-section";
@import "blocks/team-single-section";
@import "blocks/testimonial-section";
@import "blocks/latest-events-section";
@import "blocks/download-section";
@import "blocks/partner-section";
@import "blocks/gallery-section";
@import "blocks/cta-section";
@import "blocks/counter-section";
@import "blocks/newsletter-section";
@import "blocks/publication-section";
@import "blocks/facilities-section";
@import "blocks/faq-section";
@import "blocks/error-section";
@import "blocks/shop-section";
@import "blocks/single-shop-section";
@import "blocks/cart-section";
@import "blocks/header-cart-section";
@import "blocks/woocommerce-section";
@import "blocks/checkout-section";
@import "blocks/login-section";
@import "blocks/register-section";
@import "blocks/contact-section";
@import "blocks/footer-section";
@import "blocks/scroll-up";
@import "blocks/preloader";
@import "blocks/teacher-section";

@import "styles/animation.scss";
@import "styles/base.scss";
@import "styles/layout.scss";
@import "styles/main.scss";
@import "styles/media.scss";

@import "styles/themes/all.scss";
@import "styles/themes/classic.scss";
@import "styles/themes/edgeless.scss";

/**
*
* -----------------------------------------------------------------------------
*
* Template : Educavo - Education HTML Template
* Author : rs-theme
* Author URI : http://www.rstheme.com/
*
* -----------------------------------------------------------------------------
*
@include media-breakpoint-down(xs) {}
**/

