/* -----------------------
    03. Header Section CSS
--------------------------*/

.full-width-header {
  .rs-header {
    z-index: 99;

    .topbar-area {
      background: $light-blue;

      .topbar-contact li {
        display: inline-block;
        line-height: 50px;
        height: 50px;
        margin-right: 17px;
        padding-right: 20px;
        color: $white;
        //border-right: 1px solid #374A71;

        i {
          margin-right: 2px;

          &.flaticon-email:before {
            bottom: -1.5px;
            position: relative;
          }

          &:before {
            font-size: 16px;
            color: $white;
          }
        }

        a {
          color: $white;

          &:hover {
            color: $ips-middle-green;
          }
        }

        &:last-child {
          margin: 0;
          border: none;
        }
      }

      .topbar-right li {
        display: inline;
        margin-right: 30px;

        &.login-register {
          color: $white;

          i {
            margin-right: 10px;
            color: $light-blue;
          }

          a {
            color: $white;

            &:hover {
              color: $light-blue;
            }
          }
        }

        a.apply-btn {
          color: $white;
          background: $light-blue;
          padding: 12px 35px;
          display: inline-block;

          &:hover {
            background: #2db4de;
          }
        }

        &:last-child {
          margin: 0;
        }
      }

      &.home8-topbar {
        background: $light-white !important;

        .topbar-contact li {
          color: $darker-gray;
          border-right: 1px solid $white;

          i {
            margin-right: 2px;

            &:before {
              font-size: 16px;
              color: $darker-gray;
            }
          }

          a {
            color: $darker-gray;

            &:hover {
              color: $orange;
            }
          }

          &:last-child {
            margin: 0;
            border: none;
          }
        }

        .topbar-right li {
          &.login-register {
            color: $darker-gray;

            i {
              margin-right: 10px;
              color: $darker-gray;
            }

            a {
              color: $darker-gray;

              &:hover {
                color: $orange;
              }
            }
          }

          a.apply-btn {
            background: $orange;
            text-transform: uppercase;

            &:hover {
              color: $white;
              background: $red;
            }
          }

          &:last-child {
            margin: 0;
          }
        }

        &.inner-part {
          background: transparent !important;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;

          .topbar-contact li {
            color: $white !important;

            i {
              margin-right: 2px;

              &:before {
                font-size: 16px;
                color: $white;
              }
            }

            a {
              color: $white !important;

              &:hover {
                color: $orange;
              }
            }

            &:last-child {
              margin: 0;
              border: none !important;
            }
          }

          .topbar-right li {
            &.login-register {
              color: $white;

              i {
                margin-right: 10px;
                color: $white !important;
              }

              a {
                color: $white !important;

                &:hover {
                  color: $orange;
                }
              }
            }

            a.apply-btn {
              background: $orange;
              text-transform: uppercase;

              &:hover {
                color: $white;
                background: $red;
              }
            }

            &:last-child {
              margin: 0;
            }
          }
        }
      }

      &.home9-topbar {
        background: $middle-blue !important;

        .topbar-contact li {
          color: $white;
          border-right: 1px solid #1b315e;

          i {
            margin-right: 2px;

            &:before {
              font-size: 16px;
              color: $yellow;
            }
          }

          a {
            color: $white;

            &:hover {
              color: $yellow;
            }
          }

          &:last-child {
            margin: 0;
            border: none;
          }
        }

        .topbar-right li {
          &.login-register {
            color: $white;

            i {
              margin-right: 10px;
              color: $white;
            }

            a {
              color: $white;
            }

            &:hover {
              i, a {
                color: $yellow;
              }
            }
          }

          a.apply-btn {
            background: $yellow;
            text-transform: uppercase;

            &:hover {
              color: $white;
              background: #c19700;
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      }

      &.home11-topbar {
        background: $green !important;

        .topbar-contact li {
          color: $white;
          border-right: 1px solid #00822b;

          i {
            margin-right: 4px;

            &:before {
              font-size: 14px;
              color: $white;
            }
          }

          a {
            color: $white;

            &:hover {
              color: #d1d1d1;
            }
          }

          &:last-child {
            margin: 0;
            border: none;
          }
        }

        .toolbar-sl-share {
          .opening {
            color: $white;
            display: inline-block;
            border-right: 1px solid #00822b;
            font-size: 14px;
            line-height: 50px;
            margin-right: 15px;
            padding-right: 18px;

            i {
              margin-right: 5px;

              &:before {
                font-size: 15px;
                font-weight: 600;
                color: $white;
              }
            }
          }

          li {
            display: inline-block;
            margin-right: 14px;

            a {
              line-height: 30px;
              color: $white;

              &:hover {
                color: $orange;
              }
            }

            &:last-child {
              margin: 0;
              border: none;
            }
          }
        }

        &.modify1 {
          background: $light-gray4 !important;

          .topbar-contact li {
            color: $middle-gray;
            border-right: 1px solid #f0ecee;

            i {
              margin-right: 4px;

              &:before {
                font-size: 14px;
                color: $orange;
              }
            }

            a {
              color: $middle-gray;

              &:hover {
                color: $orange;
              }
            }
          }

          .toolbar-sl-share {
            .opening {
              color: $middle-gray;
              border-right: 1px solid #f0ecee;

              i:before {
                color: $orange;
              }
            }

            li {
              a {
                color: $middle-gray;

                &:hover {
                  color: $orange;
                }
              }

              &:last-child {
                margin: 0;
                border: none;
              }
            }
          }

          &.home15-style {
            padding-left: 15.5%;
            padding-right: 10px;
          }
        }
      }
    }

    .logo-part {
      position: relative;
      z-index: 9;

      img {
        max-height: 60px;
      }
    }

    .menu-area {
      transition: all 0.3s ease;

      .logo-area {
        position: relative;
        height: 90px;
        line-height: 90px;

        img {
          transition: 0.4s;
          -webkit-transition: 0.4s;
          max-height: 48px;
        }

        .dark {
          display: none;
        }

        .light {
          display: inherit;
        }
      }

      .responsive-logo {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: none;

        img {
          max-height: 30px;
        }
      }

      .logo-cat-wrap {
        display: inline-block;
        align-items: center;
        height: 90px;
        line-height: 90px;

        .categories-btn .cat-menu-inner {
          top: 120px;

          li {
            height: 45px;
            line-height: 45px;

            a {
              padding: 0 30px;
            }
          }
        }
      }

      @media (min-width: 1200px) {
        .rs-menu-area {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .main-menu .rs-menu ul {
        &.nav-menu {
          li {
            display: inline-block;
            margin-right: 50px;
            padding: 0;

            a {
              transition: all 0.3s ease;
              font-size: 15px;
              font-weight: 500;
              text-transform: uppercase;
              height: 120px;
              line-height: 120px;
              padding-right: 0;
              color: $white;
              z-index: 1;
              position: relative;

              &:hover {
                color: $light-blue;
              }
            }

            &.menu-item-has-children a {
              padding-right: 12px;

              &:before {
                content: "+";
                position: absolute;
                left: auto;
                right: 0;
                top: 50%;
                text-align: center;
                display: block;
                cursor: pointer;
                transform: translateY(-50%);
                transition: all .5s ease;
              }

              &:hover:before {
                content: "-";
              }
            }

            &:last-child {
              margin-right: 0 !important;

              i {
                margin: 0;
              }
            }
          }

          &.onepage-menu .active-menu a {
            color: $light-blue !important;
          }
        }

        &.sub-menu {
          background: $light-blue;
          margin: 0;
          padding: 15px 0;

          li {
            margin: 0;
            border: none;

            a {
              height: unset !important;
              line-height: unset !important;
              color: $white !important;
              font-weight: 400 !important;
              text-transform: capitalize;
              padding: 10px 30px !important;

              &:hover {
                color: $middle-blue !important;
              }

              &:before {
                display: none !important;
              }
            }

            &:last-child {
              margin: 0;
            }

            &.current-menu-item > a, &.active > a {
              color: $middle-blue !important;
            }

            &.children-mega-menu .child-mega-menu {
              width: 100%;
              opacity: 1 !important;
              position: unset;
              transform: translateY(0px);

              .child-single-megamenu {
                width: 50%;
                float: left;

                .sub-menu {
                  padding: 0;
                }
              }
            }
          }

          &.right-menu {
            left: unset;
            right: 0;
          }
        }

        &.mega-menu {
          background: $middle-blue;

          .mega-menu-container {
            padding: 0;
            margin: 0;

            .single-megamenu {
              width: 50%;
              float: left;

              .sub-menu .menu-title {
                font-size: 17px;
                font-weight: 600;
                padding: 10px 30px;
                color: $white;
                text-transform: capitalize;
                transition: all 0.3s ease;
              }

              &:hover .sub-menu .menu-title {
                color: $white;
              }
            }
          }
        }

        li {
          a:hover, &.active a, &.current-menu-item > a {
            color: $light-blue !important;
          }
        }
      }

      .nav-expander {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease;

        span {
          height: 2px;
          width: 30px;
          display: block;
          background: $white;
          margin: 6px 0;
          transition: all 0.3s ease;

          &.dot1 {
            margin-top: 0;
          }

          &.dot3 {
            margin-bottom: 0;
          }
        }

        &:hover span {
          background: $orange;
        }

        &.style2 {
          span {
            background: $dark;
          }

          &:hover span {
            background: $orange;
          }
        }

        &.style3 {
          span {
            background: $dark;
          }

          &:hover span {
            background: $ips-middle-blue;
          }
        }

        &.style4 {
          span {
            background: $white;
          }

          &:hover span {
            background: $light-blue;
          }
        }

        &.style5 {
          span {
            background: $white;
          }

          &:hover span {
            background: $ccc-gray;
          }
        }

        &.style6 {
          span {
            background: $white;
          }

          &:hover span {
            background: $yellow;
          }
        }
      }

      .appointment-cart {
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 9;

        ul.cart {
          text-align: right;

          li {
            display: inline-block;
            position: relative;
            cursor: pointer;
            padding-right: 15px;

            i {
              color: $white;
              transition: all 0.3s ease;

              &:hover {
                color: $white;
              }
            }
          }
        }
      }

      .expand-btn-inner {
        margin-right: 55px;

        li {
          display: inline-block;
          margin-right: 15px;

          &.cart-inner {
            position: relative;
            margin-right: 15px;
            padding-right: 20px;

            .cart-icon {
              position: relative;
              height: 120px;
              line-height: 120px;
              z-index: 1;

              .cart-count {
                position: absolute;
                min-width: 18px;
                min-height: 18px;
                line-height: 18px;
                font-size: 11px;
                border-radius: 50%;
                background: $border-white;
                color: $mid-gray;
                top: -13px;
                right: -17px;
                text-align: center;
                transition: all 0.3s ease;
              }
            }

            &:before {
              content: '';
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              height: 20px;
              background: #7d8aa3;
            }

            &.no-border {
              padding-right: 0;

              &:before {
                display: none;
              }
            }
          }

          > a {
            color: $white;
            display: inline-block;
            cursor: pointer;
            z-index: 1;
            position: relative;

            &.short-border {
              position: relative;
              padding-right: 20px;

              &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 20px;
                background: #7d8aa3;
              }
            }

            i {
              &:before {
                font-size: 15px;
                font-weight: 600;
              }

              &.flaticon-shopping-bag-1:before {
                font-weight: normal;
              }
            }

            &:hover {
              color: $white;
            }
          }

          a.apply-btn {
            text-transform: uppercase;
            color: $white;

            &:hover {
              color: $light-blue;
            }
          }

          &:last-child {
            margin-right: 0 !important;
          }
        }
      }

      .categories-btn {
        .cat-btn {
          display: inline-flex;
          align-items: center;
          background: transparent;
          padding: 0;
          border: none;
          outline: unset;
          color: $white;
          text-transform: uppercase;

          i {
            margin-right: 5px;
            transition: all 0.3s ease;
          }

          &:hover i {
            color: $light-blue;
          }
        }

        .cat-menu-inner {
          position: absolute;
          top: 120px;
          width: 200px;
          background: $light-blue;
          padding: 15px 0;

          li a {
            color: $white;
            padding: 10px 30px;
            display: inline-block;
            text-transform: capitalize;
            width: 100%;

            &:hover {
              color: $middle-blue;
            }
          }
        }
      }

      &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: rgba(39, 60, 102, 0.9) !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

        .logo-area {
          img {
            max-height: 35px;
          }

          .dark {
            display: inherit;
          }

          .light {
            display: none;
          }
        }

        .logo-cat-wrap {
          height: 90px;
          line-height: 90px;

          .categories-btn .cat-menu-inner {
            top: 90px;
          }
        }

        .main-menu .rs-menu ul.nav-menu > li > a {
          height: 90px;
          line-height: 90px;
          color: $gray-49;

          &:hover {
            color: $light-blue;
          }
        }

        .nav-expander {
          span {
            background: $dark;
          }

          &:hover span {
            background: $orange;
          }

          &.style3 {
            span {
              background: $dark;
            }

            &:hover span {
              background: $light-blue;
            }
          }

          &.style5 {
            span {
              background: $white;
            }

            &:hover span {
              background: $ccc-gray;
            }
          }
        }

        .appointment-cart ul.cart li i {
          color: $gray-49;

          &:hover {
            color: $light-blue;
          }
        }

        .expand-btn-inner li {
          &.cart-inner {
            .cart-icon {
              height: 90px;
              line-height: 90px;
            }

            .woocommerce-mini-cart {
              padding-top: 90px;
            }
          }

          .cart-icon .cart-count {
            background: $light-blue;
            color: $white;
          }

          > a {
            color: $gray-49;

            &:hover {
              color: $light-blue;
            }
          }
        }
      }
    }

    .right_menu_togle {
      background: #fafafa;
      padding: 30px 50px;
      width: 500px;
      right: -500px;
      z-index: 999999;
      text-align: center;
      transition: all 0.3s ease;

      .close-btn {
        overflow: visible;
        padding: 0;

        .line {
          width: 40px;
          height: 40px;
          line-height: 40px;

          span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 1px !important;
            height: 20px !important;
            background: $white;
            border: none !important;
            transition: all 0.3s ease;

            &.line2 {
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
        }

        #nav-close {
          position: relative;
          float: unset;
          width: 40px;
          height: 40px;
          line-height: 40px;
          border: unset;
          border-radius: 50%;
          cursor: pointer;
          transition: all 0.3s ease;
          background: $light-blue;

          &:hover {
            transform: rotate(180deg);
          }
        }
      }

      .canvas-logo {
        padding-left: 0;
        padding-bottom: 30px;
        margin-top: 50px;

        img {
          max-height: 60px;

        }
      }

      .offcanvas-gallery {
        display: inline-block;

        .gallery-img {
          width: 33%;
          float: left;
          padding: 0 5px 10px;

          .image-popup {
            display: block;
            overflow: hidden;

            img {
              transition: all 0.3s ease;
            }

            &:hover img {
              transform: scale(1.1);
            }
          }
        }
      }

      .offcanvas-text {
        text-align: justify;

        .offcanvas-title {
          font-size: 20px;
          text-align: center;
        }

      }

      .map-img {
        margin-top: 25px;
        margin-left: -12px;

        iframe {
          height: 260px;
        }
      }

      .sidebarnav_menu li a {
        font-size: 17px;
        color: $black-gray;
        padding-left: 0;

        &:hover {
          color: $light-blue;
        }
      }

      .canvas-contact {
        padding: 50px 0 0;

        .social li {
          display: inline-block;
          padding-right: 20px;

          a {
            color: $dark;

            i {
              font-size: 18px;
              font-weight: bold;
              text-align: center;
            }

            &:hover {
              color: $light-blue;
            }
          }

          &:last-child {
            padding: 0;
          }
        }
      }
    }
  }

  &.header-style1 {
    width: 100%;
    z-index: 999;

    .rs-header .menu-area {
      background: $middle-blue;

      .main-menu {
        .mobile-logo-part {
          display: none;
        }

        .rs-menu {
          ul.nav-menu li.logo-part {
            padding: 0;
            margin: 0 90px 0 60px;
          }

          .rs-mega-menu .mega-menu {
            left: unset;
            right: 0;
            background: $white;
            min-width: 770px;

            .mega-menu-container .single-megamenu {
              width: 33.33%;
              float: left;

              .sub-menu {
                background: unset;
                padding-right: 30px;
                padding-left: 30px;
                padding-bottom: 30px;
                padding-top: 30px;
              }
            }
          }
        }
      }

      &.sticky {
        background: $middle-blue !important;

        .main-menu .rs-menu ul.nav-menu > li > a {
          color: $white;
        }

        .expand-btn-inner li {
          > a {
            color: $white;

            &:hover {
              color: $white;
            }
          }

          a.apply-btn {
            color: $white;

            &:hover {
              color: $light-blue;
            }
          }
        }
      }
    }

    &.home12-modifiy .rs-header .menu-area {
      background: $white !important;

      .rs-menu-area {
        justify-content: flex-end;

        .main-menu {
          .rs-menu ul {
            &.nav-menu li {
              margin-right: 35px;

              a {
                color: $middle-gray;
                font-weight: 500;

                &:hover {
                  color: $green !important;
                  color: $green !important;
                }
              }

              &.current-menu-item > a {
                color: $green !important;
              }
            }

            &.sub-menu {
              background: $green;

              li {
                margin-right: 35px;

                a {
                  color: $middle-gray;
                  font-weight: 500;

                  &:hover {
                    color: $green !important;
                  }
                }

                &.active a {
                  color: $green !important;
                }
              }
            }
          }

          .nav-expander {
            color: $dark;

            &:hover {
              color: $middle-blue;
            }
          }
        }
      }

      .expand-btn-inner {
        margin-right: 0;

        li {
          .woocommerce-mini-cart {
            .cart-bottom-part {
              background: $green;
            }

            .crt-btn {
              background: $white;
              color: $dark;

              &:hover {
                background: $border-white;
              }
            }
          }

          &.cart-inner {
            border-color: #a9b1c2;
          }

          > a {
            color: $blue-gray;

            &:hover {
              color: $green !important;
            }
          }
        }
      }

      &.sticky {
        background: $white !important;

        .logo-cat-wrap {
          display: flex;
          align-items: center;

          .logo-part {
            .dark-logo {
              display: none;
            }

            .light-logo {
              display: inherit;
            }
          }
        }
      }
    }

    &.home14-style {
      .rs-header .menu-area .rs-menu-area {
        justify-content: flex-end;

        .main-menu .rs-menu {
          ul {
            &.nav-menu li {
              a {
                margin-right: 0;

                &:hover {
                  color: $orange !important;
                  color: $orange !important;
                }
              }

              &.current-menu-item > a {
                color: $orange !important;
              }
            }

            &.sub-menu {
              background: $orange !important;

              li {
                a {
                  color: $white !important;
                  margin-right: 0;

                  &:hover {
                    color: $ccc-gray !important;
                  }
                }

                &.active a {
                  color: $ccc-gray !important;
                }
              }

              &.right {
                left: auto;
                right: 100%;
              }
            }
          }

          .rs-mega-menu .mega-menu {
            left: unset;
            right: 0;
            background: $orange;

            .mega-menu-container .single-megamenu {
              width: 33.33%;
              float: left;

              .sub-menu {
                padding-right: 30px;
                padding-left: 40px;
                padding-bottom: 40px;
                padding-top: 35px;
                background: unset;

                li a {
                  color: #f00;
                }
              }
            }
          }
        }
      }

      .expand-btn-inner li {
        a:hover i {
          color: $orange;
        }

        &.cart-inner {
          border-color: #1c2f5e !important;
        }

        > a {
          color: $dark !important;

          &:hover {
            color: $orange !important;
          }
        }

        .woocommerce-mini-cart {
          .cart-bottom-part {
            background: $orange;
          }

          .crt-btn {
            background: $white;
            color: $dark;

            &:hover {
              background: $border-white;
            }
          }
        }
      }
    }
  }

  &.header-style2 {
    width: 100%;
    z-index: 999;

    .rs-header .menu-area {
      background: $white;

      .logo-cat-wrap .logo-part {
        .dark-logo {
          display: inherit;
          position: relative;
        }

        .light-logo {
          display: none;
        }
      }

      .rs-menu-area {
        justify-content: flex-end;
        height: 90px;

        .main-menu .rs-menu {
          //fehér navbar rs-menu//
          top: 90px;

          @media (max-width: 439px) {
            top: 120px !important;
          }

          ul {
            &.nav-menu li {
              margin-right: 35px;

              a {
                color: $blue-gray;
                line-height: 90px;
                height: 90px;

                &:hover {
                  color: $light-blue;
                }
              }
            }

            &.sub-menu {
              background: $light-blue;

              li {
                margin: 0;

                a {
                  font-weight: 400 !important;
                  color: $white;

                  &:hover {
                    color: $ips-middle-blue !important;
                  }
                }

                &.active a {
                  color: $ips-middle-blue !important;
                }
              }
            }
          }

          .rs-mega-menu .mega-menu {
            left: unset;
            right: 0;
            background: $light-blue;
            min-width: 770px;

            .mega-menu-container .single-megamenu {
              width: 33.33%;
              float: left;

              .sub-menu {
                padding-right: 30px;
                padding-left: 30px;
                padding-bottom: 30px;
                padding-top: 30px;
              }
            }
          }

          ul li {
            a:hover, &.active a, &.current-menu-item > a {
              color: $ips-middle-blue !important;
            }
          }
        }
      }

      .expand-btn-inner li {
        &.cart-inner {
          border-color: #a9b1c2;
        }

        > a {
          color: $blue-gray;

          &:hover {
            color: $light-blue;
          }
        }
      }

      .categories-btn {
        .cat-btn {
          color: $blue-gray;

          i {
            margin-right: 10px;
          }

          &:hover i {
            color: $light-blue;
          }
        }

        .cat-menu-inner {
          background: $light-blue;

          #kreta-menu-dark {
            display: none;
          }

          li a {
            color: $white;

            &:hover {
              color: $middle-blue;
            }
          }
        }
      }

      &.sticky {
        background: $light-blue !important;

        .language-selector {
          color: white !important;
        }

        #kreta {
          a {
            color: white !important;
            &:hover {
              color: $ips-middle-green !important;
            }
          }
          
        }

        nav.rs-menu {
          top: 90px !important;
        }

        .logo-cat-wrap {
          display: inline-block;
          align-items: center;

          .logo-part {
            .dark-logo {
              display: none;
            }

            .light-logo {
              display: inherit;
              position: relative;
            }
          }
        }

        #dropdownMenuButton {
          color: $white;
        }

        .main-menu .rs-menu ul.nav-menu > li > a {
          color: $white;
          @media (max-width: 1099px) {
            color: $black;
          }
        }

        .expand-btn-inner li > a {
          color: $white;

          &:hover {
            color: $light-blue;
          }
        }

        .nav-expander.style3 {
          span {
            background: $white;
          }

          &:hover span {
            background: $ips-middle-blue;
          }
        }

        .categories-btn .cat-btn {
          color: $white;

          &:hover i {
            color: $light-blue;
          }
        }
      }
    }

    &.modify1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      .rs-header .menu-area {
        background: transparent;

        .rs-menu-area .main-menu .rs-menu ul {
          &.nav-menu li a {
            color: $white;

            &:hover {
              color: $light-blue;
            }
          }

          &.sub-menu {
            background: $light-blue;

            li {
              a {
                font-weight: 400 !important;
                color: $white;

                &:hover {
                  color: $gray-ddd !important;
                }
              }

              &.active a {
                color: $gray-ddd !important;
              }
            }
          }

          li {
            a:hover, &.active a, &.current-menu-item > a {
              color: $light-blue !important;
            }
          }
        }

        .expand-btn-inner li {
          &.cart-inner {
            border-color: #a9b1c2;
          }

          > a {
            color: $white;

            &:hover {
              color: $light-blue;
            }
          }
        }

        .categories-btn .cat-btn {
          color: $white;

          &:hover i {
            color: $light-blue;
          }
        }

        &.sticky {
          background: $middle-blue !important;

          .logo-cat-wrap {
            display: flex;
            align-items: center;

            .logo-part {
              .dark-logo {
                display: none;
              }

              .light-logo {
                display: inherit;
              }
            }
          }

          .main-menu .rs-menu ul.nav-menu > li > a {
            color: $white;
          }

          .expand-btn-inner li > a {
            color: $white;

            &:hover {
              color: $light-blue;
            }
          }

          .categories-btn .cat-btn {
            color: $white;

            &:hover i {
              color: $light-blue;
            }
          }

          .nav-expander {
            span {
              background: $white;
            }

            &:hover span {
              background: $ccc-gray;
            }
          }
        }
      }

      &.header-home6 .rs-header .rs-menu-area .main-menu .rs-menu {
        ul {
          &.nav-menu li {
            margin-right: 35px;

            a {
              color: $white;

              &:hover {
                color: $yellow !important;
              }
            }
          }

          &.sub-menu {
            background: $white !important;

            li {
              margin: 0;

              a {
                font-weight: 400 !important;
                color: $dark !important;

                &:hover {
                  color: $yellow !important;
                }
              }

              &.active a {
                color: $yellow !important;
              }
            }
          }
        }

        .rs-mega-menu .mega-menu {
          background: $white;
        }

        ul li {
          a:hover, &.active a, &.current-menu-item > a {
            color: $yellow !important;
          }
        }
      }
    }
  }

  &.header-style3 {
    width: 100%;
    z-index: 999;

    .rs-header {
      .menu-area {
        background: $light-blue;

        .logo-part {
          position: absolute;
          left: -260px;
          top: 50%;
          transform: translateY(-50%);

          .small-logo {
            display: none;
          }
        }

        .logo-cat-wrap .categories-btn {
          padding-left: 45px;
        }

        .rs-menu-area .main-menu {
          .rs-menu {
            ul {
              &.nav-menu li {
                margin-right: 35px;

                a {
                  color: $blue-gray;

                  &:hover {
                    color: $light-blue;
                  }
                }
              }

              &.sub-menu {
                background: $light-blue;

                li {
                  margin: 0;

                  a {
                    font-weight: 400 !important;
                    color: $white;

                    &:hover {
                      color: $middle-blue !important;
                    }
                  }

                  &.active a {
                    color: $middle-blue !important;
                  }
                }
              }
            }

            .rs-mega-menu .mega-menu {
              left: unset;
              right: 0;
              background: $light-blue;
              min-width: 780px;

              .mega-menu-container .single-megamenu {
                width: 33.33%;
                float: left;

                .sub-menu {
                  padding-right: 32px;
                  padding-left: 32px;
                  padding-bottom: 32px;
                  padding-top: 32px;
                }
              }
            }

            ul li {
              a:hover, &.active a, &.current-menu-item > a {
                color: $light-blue !important;
              }
            }
          }

          .nav-expander {
            color: $dark;

            &:hover {
              color: $middle-blue;
            }
          }
        }

        .expand-btn-inner li {
          &.cart-inner {
            border-color: #a9b1c2;
          }

          > a {
            color: $blue-gray;

            &:hover {
              color: $light-blue;
            }
          }
        }

        .categories-btn {
          .cat-btn {
            color: $blue-gray;

            i {
              margin-right: 10px;
            }

            &:hover i {
              color: $light-blue;
            }
          }

          .cat-menu-inner {
            background: $light-blue;

            li a {
              color: $white;

              &:hover {
                color: $middle-blue;
              }
            }
          }
        }

        &.sticky {
          background: $light-blue !important;

          .logo-cat-wrap {
            display: flex;
            align-items: center;

            .logo-part {
              .dark-logo {
                display: none;
              }

              .light-logo {
                display: inherit;
              }
            }
          }
        }
      }

      .right_menu_togle {
        .close-btn #nav-close {
          background: $deep-purple;

          &:hover {
            transform: rotate(180deg);
          }
        }

        .canvas-logo {
          padding-left: 0;
          padding-bottom: 30px;
          margin-top: 50px;

          img {
            max-height: 35px;
          }
        }

        .canvas-contact {
          padding: 50px 0 0;

          .social li {
            a:hover {
              color: $deep-purple;
            }

            &:last-child {
              padding: 0;
            }
          }
        }
      }
    }

    &.modify {
      .rs-header .menu-area {
        display: flex;
        background: $white;

        .logo-part {
          background: $light-blue;
          left: 0;
          height: 120px;
          line-height: 120px;
          width: 350px;
          text-align: center;
        }

        .logo-cat-wrap {
          display: flex;
          align-items: center;
          float: left;
        }

        .rs-menu-area {
          float: right;
          margin-right: -130px;
        }

        .expand-btn-inner {
          margin: 0;
          margin-left: 50px;

          .apply-btn {
            background: $light-blue;
            color: $white;
            line-height: 1;
            padding: 11px 24px;
            border-radius: 30px;

            &:hover {
              background: #1a84a4;
            }
          }

          .nav-expander {
            top: 0;
            right: 0;
            width: 130px;
            height: 120px;
            line-height: 120px;
            background: $light-blue;
            text-align: center;
            transform: unset;

            .bar {
              display: inline-block;
            }

            &:hover {
              color: $border-right-white;
            }
          }
        }

        &.sticky {
          .main-menu .rs-menu ul.nav-menu > li > a, .categories-btn .cat-btn, .expand-btn-inner li > a {
            color: $white;
          }

          .main-menu .rs-menu ul.nav-menu > li > a:hover, .categories-btn .cat-btn:hover, .expand-btn-inner li > a:hover, .main-menu .rs-menu ul.nav-menu > li > a:hover i, .categories-btn .cat-btn:hover i, .expand-btn-inner li > a:hover i, .main-menu .rs-menu ul li.current-menu-item > a {
            color: $dark !important;
          }

          .logo-part {
            height: 90px;
            line-height: 90px;
          }

          .expand-btn-inner {
            margin: 0;
            margin-left: 50px;

            li.cart-inner {
              border-color: $white;
            }

            .apply-btn {
              background: $white;
              color: $light-blue;

              &:hover {
                color: $dark;
              }
            }

            .nav-expander {
              height: 90px;
              line-height: 90px;
            }
          }
        }
      }

      &.home-7-modify2 .rs-header .menu-area .categories-btn .cat-btn {
        color: $mid-gray;

        &:hover {
          color: $light-blue;
        }
      }
    }

    &.home11-modify2 .rs-header .menu-area {
      display: flex;
      background: $white;

      .logo-part {
        left: 0;
        height: 120px;
        line-height: 120px;
        width: 350px;
        text-align: center;
      }

      .logo-cat-wrap {
        float: left;

        .categories-btn {
          .cat-btn {
            color: $blue-gray;

            i {
              margin-right: 10px;
            }

            &:hover i {
              color: $deep-purple;
            }
          }

          .cat-menu-inner {
            background: $deep-purple;

            li a {
              color: $white;

              &:hover {
                color: $light-blue;
              }
            }
          }
        }
      }

      .rs-menu-area {
        justify-content: flex-end;

        .main-menu .rs-menu {
          ul {
            &.nav-menu li {
              margin-right: 35px;

              a {
                color: $blue-gray;

                &:hover {
                  color: $light-blue;
                }
              }
            }

            &.sub-menu {
              background: $deep-purple;

              li {
                margin: 0;

                a {
                  font-weight: 400 !important;
                  color: $white !important;

                  &:hover {
                    color: $ccc-gray !important;
                  }
                }

                &.active a {
                  color: $ccc-gray !important;
                }
              }
            }
          }

          .rs-mega-menu .mega-menu {
            background: $deep-purple;
          }

          ul li {
            a:hover, &.current-menu-item > a {
              color: $deep-purple !important;
            }
          }
        }
      }

      .expand-btn-inner {
        margin: 0;
        margin-left: 50px;

        li .woocommerce-mini-cart {
          .cart-bottom-part {
            background: $deep-purple;
          }

          .crt-btn {
            background: $white;
            color: $dark;

            &:hover {
              background: $border-white;
            }
          }
        }

        .apply-btn {
          background: $deep-purple;
          color: $white;
          line-height: 1;
          padding: 16px 24px;
          border-radius: 30px;

          &:hover {
            background: $light-purple;
            color: $white;
          }
        }

        .nav-expander {
          top: 0;
          right: 0;
          width: 130px;
          height: 120px;
          line-height: 120px;
          background: $deep-purple;
          text-align: center;
          transform: unset;

          .bar {
            display: inline-block;
          }

          &:hover {
            color: $border-right-white;
          }
        }
      }

      &.sticky {
        background: $white !important;

        .main-menu .rs-menu ul.nav-menu > li > a, .categories-btn .cat-btn, .expand-btn-inner li > a {
          color: $blue-gray;
        }

        .main-menu .rs-menu ul.nav-menu > li > a:hover, .categories-btn .cat-btn:hover, .expand-btn-inner li > a:hover {
          color: $dark !important;
        }

        .main-menu .rs-menu ul li.current-menu-item > a {
          color: $deep-purple !important;
        }

        .expand-btn-inner {
          li.cart-inner {
            border-color: $white;
          }

          .apply-btn {
            background: $deep-purple;
            color: $white;

            &:hover {
              background: $light-purple;
              color: $white !important;
            }
          }

          .nav-expander {
            height: 90px;
            line-height: 90px;
          }
        }
      }
    }

    &.home-7-modify2 .rs-header .menu-area {
      background: $white !important;

      .rs-menu-area .main-menu .rs-menu {
        ul {
          &.nav-menu li {
            a {
              color: $dark !important;
              font-weight: 600 !important;
              padding-right: 18px !important;

              &:hover {
                color: $light-blue !important;
              }

              &:last-child {
                margin-right: none !important;
              }
            }

            &.current-menu-item > a {
              color: $light-blue !important;
            }
          }

          &.sub-menu {
            background: $white !important;

            li {
              margin: 0;

              a {
                font-weight: 400 !important;
                color: $dark !important;

                &:hover {
                  color: $light-blue !important;
                }
              }

              &.active a {
                color: $light-blue !important;
              }
            }
          }
        }

        .rs-mega-menu .mega-menu {
          background: $white;
        }
      }

      &.sticky {
        background: $white !important;

        .rs-menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
          color: $light-blue;
        }
      }
    }

    &.home-style15 .rs-header .menu-area {
      background: $white;

      .custom-table {
        display: flex;
        width: 100%;
        position: relative;
        align-items: center;
        justify-content: space-between;

        .header-logo .custom-logo-area {
          position: absolute;
          top: -51px;
          background: $orange;
          padding: 25px 42px 26px 51px;
          left: 0;

          a img {
            max-height: 93px;
          }

          &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 30px solid $orange;
            border-left: 30px solid transparent;
            transform: rotate(180deg);
            left: 0;
            bottom: -30px;
          }
        }

        .categore-menu {
          padding-right: 175px;
          display: flex;
          flex: 1;
          align-items: center;

          .logo-cat-wrap {
            height: 70px;
            line-height: 70px;

            .categories-btn {
              padding-left: 45px;
              padding-right: 45px;

              .cat-btn:hover i {
                color: $orange;
              }
            }

            .cat-menu-inner {
              top: 93px;
              background: $orange;
              width: 240px;

              li {
                height: 45px;
                line-height: 45px;

                a {
                  padding: 0 30px;

                  &:hover {
                    color: $ccc-gray;
                  }
                }
              }
            }
          }

          .search-widget .search-wrap {
            position: relative;

            [type=search] {
              outline: none;
              border: 1px solid $gray-ddd;
              color: #444;
              padding: 9px 17px;
              width: 100%;
              border-radius: 5px;
              width: 320px;
            }

            button {
              background: 0 0 !important;
              border: none;
              padding: 12px 18px;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 1;
              font-size: 16px;
              outline: none;
              border-radius: 0;
              color: $dark-gray;

              i:before {
                font-size: 18px;
                font-weight: 600;
                color: #ebebeb;
              }
            }
          }
        }

        .rs-menu-area .main-menu .rs-menu {
          ul {
            &.nav-menu li {
              margin-right: 35px;

              a {
                color: $blue-gray;
                height: 90px;
                line-height: 90px;

                &:hover {
                  color: $orange;
                }
              }

              &.menu-item-has-children a {
                padding-right: 12px;

                &:before {
                  transition: unset;
                }

                &:hover:before {
                  content: "-";
                }
              }
            }

            &.sub-menu {
              background: $orange !important;

              li {
                margin: 0;

                a {
                  font-weight: 400 !important;
                  color: $white;

                  &:hover {
                    color: $ccc-gray !important;
                  }
                }

                &.active a {
                  color: $ccc-gray !important;
                }
              }
            }
          }

          .rs-mega-menu .mega-menu {
            right: 270px;
            margin: 0 auto;
            max-width: 800px;
            background: $orange !important;

            .mega-menu-container .single-megamenu {
              width: 33.33%;
              float: left;

              .sub-menu {
                background: $orange !important;
                padding-right: 32px;
                padding-left: 32px;
                padding-bottom: 32px;
                padding-top: 32px;
              }
            }
          }

          ul li {
            a:hover, &.active a, &.current-menu-item > a {
              color: $orange !important;
            }
          }
        }

        .expand-btn-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 0;

          .icon-bar .cart-icon {
            position: relative;
            z-index: 1;
            padding-right: 10px;

            i {
              &:before {
                font-size: 18px;
                color: $dark;
              }

              &:hover:before {
                color: $orange !important;
              }
            }
          }

          .user-icon {
            margin-left: 0;
            padding-left: 25px;
            border-left: 1px solid #ebebeb;

            a {
              font-size: 18px;
              border: none;

              &:hover {
                color: $orange;
              }
            }
          }

          li .apply-btn {
            background: $orange;
            color: $white;
            padding: 10px 23px;
            line-height: 16px;
            border-radius: 30px;
            font-size: 14px;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      &.sticky {
        background: $white !important;

        .custom-table {
          .header-logo .custom-logo-area {
            top: -34px;
            background: $orange;
            padding: 51px 42px 26px 51px;

            a img {
              max-height: 50px;
            }
          }

          .categore-menu {
            padding-right: 0;

            .logo-cat-wrap .categories-btn {
              padding-right: 700px;
              padding-left: 0;

              .cat-menu-inner {
                top: 93px;
                background: $orange;
                width: 240px;

                li {
                  height: 45px;
                  line-height: 45px;

                  a {
                    padding: 0 30px;

                    &:hover {
                      color: $ccc-gray;
                    }
                  }
                }
              }
            }
          }
        }

        .categore-menu .search-widget {
          display: none;
        }

        .expand-btn-inner {
          .icon-bar, .user-icon {
            display: none;
          }
        }
      }
    }
  }

  &.home8-style4 {
    width: 100%;
    z-index: 999;

    .rs-header {
      .menu-area {
        background: $white !important;

        .logo-part {
          position: relative;
          z-index: 9;
        }

        .logo-area {
          position: relative;
          height: 90px;
          line-height: 90px;
        }

        .rs-menu-area .main-menu .rs-menu {
          ul {
            &.nav-menu li {
              a {
                color: $dark !important;

                &:hover {
                  color: $orange !important;
                }

                &:last-child {
                  margin-right: none !important;
                }
              }

              &.current-menu-item > a {
                color: $orange !important;
              }
            }

            &.sub-menu {
              background: $white !important;

              li {
                margin: 0;

                a {
                  font-weight: 400 !important;
                  color: $dark !important;

                  &:hover {
                    color: $orange !important;
                  }
                }

                &.active a {
                  color: $orange !important;
                }
              }
            }
          }

          .rs-mega-menu .mega-menu {
            left: unset;
            right: 0;
            background: $white;
            min-width: 770px;

            .mega-menu-container .single-megamenu {
              width: 33.33%;
              float: left;

              .sub-menu {
                padding-right: 30px;
                padding-left: 30px;
                padding-top: 30px;
                padding-bottom: 30px;
              }
            }
          }
        }

        .expand-btn-inner {
          .cart-icon i {
            font-size: 20px;
          }

          li {
            &.cart-inner {
              border-color: #1c2f5e !important;
            }

            > a {
              color: $dark !important;

              &:hover {
                color: $orange !important;
              }
            }

            .woocommerce-mini-cart {
              .cart-bottom-part {
                background: $orange;
              }

              .crt-btn {
                background: $white;
                color: $dark;

                &:hover {
                  background: $border-white;
                }
              }
            }
          }

          .apply-btn {
            background: $orange;
            color: $white !important;
            font-size: 15px;
            line-height: 24px;
            padding: 10px 24px;
            border-radius: 30px;
          }

          .nav-expander {
            color: $dark;
          }
        }

        &.sticky {
          background: $white !important;

          .rs-menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
            color: $light-blue;
          }
        }
      }

      .right_menu_togle {
        .close-btn #nav-close {
          background: $orange;
        }

        .sidebarnav_menu li a:hover, .canvas-contact .social li a:hover {
          color: $orange;
        }
      }
    }

    &.main-home {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      .menu-area {
        background: transparent !important;

        .logo-part .sticky-logo {
          display: none;
        }

        .rs-menu-area .main-menu {
          .rs-menu ul {
            &.nav-menu li {
              margin-right: 45px;

              a {
                color: $white !important;
              }
            }

            &.sub-menu {
              background: $white !important;
              min-width: 250px;
              padding-bottom: 30px;
              padding-top: 30px;
              border-radius: 0px;
              border-bottom: 5px solid $orange;
              border-color: $orange !important;

              li {
                margin: 0;

                a {
                  font-weight: 400 !important;
                  color: $dark !important;

                  &:hover {
                    color: $orange !important;
                  }
                }

                &.active a {
                  color: $orange !important;
                }
              }
            }
          }

          .rs-menu-toggle {
            color: $white !important;
          }
        }

        &.menu-sticky.sticky {
          .logo-part {
            .sticky-logo {
              display: block;
            }

            .normal-logo {
              display: none;
            }
          }

          .rs-menu-area .main-menu .rs-menu ul.nav-menu li {
            a {
              color: $dark !important;
              padding-right: 18px !important;

              &:hover {
                color: $orange !important;
              }

              &:last-child {
                margin-right: none !important;
              }
            }

            &.current-menu-item > a {
              color: $orange !important;
            }
          }

          .expand-btn-inner {
            li {
              .cart-icon .cart-count {
                background: $light-blue;
                color: $white;
              }

              > a {
                color: $gray-49;

                i:before {
                  color: $dark !important;
                }

                &:hover {
                  color: $light-blue;

                  i:before {
                    color: $orange !important;
                  }
                }
              }
            }

            .user-icon > a {
              line-height: 90px;
              height: 90px;
            }

            .woocommerce-mini-cart {
              padding-top: 90px;
            }

            .nav-expander {
              color: $dark !important;
            }
          }
        }
      }

      .expand-btn-inner {
        .cart-icon i {
          font-size: 20px;
        }

        li a {
          i:before {
            color: $white !important;
          }

          &:hover i:before {
            color: $orange !important;
          }
        }

        .user-icon > a {
          position: relative;
          line-height: 120px;
          height: 120px;
          display: flex;
          align-items: center;

          i {
            color: $white;
            border: 1px solid $box-shadow-gray;
            width: 32px;
            display: block;
            text-align: center;
            border-radius: 50%;
            font-size: 14px;
            height: 32px;
            line-height: 29px;

            &:before {
              color: $white;
            }
          }
        }

        .nav-expander {
          color: $white !important;

          &:hover {
            color: $orange !important;
          }
        }
      }

      .right_menu_togle {
        .close-btn #nav-close {
          background: $orange;
        }

        .sidebarnav_menu li a:hover, .canvas-contact .social li a:hover {
          color: $orange;
        }
      }
    }

    &.home9 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      .menu-area {
        background: transparent !important;
        box-shadow: unset !important;

        .logo-part .sticky-logo {
          display: none;
        }

        .rs-menu-area .main-menu .rs-menu ul {
          &.nav-menu li {
            a {
              color: $white !important;
              font-weight: 600 !important;
              padding-right: 18px !important;

              &:hover {
                color: $yellow !important;
              }

              &:last-child {
                margin-right: none !important;
              }
            }

            &.current-menu-item > a {
              color: $yellow !important;
            }
          }

          &.sub-menu {
            background: $white !important;
            min-width: 250px;
            padding-bottom: 30px;
            padding-top: 30px;
            border-radius: 0px;
            border-bottom: 5px solid $yellow;
            border-color: $yellow !important;

            li {
              margin: 0;

              a {
                font-weight: 400 !important;
                color: $dark !important;

                &:hover {
                  color: $yellow !important;
                }
              }

              &.active a {
                color: $yellow !important;
              }
            }
          }
        }

        &.menu-sticky.sticky {
          .logo-part {
            .sticky-logo {
              display: block;
            }

            .normal-logo {
              display: none;
            }
          }

          .rs-menu-area .main-menu .rs-menu ul.nav-menu li {
            a {
              color: $dark !important;
              padding-right: 18px !important;

              &:hover {
                color: $yellow !important;
              }

              &:last-child {
                margin-right: none !important;
              }
            }

            &.current-menu-item > a {
              color: $yellow !important;
            }
          }

          .expand-btn-inner {
            li {
              .cart-icon .cart-count {
                background: $light-blue;
                color: $white;
              }

              > a {
                color: $gray-49;

                i:before {
                  color: $dark !important;
                }

                &:hover {
                  color: $light-blue;

                  i:before {
                    color: $yellow !important;
                  }
                }
              }
            }

            .user-icon > a {
              line-height: 90px;
              height: 90px;
            }
          }
        }
      }

      .expand-btn-inner {
        .cart-icon i {
          font-size: 20px;
        }

        li {
          a {
            i:before {
              color: $white !important;
            }

            &:hover i:before {
              color: $yellow !important;
            }
          }

          .woocommerce-mini-cart .cart-bottom-part {
            background: $yellow !important;
          }
        }

        .user-icon > a {
          position: relative;
          line-height: 120px;
          height: 120px;
          display: flex;
          align-items: center;

          i:before {
            color: $white;
          }
        }
      }

      .right_menu_togle {
        .close-btn #nav-close {
          background: $yellow;
        }

        .sidebarnav_menu li a:hover, .canvas-contact .social li a:hover {
          color: $yellow;
        }
      }
    }

    &.home13 {
      .menu-area {
        .rs-menu-area {
          justify-content: flex-end;
        }

        .rs-menu .nav-menu .rs-mega-menu .mega-menu {
          background: $white;
          left: unset;
          right: 0;

          .mega-menu-container .single-megamenu {
            width: 33.33%;
            float: left;

            .sub-menu {
              background: unset;
              padding-right: 30px;
              padding-left: 41px;
            }
          }
        }
      }

      .expand-btn-inner {
        margin-right: unset;

        li a i {
          border-right: 1px solid $white;
          padding-right: 24px;
        }

        .user-icon > a i {
          border: unset;
        }
      }
    }
  }

  &.transparent {
    position: absolute;

    .rs-header .menu-area {
      .rs-menu-area .main-menu .rs-menu ul.nav-menu li a {
        color: $white !important;

        &:hover {
          color: $light-blue;
        }
      }

      .expand-btn-inner li {
        &.cart-inner {
          border-color: #1c2f5e !important;
        }

        > a {
          color: $white !important;

          &:hover {
            color: $light-blue;
          }
        }
      }

      &.menu-sticky {
        background: none;
        border-bottom: none;
      }
    }
  }

  &.home7-style4 .rs-header .menu-area {
    background: $white !important;

    .logo-cat-wrap .logo-part {
      background: red !important;
    }

    .rs-menu-area .main-menu .rs-menu ul.nav-menu li a {
      color: $dark !important;

      &:hover {
        color: $light-blue;
      }
    }

    .expand-btn-inner .nav-expander {
      right: -196px;
      position: absolute;
      color: $white !important;
      background: $light-blue;
      padding: 40px 40px;
    }
  }

  &.home1-modifiy .rs-header .menu-area {
    background: $white !important;

    .rs-menu-area .main-menu .rs-menu ul {
      &.nav-menu li {
        margin-right: 40px !important;

        a {
          color: $dark !important;
          font-weight: 600 !important;
          padding-right: 18px !important;
          margin-right: unset !important;

          &:hover {
            color: $light-blue !important;
          }

          &:last-child {
            margin-right: 0 !important;
          }
        }

        &.current-menu-item > a {
          color: $light-blue !important;
        }
      }

      &.sub-menu {
        background: $white !important;

        li {
          margin: 0;

          a {
            font-weight: 400 !important;
            color: $dark !important;

            &:hover {
              color: $light-blue !important;
            }
          }

          &.active a {
            color: $light-blue !important;
          }
        }
      }
    }

    .expand-btn-inner {
      .cart-icon i {
        font-size: 20px;
      }

      li {
        &.cart-inner {
          border-color: #1c2f5e !important;
        }

        > a {
          color: $dark !important;

          &:hover {
            color: $light-blue !important;
          }
        }
      }

      .apply-btn {
        background: $light-blue;
        color: $white !important;
        font-size: 15px;
        line-height: 24px;
        padding: 10px 24px;
        border-radius: 30px;
      }

      .nav-expander {
        color: $dark;
      }
    }

    &.sticky {
      background: $white !important;

      .rs-menu-area .main-menu .rs-menu ul.nav-menu li a:hover {
        color: $light-blue;
      }
    }
  }
}

body {
  .search-modal {
    .modal-content {
      background: transparent;
      position: initial;
      border: 0;
    }

    .search-block input {
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      background: transparent;
      border-width: 0 0 1px 0;
      border-radius: 0;
      border-color: rgba(255, 255, 255, 0.4);
      box-shadow: none;
      color: $white;
      font-weight: 600;
      font-size: 18px;
    }

    .close {
      color: $white;
      margin-top: 20px;
      font-size: 14px;
      background-color: transparent;
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      opacity: 1;
      outline: none;
      transition: all 0.3s ease;

      &:hover {
        color: $orange !important;
        opacity: 1 !important;
      }
    }
  }

  .modal-backdrop {
    opacity: 0.95 !important;
  }
}

.flag-rounded {
  width: 30px;
  height: 20px;
  margin-bottom: 5px
}

.language-selector {
  background-color: transparent !important;
  border: none !important;
  margin-right: -12px;
  box-shadow: none !important;
  margin-left: 10px;

  a {
    display: inline-block;
    margin-right: 20px;
  }
}

.nav-link:focus {
  outline: none !important;
  box-shadow: none;
}

.flex-start {
  justify-content: flex-start !important;
}

.language-selector-element {
  a {
    &:hover {
      background-color: $light-blue !important;
      color: white !important;

      .dark-select-option {
        color: white !important;
      }
    }
  }
}

.dark-logo {
  height: 60px !important;
}

.rs-menu-parent {
  &:hover {
    background-color: #0266B3 !important;
    color: white;
  }
}

.right-side-menu {
  position: absolute;
  right: 0;
  width: 100%;
}

#language-selector-menu {
  button {
    color: $black;
  }
}

@media (max-width: 1199px) {
  .rs-menu-shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  }
}

.z-1000 {
  z-index: 1000;
}

#nav-expander {
  padding-right: 20px;
}

//@media (min-width: 1200px) {
//  .rs-menu ul ul {
//    top: 105px;
//  }
//}

@media (min-width: 992px) and (max-width: 1199px) {
  .logo-part {
    display: inline-block;
  }

  .language-selector {
    display: inline-block;
  }
}

@media (min-width: 440px) and (max-width: 991px) {
  .logo-part {
    display: inline-block;
  }

  .language-selector {
    display: inline-block;
  }
}

@media (max-width: 439px) {
  .language-selector {
    position: relative;
    top: -8px;
  }

  .menu-area {
    height: 120px;
  }

  .logo-cat-wrap {
    display: inline-block !important;
  }

  i.fas.fa-bars {
    line-height: 120px !important;
  }
}

.dark-select-option {
  color: #212529;
}

.topbar-contact {
  display: flex;
  justify-content: flex-end;
}

#kreta {
  margin-left: 20px;
  a {
    &:hover {
      color: $ips-middle-green !important;
    }
    color:black !important;
  }
  
}

#kreta-logo {
  max-height: 40px !important;
}

#kreta-logo-white {
  img{
    max-height: 40px !important;
  }
}

#kreta-logo-dark {
  img {
    max-height: 40px !important;
  }
}

@media (min-width: 992px) {
  #language-selector-menu {
    display: none;
  }
}

@media (min-width: 992px) {
  #kreta {
    display: none;
  }
}

#dark-logo-small {
  display: inherit;
}

@media (min-width: 1341px) {
  #dark-logo-small {
    display: none;
  }
}

@media (max-width: 1340px) {
  #dark-logo-full {
    display: none;
  }
  .light-logo {
    display: none !important;
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
    font-size: 13px !important;
  }
  div.rs-menu-area:nth-child(1) {
    display: flex;
  }
  div.rs-menu-area:nth-child(2) {
    display: flex;
  }

}

.full-width-header.header-style2 .rs-header .menu-area .rs-menu-area .main-menu .rs-menu ul.nav-menu li {
  margin-right: 25px;
}

@media (max-width: 1099px) {
  .scrollable-menu {
    height: auto;
    max-height: 300px;
    overflow-x: hidden;
  }
}