/*----------------------------------------
    33. Checkout Section CSS
----------------------------------------*/

.rs-checkout {
  .checkout-title {
    margin-bottom: 30px;

    h3 {
      font-size: 26px;
      margin: 0;
    }
  }

  .coupon-toggle .accordion .card {
    border: unset;
    border-top: 3px solid $light-blue;
    border-radius: 0;

    .card-header {
      border: none;
      margin: 0;
      border-radius: unset;

      .card-title {
        margin: 0;

        span i {
          margin-right: 10px;
        }

        button {
          background: unset;
          border: none;
          color: $middle-blue;
          transition: all 0.3s ease;
          outline: none;
          cursor: pointer;

          &:hover {
            color: $light-blue;
          }
        }
      }
    }

    .card-body {
      border: 1px solid #d3ced2;
      padding: 20px 20px 50px 20px;
      margin-top: 2em;
      text-align: left;

      .coupon-code-input {
        width: 47%;
        float: left;
        margin-right: 50px;

        input {
          width: 100%;
          height: 45px;
          outline: none;
          padding: 10px 18px;
          color: $mid-gray;
          border: 1px solid rgba(54, 54, 54, 0.1);
        }
      }
    }
  }

  .full-grid {
    margin-top: 25px;

    .form-content-box {
      margin-bottom: 50px;

      .form-group {
        label {
          line-height: 2;
          display: block;
          margin-bottom: 5px;
          font-weight: 600;
        }

        select {
          color: $lighter-gray;
          opacity: .5;
          padding-left: 8px;
          padding-right: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border: 1px solid $ccc-gray;
          border-radius: 0;
          height: 45px;
          line-height: 45px;
          cursor: pointer;
          width: 100%;
          outline: none;
        }

        textarea {
          height: 4em;
          line-height: 1.5;
          display: block;
          box-shadow: none;
          width: 100%;
          border: 1px solid rgba(54, 54, 54, 0.1);
          padding: 10px 18px;
          margin: 0;
          outline: none;
        }

        .form-control-mod {
          border: 1px solid rgba(54, 54, 54, 0.1);
          height: 45px;
          padding: 10px 18px;
          width: 100%;
          margin: 0;
          outline: none;
          line-height: normal;
          border-radius: unset;

          &.margin-bottom {
            margin-bottom: 10px !important;
          }
        }
      }
    }

    .ordered-product table {
      width: 100%;

      tr {
        th {
          border: 1px solid $ccc-gray;
          padding: 9px 12px;
        }

        td {
          border: 1px solid $ccc-gray;
          padding: 6px 12px;
        }
      }
    }

    .payment-method .top-area {
      border-bottom: 1px solid #d3ced2;

      .payment-co {
        margin-bottom: 20px;

        span {
          font-weight: 600;
          margin-right: 10px;
        }
      }

      .p-msg {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        padding: 1em;
        margin: 1em 0 2em;
        font-size: .92em;
        border-radius: 2px;
        line-height: 1.5;
        background-color: $lightest-gray;

        &:before {
          content: '';
          display: block;
          border: 1em solid $lightest-gray;
          border-right-color: transparent;
          border-left-color: transparent;
          border-top-color: transparent;
          position: absolute;
          top: -0.75em;
          left: 0;
          margin: -1em 0 0 2em;
        }
      }
    }
  }

  &.orange-color .coupon-toggle .accordion .card {
    border-color: $orange;

    .card-header .card-title button:hover {
      color: $orange;
    }
  }
}