/* ------------------------------------
    23. Counter Section CSS
---------------------------------------*/

.rs-counter {
  .counter-item {
    .rs-count {
      position: relative;
      display: inline-block;
      font-size: 42px;
      line-height: 1;
      color: $dark-blue;
      padding-right: 25px;
      margin-bottom: 15px;

      &:before {
        content: '';
        position: absolute;
        right: 0;
      }

      &.plus:before {
        content: '+';
      }

      &.kplus:before {
        content: 'k+';
        right: -25px;
      }

      &.percent:before {
        content: '%';
        right: -15px;
      }
    }

    .title {
      font-size: 22px;
      color: $mid-gray;
    }

    .counter-text {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
    }
  }

  &.style2-about .counter-item {
    .rs-count {
      color: $dark;
    }

    .title {
      font-size: 22px;
      color: $mid-gray;
    }
  }

  &.style7 .counter-item {
    .rs-count {
      &.purple-color {
        color: #c701f1;
      }

      &.blue-color {
        color: #065ce8;
      }

      &.pink-color {
        color: #f6075b;
      }

      &.orange-color {
        color: #ffbc00;
      }
    }

    .title {
      color: $gray-blue;
    }
  }

  &.style-home8 .counter-item {
    padding: 40px 10px 32px 10px;
    background-color: $light-white;
    border-radius: 4px 4px 4px 4px;

    .rs-count {
      color: $orange;
    }

    .title {
      color: $ocean-blue;
    }
  }

  &.home12-style .counter-item {
    .rs-count, .prefix {
      color: $green;
      font-size: 42px;
      font-weight: 900;
      line-height: 51px;
      font-family: "Nunito", Sans-serif;
    }

    .title {
      color: $mid-gray;

      &:hover {
        color: $green;
      }
    }
  }

  &.home13-style .counter-item {
    transition: all 500ms ease;

    .rs-count {
      color: $orange;
      font-size: 42px;
      font-weight: 700;
      line-height: 42px;
      margin-bottom: 5px;
    }

    .title {
      font-size: 22px;
      font-weight: 500;
      line-height: 37px;
      color: $white;
    }

    &:hover {
      transform: translateY(-5px);
    }
  }
}