/* ------------------------------------
    06. Slider Section CSS
---------------------------------------*/

.rs-slider {
  &.style1 {
    position: relative;

    .slider-content {
      padding: 250px 0;

      .sl-sub-title {
        font-size: 48px;
        line-height: 58px;
        font-family: 'Nunito', sans-serif;
        margin-bottom: 24px;
      }

      .sl-title {
        font-size: 90px;
        line-height: 100px;
        margin-bottom: 39px;
        margin-left: -5px;
      }
    }

    .owl-nav {
      .owl-next, .owl-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 50%;
        background: $light-blue;
        text-align: center;
        color: $white;
        transition: all 0.5s;
        transition-delay: 0.70s;
        opacity: 0;
        visibility: hidden;
      }

      .owl-next i:before, .owl-prev i:before {
        content: "\f138";
        font-family: Flaticon;
      }

      .owl-next {
        right: 30px;
        left: unset;

        i:before {
          content: "\f137";
        }
      }
    }

    &:hover .owl-nav {
      .owl-next, .owl-prev {
        left: 50px;
        transition-delay: 0s;
        visibility: visible;
        opacity: 1;
      }

      .owl-next {
        right: 50px;
        left: unset;
      }
    }
  }

  &.style2 {
    .slide-part {
      background: $light-white;
      padding: 100px 0 0;

      .img-part {
        margin-bottom: -80px;
        overflow: hidden;
      }

      .content {
        padding: 100px 0 100px;

        .title {
          font-size: 55px;
          line-height: 70px;
          font-family: 'Nunito', sans-serif;
          margin-bottom: 40px;
          color: $ocean-blue;
        }

        .sub-title {
          font-size: 16px;
          line-height: 25px;
          color: $orange;
          margin-bottom: 20px;
          text-transform: uppercase;
        }
      }
    }

    .owl-nav {
      .owl-next, .owl-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        cursor: pointer;
        text-align: center;
        color: $white;
        transition: all 0.5s;
        transition-delay: 0.70s;
        opacity: 0;
        visibility: hidden;
      }

      .owl-next i:before, .owl-prev i:before {
        content: "\f138";
        font-family: Flaticon;
      }

      .owl-next:hover, .owl-prev:hover {
        background: $orange;
        color: $white;
      }

      .owl-next {
        right: 30px;
        left: unset;

        i:before {
          content: "\f137";
        }
      }
    }

    &:hover .owl-nav {
      .owl-next, .owl-prev {
        left: 50px;
        transition-delay: 0s;
        visibility: visible;
        opacity: 1;
      }

      .owl-next {
        right: 50px;
        left: unset;
      }
    }

    .owl-carousel .owl-stage-outer {
      padding: 0 0 80px;
      margin: 0 0 -80px;
    }
  }

  &.main-home {
    position: relative;

    .slider-content {
      text-align: center;

      .content-part {
        padding: 250px 35px 400px;
        max-width: 900px;
        margin: 0 auto;

        .sl-sub-title {
          font-size: 22px;
          line-height: 30px;
          font-weight: 500;
          color: $orange;
          margin-bottom: 22px;
          text-transform: uppercase;
        }

        .sl-title {
          font-size: 70px;
          line-height: 80px;
          font-weight: 700;
          color: $white;
          margin-bottom: 65px;
        }
      }

      &.slide1 {
        background: url(assets/images/slider/main-home/1.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.slide2 {
        background: url(assets/images/slider/main-home/2.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .owl-nav {
      .owl-next, .owl-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 50%;
        background: $orange;
        text-align: center;
        color: $white;
        transition: all 0.5s;
        transition-delay: 0.70s;
        opacity: 0;
        visibility: hidden;
      }

      .owl-next i:before, .owl-prev i:before {
        content: "\f138";
        font-family: Flaticon;
      }

      .owl-next:hover, .owl-prev:hover {
        background: $red;
      }

      .owl-next {
        right: 30px;
        left: unset;

        i:before {
          content: "\f137";
        }
      }
    }

    &:hover .owl-nav {
      .owl-next, .owl-prev {
        left: 50px;
        transition-delay: 0s;
        visibility: visible;
        opacity: 1;
      }

      .owl-next {
        right: 50px;
        left: unset;
      }
    }
  }
}

@media (min-width: 992px) {
  .slideshow-content-container {
    min-height: 400px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slideshow-content-container {
    min-height: 325px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .slideshow-content-container {
    min-height: 300px;
  }
}

@media (max-width: 576px) {
  .slideshow-content-container {
    min-height: 215px;
  }
}