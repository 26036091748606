/* ------------------------------------
    24. Newsletter Section CSS
---------------------------------------*/

.rs-newsletter {
  &.style1 {
    .newsletter-wrap {
      background: url(assets/images/bg/newsletter-bg.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 3px;
      padding: 60px 70px;
      position: relative;

      .content-part .sec-title {
        display: flex;
        align-items: center;

        .title-icon img {
          max-width: 50px;
          margin-right: 20px;
        }
      }

      .newsletter-form {
        position: relative;
        border-radius: 3px;
        overflow: hidden;

        input, button {
          outline: none;
          border: none;
        }

        input {
          background: $white;
          width: 100%;
          color: $mid-gray;
          padding: 17px 30px;
          padding-right: 150px;
        }

        button {
          position: absolute;
          right: 0;
          top: 0;
          background: $middle-blue;
          color: $white;
          padding: 17px 40px;
          transition: all 0.3s ease;

          &:hover {
            background: $deep-blue;
          }
        }
      }
    }

    &.modify1 .newsletter-wrap {
      background: url(assets/images/bg/newslatter-bg15.jpg);

      .newsletter-form button {
        background: $orange;

        &:hover {
          background: $orange;
        }
      }
    }
  }

  &.style2 {
    background: url(assets/images/bg/newsletter-bg2.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    .sec-title {
      .title {
        margin-bottom: 10px;
      }

      .sub-title {
        margin: 0;
        text-transform: capitalize;
        line-height: 2.5rem;
      }
    }

    .newsletter-wrap {
      border-radius: 3px;
      padding: 60px 70px;
      padding-left: 0;
      position: relative;

      .newsletter-form {
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        max-width: 500px;

        input, button {
          outline: none;
          border: none;
        }

        input {
          background: $white;
          width: 100%;
          color: $mid-gray;
          padding: 17px 30px;
          padding-right: 150px;
        }

        button {
          position: absolute;
          right: 0;
          top: 0;
          background: $light-blue;
          color: $white;
          padding: 17px 40px;
          transition: all 0.3s ease;

          &:hover {
            background: #2db4de;
          }
        }
      }
    }

    &.home11-style {
      background: url(assets/images/bg/home11-news.jpg);
      background-size: cover;
      background-repeat: no-repeat;

      .sec-title2 {
        .title {
          margin-bottom: 10px;
        }

        .sub-title {
          margin: 0;
          text-transform: capitalize;
        }
      }

      .newsletter-wrap {
        padding: unset;

        .newsletter-form {
          input {
            outline: none;
            border: none;
          }

          button {
            outline: none;
            border: none;
            position: absolute;
            right: 5px;
            top: 5px;
            background: $deep-purple;
            color: $white;
            padding: 12px 40px 12px;
            border-radius: 5px;

            &:hover {
              background: $light-purple;
            }
          }
        }
      }
    }
  }

  &.style6 .newsletter-wrap {
    .content-part {
      .title {
        font-size: 42px;
        line-height: 46px;
        font-weight: 600;
        color: $ocean-blue;
      }

      .sub-title {
        font-weight: 400;
        line-height: 30px;
        font-size: 20px;
        color: $gray-blue;
      }
    }

    .newsletter-form {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      max-width: 600px;
      border-radius: 4px;
      overflow: hidden;

      input, button {
        outline: none;
        border: none;
      }

      input {
        background: $white;
        color: $mid-gray;
        padding: 18px 40px;
        padding-right: 150px;
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        background: $light-blue;
        color: $white;
        padding: 17px 40px;
        transition: all 0.3s ease;
        border-radius: 0 0 5px 5px;

        &:hover {
          background: $deep-blue;
        }
      }

      #email {
        width: 100%;
      }

      span {
        margin: 0;
      }
    }
  }

  &.modify {
    .newsletter-wrap .newsletter-form button {
      position: absolute;
      right: 4px;
      top: 4px;
      background: $light-blue;
      color: $white;
      padding: 14px 38px;
      transition: all 0.3s ease;
      border-radius: 3px;
    }

    &.home13 .newsletter-wrap .newsletter-form button {
      background: $orange;

      &:hover {
        background: $deep-blue;
      }
    }
  }

  &.home8-style1 .content-wrap .newsletter-form {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    border-radius: 4px;
    overflow: hidden;

    input, button {
      outline: none;
      border: none;
    }

    input {
      background: $white;
      color: $mid-gray;
      padding: 18px 40px;
      padding-right: 150px;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      background: $orange;
      color: $white;
      padding: 17px 40px;
      transition: all 0.3s ease;
      border-radius: 0 3px 3px 0;

      &:hover {
        background: $deep-blue;
      }
    }

    #email {
      width: 100%;
    }
  }

  &.main-home .newsletter-wrap {
    background: $dark-gray;
    padding: 60px 70px;
    position: relative;
    border-radius: 5px;

    .newsletter-form {
      position: relative;
      border-radius: 3px;
      overflow: hidden;

      input, button {
        outline: none;
        border: none;
      }

      input {
        background: $white;
        width: 100%;
        color: $mid-gray;
        padding: 17px 30px;
        padding-right: 150px;
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        background: $orange;
        color: $white;
        padding: 17px 40px;
        transition: all 0.3s ease;
        text-transform: uppercase;

        &:hover {
          background: #FF6030;
        }
      }
    }
  }

  &.orange-color .newsletter-wrap {
    background: $dark-gray;

    .newsletter-form button {
      background: $orange;

      &:hover {
        background: #ff683b;
      }
    }
  }

  &.yellow-color .newsletter-wrap {
    background: #172e58;

    .newsletter-form button {
      background: $yellow;
      color: $warm-blue;

      &:hover {
        background: #ffcb0f;
      }
    }
  }

  &.green-color .newsletter-wrap {
    background: $green;

    .newsletter-form {
      position: relative;
      border-radius: 3px;
      overflow: hidden;

      input, button {
        outline: none;
        border: none;
      }

      input {
        background: #127c4b;
      }

      button {
        background: #0fcb75;
        text-transform: uppercase;

        &:hover {
          background: #22cc7f;
        }
      }
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */

      color: $white;
      opacity: 1;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */

      color: $white;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* IE 10+ */

      color: $white;
      opacity: 1;
    }

    :-moz-placeholder {
      /* Firefox 18- */

      color: $white;
      opacity: 1;
    }
  }
}