/*------------------------------------
    04. Sticky Menu CSS
--------------------------------------*/

.menu-sticky {
  background: $white;
  margin: 0;
  z-index: 999;
  width: 100%;
  top: 0;
  position: relative;
}

@-webkit-keyframes sticky-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes sticky-animation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}