/*----------------------------------------
    30. Single Shop Section CSS
----------------------------------------*/

.rs-single-shop {
  .single-product-image {
    .images-single {
      z-index: 1 !important;
    }

    img {
      width: 100%;
    }
  }

  .single-price-info {
    .product-title {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 12px;
    }

    .single-price {
      color: $light-blue;
      font-weight: 600;
    }

    .some-text {
      margin-top: 15px;
    }

    form {
      margin-bottom: 30px;

      input {
        height: 40px;
        width: 70px;
        line-height: 40px;
        text-align: center;
        padding-left: 10px;
        border: 1px solid rgba(54, 54, 54, 0.1);
        outline: none;
      }
    }

    p.category {
      margin: 0;
      padding-top: 25px;
      border-top: 1px solid $border-right-white;
      font-size: 14px;

      span {
        font-weight: 700;
        padding-right: 10px;
      }

      a {
        color: $mid-gray;

        &:hover {
          color: $light-blue;
        }
      }
    }

    .tag {
      margin: 0;

      span {
        font-weight: 700;
      }
    }
  }

  .tab-area {
    margin-top: 50px;

    ul.nav-tabs {
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 32px;

      li {
        margin-right: 3px;

        a {
          padding: 10px 15px;
          display: inline-block;
          border-radius: 5px 5px 0 0;
          background: #f0f0f0;
          color: $dark;
          font-weight: 600;

          &:hover, &.active {
            background: $light-blue;
            color: $white;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .tab-content .tab-pane {
      .tab-title {
        font-weight: 700;
        margin-bottom: 34px;
      }

      .dsc-p {
        margin: 0;
      }

      .reviews-grid {
        .top-area {
          margin-bottom: 5px;

          p {
            margin-bottom: 23px;
          }

          h4 {
            font-weight: 400;
            color: unset;
          }
        }

        .comment-form {
          max-width: 600px;

          p.comment-notes {
            margin-bottom: 15px;
          }

          .placeholder-cntrl {
            label {
              font-weight: 600;
              display: block;
            }

            input {
              border: 1px solid rgba(54, 54, 54, 0.1);
              padding: 10px 15px;
              margin-bottom: 10px;
              width: 100%;
            }

            textarea {
              width: 100%;
              padding: 10px 15px;
              height: 75px;
              border: 1px solid rgba(54, 54, 54, 0.1);
              margin-bottom: 20px;
            }
          }

          .ratings {
            font-weight: 600;
            margin-bottom: 18px;
            display: block;

            ul li {
              display: inline;

              i {
                color: $light-blue;
                font-weight: normal;
              }
            }
          }

          .readon {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 15px;
            padding: 0 22px;
          }
        }
      }
    }
  }

  &.orange-color {
    .single-price-info {
      .single-price, p.category a:hover {
        color: $orange;
      }
    }

    .tab-area {
      .tab-content .tab-pane .reviews-grid .comment-form .ratings ul li i {
        color: $orange;
      }

      ul.nav-tabs li a {
        &:hover, &.active {
          background: $orange;
        }
      }
    }
  }
}