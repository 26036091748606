/* ------------------------------------
    11. Popular Courses Section CSS
---------------------------------------*/

.rs-popular-courses {
  &.style1 {
    .courses-item {
      padding: 30px;
      border: 1px solid #dfe9eb;
      border-radius: 5px;
      transition: all 0.3s ease;

      .img-part {
        margin-bottom: 30px;

        img {
          width: 100%;
          border-radius: 5px;
          transition: all 0.3s ease;
        }
      }

      .content-part {
        .meta-part {
          margin-bottom: 17px;

          li {
            display: inline;
            margin-right: 10px;

            span.price {
              display: inline-block;
              padding: 3px 20px;
              color: $white;
              font-weight: 500;
              background: $light-blue;
              border-radius: 3px;
              transition: all 0.3s ease;
            }

            a.categorie {
              color: $mid-gray;
            }

            &:last-child {
              margin: 0;
            }
          }
        }

        .title {
          font-size: 24px;

          a {
            color: $dark;
          }
        }

        .bottom-part {
          overflow: hidden;

          .info-meta {
            float: left;
            padding-top: 10px;

            li {
              display: inline;
              margin-right: 15px;
              transition: all 0.3s ease;

              &.user i {
                color: $mid-gray;
                margin-right: 8px;
                transition: all 0.3s ease;
              }

              &.ratings i {
                color: $ochre;
              }

              &:last-child {
                margin: 0;
              }
            }
          }

          .btn-part {
            text-align: right;

            a {
              padding: 5px 13px;
              background: $light-gray;
              border: 1px solid $light-blue;
              display: inline-block;
              border-radius: 3px;
              color: $dark;

              i:before {
                font-size: 18px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    &.modify1 .courses-item {
      overflow: hidden;
      transition: all 0.3s ease;

      .img-part {
        overflow: hidden;
        margin-bottom: 35px;
        border-radius: 4px;

        img {
          transition: all 500ms ease;
          transform: scale(1);
        }
      }

      .content-part .title {
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 12px;

        a {
          color: $dark;

          &:hover {
            color: $orange;
          }
        }
      }

      &:hover .img-part img {
        transform: scale(1.1);
      }
    }
  }

  &.style2 .course-wrap {
    background: #c7e8f0;
    position: relative;
    border-radius: 10px;
    text-align: center;
    transition: all 0.3s ease;
    overflow: hidden;
    padding: 30px;

    .front-part {
      z-index: 1;
      position: relative;
      transition: all 0.3s ease;
      opacity: 1;
      visibility: visible;

      .img-part {
        margin-bottom: 21px;
      }

      .content-part {
        a.categorie {
          color: $mid-gray;
        }

        .title {
          margin: 7px 0 0 0;

          a {
            color: $dark;
          }
        }
      }
    }

    .inner-part {
      z-index: 1;
      position: absolute;
      top: 40%;
      left: 0;
      width: 100%;
      padding: 0 30px;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      opacity: 0;
      visibility: hidden;

      .content-part {
        a.categorie {
          color: $white;

          &:hover {
            color: $dark;
          }
        }

        .title {
          margin: 10px 0 25px;

          a {
            color: $white;

            &:hover {
              color: $dark;
            }
          }
        }

        .course-meta li {
          color: $white;
          display: inline;
          margin-right: 15px;

          &.course-user i {
            margin-right: 6px;
          }

          &.ratings i {
            margin-right: 3px;
            color: $ochre;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .price-btn {
      position: absolute;
      bottom: 0;
      padding-bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease;
      opacity: 0;
      visibility: hidden;

      a {
        font-weight: 500;
        background: $white;
        border-radius: 3px;
        color: $dark;
        display: inline-block;
        padding: 5px 5px 5px 10px;

        i {
          padding-left: 5px;

          &:before {
            font-size: 15px;
            color: $white;
            width: 41px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: $light-blue;
            display: inline-block;
            border-radius: 3px;
            transition: all 0.3s ease;
          }
        }

        &:hover i:before {
          background: $dark;
        }
      }
    }

    &:after, &:before {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.3s ease;
      z-index: 0;
      background: #b5e2ec;
    }

    &:after {
      width: 146px;
      height: 146px;
      left: -73px;
      bottom: 70px;
    }

    &:before {
      width: 412px;
      height: 412px;
      right: -133px;
      top: -80px;
    }

    &:hover {
      background: #16aaca;

      .front-part {
        opacity: 0;
        visibility: hidden;
      }

      .inner-part, .price-btn {
        opacity: 1;
        visibility: visible;
      }

      &:after, &:before {
        background: #1ca6c7;
      }
    }
  }

  &.style3 .courses-item {
    border: 1px solid #dfe9eb;
    transition: all 0.3s ease;

    .img-part img {
      width: 100%;
      transition: all 0.3s ease;
    }

    .content-part {
      position: relative;
      padding: 40px 25px;

      a.categories {
        display: inline-block;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        color: $white;
        font-weight: 500;
        background: $light-blue;
        border-radius: 3px;
        transition: all 0.3s ease;
        position: absolute;
        top: -20px;
      }

      .meta-part {
        margin-bottom: 17px;

        li {
          display: inline;
          margin-right: 10px;
          font-weight: 700;

          &:last-child {
            margin: 0;
          }
        }
      }

      .title a {
        color: $dark;
      }

      .bottom-part {
        overflow: hidden;

        .info-meta {
          float: left;

          li {
            display: inline;
            margin-right: 15px;
            transition: all 0.3s ease;

            &.ratings i {
              color: $ochre;
            }

            &:last-child {
              margin: 0;
            }
          }
        }

        .btn-part {
          text-align: right;

          a {
            color: $mid-gray;

            i:before {
              font-size: 18px;
              font-weight: 700;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }

  &.style4 {
    .sec-title {
      .title {
        font-weight: 800;
      }

      .sub-title {
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 500;
        color: #485973;
      }
    }

    .courses-item {
      border: 1px solid #dfe9eb;
      transition: all 0.3s ease;

      .img-part img {
        width: 100%;
        transition: all 0.3s ease;
      }

      .content-part {
        position: relative;
        padding: 40px 25px;

        .price {
          display: inline-block;
          padding: 0 15px;
          height: 40px;
          line-height: 40px;
          color: $white;
          font-weight: 500;
          background: $light-blue;
          border-radius: 3px;
          transition: all 0.3s ease;
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
        }

        a.categories {
          color: #485973;
          display: inline-block;
          margin-bottom: 10px;
        }

        .meta-part {
          margin-bottom: 17px;

          li {
            display: inline;
            margin-right: 10px;
            font-weight: 700;

            &:last-child {
              margin: 0;
            }
          }
        }

        .title {
          font-size: 24px;

          a {
            color: $dark;
          }
        }

        .bottom-part {
          overflow: hidden;

          .user {
            float: left;
            margin-right: 20px;
          }

          .info-meta {
            float: left;

            li {
              display: inline;
              margin-right: 15px;
              transition: all 0.3s ease;

              &.ratings i {
                color: $ochre;
              }

              &:last-child {
                margin: 0;
              }
            }
          }

          .btn-part {
            text-align: right;

            a {
              color: $mid-gray;

              i:before {
                font-size: 18px;
                font-weight: 700;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  &.style5 .courses-item {
    .courses-grid {
      .img-part {
        overflow: hidden;

        a img {
          border-radius: 5px;
          transition: all 500ms ease;
          transform: scale(1);
        }
      }

      .content-part {
        padding: 50px 30px 50px;
        position: relative;
        text-align: center;
        background: $light-white;
        border-color: $light-white;

        .course-price .price {
          padding: 9px 20px;
          color: $white;
          font-weight: 500;
          background: $orange;
          border-radius: 5px;
          transition: all 0.3s ease;
          position: absolute;
          top: -25px;
          left: 50%;
          transform: translateX(-50%);
        }

        .title {
          font-size: 22px;
          line-height: 32px;
          margin-bottom: 18px;

          a {
            color: $ocean-blue;

            &:hover {
              color: $light-blue;
            }
          }
        }

        .meta-part {
          text-align: center;

          .user {
            display: inline-block;
            margin-right: 8px;
            color: $darker-gray;

            i:before {
              color: $orange;
              padding-right: 6px;

              &:last-child {
                padding-left: 0;
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    &:hover .courses-grid .img-part a img {
      transform: scale(1.1);
    }
  }

  &.style6 .courses-item {
    background: $white;
    border-radius: 5px;
    transition: .4s;
    overflow: hidden;

    .img-part {
      overflow: hidden;

      img {
        border-radius: 5px;
        transition: all 500ms ease;
        transform: scale(1.1);
      }
    }

    .content-part {
      padding: 25px 0px 25px 20px;

      .title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 700;
        margin-bottom: 0;

        a {
          color: $dark-black;

          &:hover {
            color: $orange;
          }
        }
      }

      .meta-part li .price {
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;
        color: $orange;
      }
    }

    &:hover .img-part img {
      transform: scale(1);
    }
  }

  &.course-view-style {
    .course-part {
      margin: 0 -15px;

      .courses-item {
        float: left;
        width: 46%;
        margin: 0 15px;
        margin-bottom: 30px;

        &.right {
          float: right;
        }
      }
    }

    .course-search-part {
      background-color: $white;
      border: 1px solid #e0e0e08c;
      margin-bottom: 30px;
      padding: 15px 30px;
      display: inline-flex;
      width: 100%;

      .course-view-part {
        display: flex;
        align-items: center;
        float: left;
        width: 90%;

        .view-icons {
          float: left;
          margin-right: 20px;
          line-height: 1;

          a {
            color: $mid-gray;
            font-size: 20px;
            opacity: 0.7;

            &.view-grid, &:hover {
              color: $orange;
              opacity: 1;
            }
          }
        }
      }

      .type-form {
        position: relative;
        float: right;

        select {
          display: block;
          width: 100%;
          min-width: 125px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: 500;
          color: $white;
          padding: 0 40px 0 20px;
          background: $orange;
          border: none;
          border-radius: 4px;
          -webkit-appearance: none;
          -moz-appearance: none;
          -webkit-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          transition: all 300ms ease;
          cursor: pointer;
        }

        .custom-select-box {
          position: relative;

          &:before {
            font-family: 'FontAwesome';
            content: "\f107";
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            color: $white;
            font-size: 16px;
            line-height: 1;
          }
        }
      }
    }

    &.list-view {
      .course-part {
        margin: 0;

        .courses-item {
          width: 100%;
          margin: 0 0 30px;
          display: flex;
          align-items: center;

          .img-part {
            margin: 0;
            margin-right: 30px;
          }
        }
      }

      .course-search-part .course-view-part .view-icons a {
        &.view-grid {
          color: $mid-gray;
          opacity: 0.7;
        }

        &.view-list {
          color: $orange;
          opacity: 1;
        }
      }
    }

    .widget-archives .categories {
      margin-left: 25px;
    }

    .filter-widget {
      position: relative;

      h5 {
        position: relative;
        padding: 12px 25px;
        padding-left: 0;
        font-size: 16px;
        font-weight: 600;
        color: #03382e;
        display: block;
        border-bottom: 1px solid #f0f5fb;
      }
    }

    .filter-form {
      position: relative;

      span {
        position: relative;
        color: $gray-62;
        font-size: 16px;
        display: block;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .radio-box {
        position: relative;
        margin-left: 25px;
        margin-bottom: 12px;

        label {
          position: relative;
          display: block;
          width: 100%;
          line-height: 1;
          padding-left: 25px;
          cursor: pointer;
          margin-bottom: 0;
          -webkit-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          transition: all 300ms ease;

          &:before {
            position: absolute;
            left: 0;
            top: 3px;
            height: 10px;
            width: 10px;
            background: #b6b6b6;
            content: "";
            border-radius: 50px;
            -webkit-transition: all 300ms ease;
            -moz-transition: all 300ms ease;
            -ms-transition: all 300ms ease;
            -o-transition: all 300ms ease;
            transition: all 300ms ease;
            border: 1px solid transparent;
          }

          &:after {
            position: absolute;
            left: 0px;
            top: 0;
            height: 15px;
            line-height: 15px;
            max-width: 0;
            font-size: 14px;
            color: $white;
            font-weight: 800;
            overflow: hidden;
            background: transparent;
            text-align: center;
            font-family: 'FontAwesome';
            -webkit-transition: max-width 500ms ease;
            -moz-transition: max-width 500ms ease;
            -ms-transition: max-width 500ms ease;
            -o-transition: max-width 500ms ease;
            transition: max-width 500ms ease;
          }
        }

        input[type="radio"] {
          &:checked {
            + label {
              border-color: #25a9e0;
            }

            + label {
              &:before {
                border: 5px solid $orange;
                background: $white;
              }

              &:after {
                max-width: 20px;
                opacity: 1;
              }
            }
          }

          display: none;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }

      .check-box {
        margin-left: 25px;
        margin-bottom: 12px;

        label {
          padding-left: 10px;
          line-height: 1;
        }
      }
    }
  }

  &.main-home {
    .courses-item {
      .courses-grid {
        padding: 0px;
        box-shadow: 0 0 30px $box-shadow-gray;
        border-radius: 0 0 4px 4px;
        background: $white;

        .img-part {
          margin-bottom: 0px;
          overflow: hidden;

          a img {
            border-radius: 4px 4px 0 0;
            transition: all .8s ease;
            transform: scale(1);
          }
        }

        .content-part {
          padding: 32px 35px 35px 35px;

          .info-meta {
            padding-bottom: 10px;

            li {
              display: inline-block;
              margin-right: 10px;
              transition: all 0.3s ease;

              i {
                color: $ochre;
              }
            }
          }

          .course-price .price {
            padding: 3px 15px;
            color: $white;
            font-weight: 500;
            background: $orange;
            border-radius: 5px;
            transition: all 0.3s ease;
            position: absolute;
            top: 20px;
            right: 38px;
          }

          .title {
            margin: 0 0 10px;
            line-height: 30px;
            font-size: 22px;

            a {
              color: $dark-black;

              &:hover {
                color: $orange;
              }
            }
          }

          .meta-part {
            display: flex;
            justify-content: space-between;
            list-style: none;
            margin: 25px 0 0;
            padding: 25px 0 0;
            font-size: 14px;
            border-top: 1px solid #f4f0f0;

            .user {
              display: inline-block;
              margin-right: 8px;
              color: $darker-gray;

              i:before {
                color: $orange;
                padding-right: 6px;
              }
            }
          }
        }
      }

      &:hover .courses-grid .img-part a img {
        transform: scale(1.1);
      }
    }

    &.home12-style .courses-item .courses-grid .content-part {
      padding: 32px 35px 35px 35px;

      .info-meta {
        padding-bottom: 10px;

        li i {
          color: $ochre;
        }
      }

      .course-price .price {
        background: $green;
      }

      .title {
        margin: 0 0 10px;
        line-height: 30px;
        font-size: 22px;

        a {
          color: $dark-black;

          &:hover {
            color: $green;
          }
        }
      }

      .meta-part {
        border-top: 1px solid #f4f0f0;

        .user {
          display: inline-block;
          margin-right: 8px;
          color: $darker-gray;

          i:before {
            color: $green;
            padding-right: 6px;
          }
        }
      }
    }
  }

  &.orange-color {
    .courses-item .content-part {
      .meta-part li span.price {
        background: $orange;
      }

      .title a:hover {
        color: $orange;
      }

      .bottom-part .btn-part a {
        border: 1px solid $border-white;
        background: transparent;

        &:hover {
          background: $orange;
          color: $white;
        }
      }
    }

    &.style2 .course-wrap {
      background: $orange;

      .price-btn a {
        i:before {
          background: $orange;
        }

        &:hover i:before {
          background: #ff4007;
        }
      }

      &:after, &:before {
        background: $orange;
      }

      &:hover {
        background: $orange;

        &:after, &:before {
          background: $orange;
        }
      }
    }

    &.style3 .courses-item .content-part {
      a.categories {
        background: $orange;
        color: $white;
      }

      .meta-part li span.price {
        background: unset;
      }

      .title a {
        color: $dark;

        &:hover {
          color: $orange;
        }
      }

      .bottom-part .btn-part a {
        border: unset;

        &:hover {
          background: transparent;
          color: $orange;
        }
      }
    }

    &.style4 .courses-item .content-part {
      .price {
        background: $orange;
      }

      .bottom-part .btn-part a {
        border: unset;
        border: unset;

        &:hover {
          background: transparent;
          color: $orange;
        }
      }
    }
  }

  &.home11-style {
    .courses-item {
      border: 1px solid #e5e4f1;
      padding: 30px;
      position: relative;
      transition: all .4s ease;

      .content-part {
        padding: 30px 0 0px;

        .course-body {
          .title {
            font-size: 24px;
            line-height: 34px;
            font-weight: 800;
            margin-bottom: 12px;

            a {
              color: $deep-purple;

              &:hover {
                color: $light-blue;
              }
            }
          }

          p {
            font-size: 16px;
            line-height: 28px;
            color: $gray-blue;
            font-weight: 400;
            margin-bottom: 20px;
          }
        }

        .bottom-part {
          border-top: 1px solid #e5e4f1;
          padding: 20px 0 0;

          .course-footer {
            display: inline-block;
            border-right: 1px solid #e5e4f1;
            padding-right: 15px;
            padding-left: 15px;

            .label {
              display: block;
              font-size: 16px;
              line-height: 28px;
              color: $gray-blue;
              font-weight: 500;
            }

            .desc {
              font-size: 14px;
              line-height: 23px;
              color: $light-blue;
              font-weight: 400;
            }
          }
        }
      }

      .btn-part {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        clip-path: none;
        background: $deep-purple;
        position: absolute;
        text-align: center;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease;

        a {
          font-size: 16px;
          color: $white;
          display: inline-block;
          font-weight: 500;

          &:hover {
            color: $light-blue;
          }
        }
      }

      &:hover .btn-part {
        bottom: -20px;
        opacity: 1;
        visibility: visible;
      }
    }

    .owl-carousel .owl-stage-outer {
      padding-bottom: 20px;
      margin-bottom: -20px;
    }
  }

  &.home13-style .courses-item {
    .courses-grid {
      border-radius: 0 0 4px 4px;

      .img-part {
        margin-bottom: 0px;
        overflow: hidden;

        a img {
          border-radius: 4px 4px 0 0;
          transition: all .8s ease;
          transform: scale(1);
        }
      }

      .content-part {
        padding: 25px 25px 25px 25px;
        border-style: solid;
        border-width: 0px 1px 1px 1px;
        border-color: #252525;

        .course-price .price {
          padding: 3px 15px;
          color: $white;
          font-weight: 500;
          background: $orange;
          border-radius: 5px;
          transition: all 0.3s ease;
          position: absolute;
          top: 20px;
          right: 38px;
        }

        .title {
          margin: 0 0 10px;
          line-height: 30px;
          font-size: 22px;

          a {
            color: $white;

            &:hover {
              color: $orange;
            }
          }
        }

        .meta-part {
          display: flex;
          justify-content: space-between;
          list-style: none;
          font-size: 15px;
          margin: 0 0 20px;

          .user {
            display: inline-block;
            margin-right: 8px;
            color: $white;

            i:before {
              color: $orange;
              padding-right: 6px;
            }
          }
        }

        .avatar-part {
          display: flex;
          align-items: center;
          margin-top: 20px;

          .avatar-img {
            width: 30px;
            height: 30px;
            margin-right: 10px;

            img {
              border-radius: 50%;
            }
          }

          .title {
            font-size: 15px;
            color: $white;
            line-height: 27px;
            margin-bottom: 0;
          }
        }
      }
    }

    &:hover .courses-grid .img-part a img {
      transform: scale(1.1);
    }
  }
}

.free-course-contact {
  margin: 0px;
  padding: 40px 50px 50px;
  background: url(assets/images/bg/course-contact-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  span {
    font-size: 30px;
    color: $dark;
    display: block;
  }

  form {
    input, textarea, select {
      width: 100%;
      border-radius: 3px;
      border: 1px solid $white;
      color: $gray-76;
      background: $white;
      padding: 10px 18px;
    }

    input, select {
      margin-bottom: 30px;
      height: 45px;
    }

    textarea {
      height: 140px;
      display: inherit;
    }

    .select-option {
      position: relative;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        z-index: 11;
        position: relative;
      }

      &:after {
        content: '\f107';
        font-family: FontAwesome;
        color: $gray-76;
        right: 15px;
        position: absolute;
        top: 16px;
        font-size: 20px;
        pointer-events: none;
      }
    }

    ::-webkit-input-placeholder, :-ms-input-placeholder, ::placeholder {
      color: $gray-76;
    }
  }
}

.rs-latest-couses {
  .course-item {
    align-items: flex-start;
    border-radius: 300px 5px 5px 300px;
    display: flex;
    border: 1px solid #ceeaf0;
    background: $white;

    .course-image a {
      width: 200px;
      height: 200px;
      overflow: hidden;
      border-radius: 100%;
      display: block;

      img {
        object-fit: cover;
        object-position: left;
        height: 100%;
        width: 100%;
      }
    }

    .course-info {
      align-self: center;
      padding: 0px 30px;

      .meta-part {
        padding-bottom: 5px;

        li {
          display: inline;
          margin-right: 10px;
          font-weight: 700;
        }
      }

      .course-title {
        font-size: 22px;
        line-height: 36px;
        font-weight: 700;
        margin-bottom: 5px;

        a {
          color: $dark;

          &:hover {
            color: $light-blue;
            transition: all 0.3s ease;
          }
        }
      }

      .bottom-part {
        .info-meta {
          float: left;

          ul .ratings {
            display: inline-block;
            margin-right: 10px;
            transition: all 0.3s ease;

            i {
              color: $ochre;
            }
          }
        }

        .btn-part {
          text-align: right;

          a {
            color: $mid-gray;

            i:before {
              font-size: 18px;
              font-weight: 700;
              padding-left: 10px;
            }

            &:hover {
              color: $light-blue;
              transition: all 0.3s ease;
            }
          }
        }
      }
    }

    &:hover {
      background: $white;
      border-color: $white;
    }
  }

  &.orange-color .course-item {
    border: 1px solid $orange;

    .course-info .course-title a:hover, .bottom-part .btn-part a:hover {
      color: $orange;
    }

    &:hover {
      background: $white;
      border-color: $white;
    }
  }
}

.rs-featured-courses {
  background: #e7f8fb;

  .rs-carousel .owl-dots {
    text-align: center;

    .owl-dot {
      width: 30px;
      height: 10px;
      display: inline-block;
      margin: 0 6px;
      padding: 3px 0;
      border-radius: 30px;
      background: $dark;

      &:hover {
        background: $light-blue;
      }
    }

    .active {
      background: $light-blue;
    }
  }

  .owl-stage-outer {
    padding-bottom: 10px;
  }

  .courses-item {
    padding: 0 30px;
    position: relative;
    transition: all 0.3s ease;

    .img-part {
      position: relative;
      z-index: 111;
    }

    .content-part {
      padding: 30px 0;
      position: relative;
      z-index: 111;

      span .categories {
        display: inline-block;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        color: $white;
        font-weight: 500;
        border-radius: 3px;
        transition: all 0.3s ease;
        position: absolute;
        top: -20px;
        left: 32px;

        &.bg1 {
          background: #c701f1;
        }

        &.bg2 {
          background: #065ce8;
        }

        &.bg3 {
          background: #f6075b;
        }
      }

      .meta-part {
        display: flex;
        align-items: center;
        padding-top: 16px;
        padding-bottom: 12px;

        .user {
          display: inline;
          margin-right: 10px;
          font-weight: 700;
        }

        .ratings {
          padding-left: 25px;

          i {
            color: $ochre;
          }
        }
      }

      .title {
        font-size: 24px;
        line-height: 35px;
        font-weight: 700;

        a {
          color: $ocean-blue;

          &:hover {
            color: $light-blue;
          }
        }
      }

      .images {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e5e8eb;
        padding-bottom: 35px;

        .img-part img {
          border-radius: 50%;
          width: 30px;
          height: 30px;
        }

        span {
          color: $gray-blue;
          font-size: 15px;
          line-height: 25px;
          font-weight: 400;
          padding-left: 15px;
        }
      }

      .bottom-part {
        .info-meta {
          float: left;

          li {
            color: $ocean-blue;
            font-size: 15px;
            line-height: 25px;
            font-weight: 700;
          }
        }

        .btn-part {
          text-align: right;

          a {
            color: $ocean-blue;
            font-size: 15px;
            line-height: 25px;

            i:before {
              font-size: 18px;
              font-weight: 700;
              padding-left: 10px;
            }

            &:hover {
              color: $light-blue;
            }
          }
        }
      }
    }

    &:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 78%;
      background: $white;
      border-radius: 3px;
    }

    &:hover {
      transform: translateY(-10px);
    }
  }
}

.rs-features {
  position: relative;
  margin-top: -245px;
  z-index: 111;

  .features-wrap {
    border-radius: 5px 5px 5px 5px;
    background: $dark-gray;
    padding: 25px 40px 25px;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;

    .icon-part img {
      width: 50px;
      margin: 0 35px 8px 0;
    }

    .content-part {
      .title {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 5px;

        .watermark {
          color: $white;
        }
      }

      .dese {
        font-size: 16px;
        font-weight: 400;
        color: $white;
        margin: 0;
      }
    }

    &:hover {
      transform: translateY(-10px);
    }
  }

  &.style2 {
    margin-top: -110px;

    .rs-iconbox-area {
      position: relative;
      transition: all 0.3s ease;
      padding: 50px;
      background-color: $warm-blue;
      border-radius: 5px 5px 5px 5px;
      text-align: center;

      .icon-area {
        margin-bottom: 32px !important;

        img {
          width: 80px;
          height: 80px;
        }
      }

      .text-area .icon-title {
        font-size: 22px;
        line-height: 700;
        line-height: 1;
        color: $white;
        margin-bottom: 0;
      }

      &:hover {
        transform: translateY(-10px);
      }
    }
  }

  &.main-home {
    width: 100%;
    position: absolute;
    bottom: 100px;
    margin: 0;
  }

  &.style3 {
    margin: unset;

    .features-item {
      position: relative;
      transition: all 0.3s ease;

      .content-part {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
        padding: 50px 35px 0;
        text-align: center;

        .icon-part {
          padding-bottom: 20px;

          img {
            -webkit-transition: all 0.4s ease;
            transform: scale(1);
          }
        }

        .title {
          font-size: 22px;
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 12px;

          a {
            color: $white;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          color: $white;
          margin-bottom: 4px;
        }
      }

      &:hover {
        transform: translateY(-10px);

        .content-part .icon-part img {
          transform: scale(0.9);
        }
      }
    }
  }

  &.style4 {
    position: unset;
    margin-top: unset;
    z-index: unset;

    .features-wrap {
      background: unset;
      display: unset;
      align-items: unset;
      position: unset;

      .icon-part {
        margin: 0 0px 17px 0;
      }

      .content-part {
        .title {
          margin-bottom: 10px;

          .watermark {
            color: $middle-gray;
          }
        }

        .dese {
          color: $mid-gray;
        }
      }
    }
  }
}

.rs-degree.style1 .degree-wrap {
  position: relative;
  overflow: hidden;
  border-radius: 3px;

  img {
    width: 100%;
  }

  .title-part {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 30px 21px;
    opacity: 1;
    transition: all 0.3s ease;

    .title {
      margin: 0;
      color: $white;
    }
  }

  .content-part {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotateY(90deg);
    text-align: center;
    background: rgba(17, 41, 88, 0.9);
    border-radius: 3px;
    padding: 78px 35px 0;
    color: $white;
    opacity: 0;
    transition: all 0.3s ease;

    .title {
      margin-bottom: 13px;

      a {
        color: $white;

        &:hover {
          color: $ccc-gray;
        }
      }
    }

    .desc {
      margin-bottom: 15px;
    }

    .btn-part a {
      text-transform: uppercase;
      font-weight: 500;
      color: $white;
      padding-bottom: 0;
      border-bottom: 1px solid $white;

      &:hover {
        color: $ccc-gray;
      }
    }
  }

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background: linear-gradient(transparent, $middle-blue);
  }

  &:hover {
    .content-part {
      transform: translate(-50%, -50%) rotateY(0);
      opacity: 1;
    }

    .title-part {
      bottom: -60px;
      opacity: 0;
    }
  }
}