/* ------------------------------------
    26. Facilities Section CSS
---------------------------------------*/

.rs-facilities {
  .img-part {
    position: relative;

    .media-icon {
      position: absolute;
      left: 80px;
      top: 90px;
    }
  }

  .choose-part {
    max-width: 700px;
    border-radius: 10px;
    padding: 80px 40px;
    background: #e7f8fb;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .our-facilities {
      display: flex;
      align-items: center;

      .icon-part img {
        max-width: 55px;
      }

      .content-part .text-part {
        padding-left: 30px;

        .title {
          font-size: 24px;
          line-height: 22px;
          font-weight: 800;
          color: $ocean-blue;
          margin: 0;
          padding-bottom: 10px;
        }

        .desc {
          font-size: 16px;
          line-height: 26px;
          font-weight: 400;
          color: $gray-blue;
          margin: 0;
        }
      }
    }
  }
}