/*----------------------------------------
    27. Faq Section CSS
----------------------------------------*/

.rs-faq-part {
  .content-part {
    .title .text-part {
      font-size: 20px;
      color: $dark;
      line-height: 28px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .accordion {
      border-width: 12px;

      .card {
        margin-bottom: 15px;
        border: none;

        &:last-child {
          margin-bottom: 0;
        }

        .card-header {
          padding: 0 !important;
          border: none;

          .card-link {
            font-size: 17px;
            font-weight: 600;
            line-height: 28px;
            color: $white;
            display: block;
            padding: 15px 15px;
            background: $light-blue;

            &.collapsed {
              background: #f9f8ff;
              color: $mid-gray;
            }
          }
        }

        .card-body {
          background: $white;
          font-size: 15px;
          font-weight: 400;
          line-height: 25px;
          color: $mid-gray;
          padding: 15px 14px 15px !important;
          border: 1px solid #f9f8ff;
        }
      }
    }
  }

  &.style1 {
    .main-part {
      background: $light-white;
      padding: 60px 60px 70px;

      .title .text-part {
        font-size: 36px;
        color: $dark;
        line-height: 46px;
        font-weight: 700;
      }

      .faq-content .accordion {
        border-width: 12px;

        .card {
          margin-bottom: 25px;
          border: none;

          &:last-child {
            margin-bottom: 0;
          }

          .card-header {
            display: flex;
            align-items: center;
            padding: 0 !important;
            border: none;

            .card-link {
              position: relative;
              display: block;
              width: 100%;
              padding: 0 60px;
              height: 65px;
              line-height: 65px;
              font-size: 16px;
              font-weight: 600;
              background: $light-blue;
              box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.08);
              color: $white;

              &:after {
                position: absolute;
                font-family: FontAwesome;
                content: "\f1f7";
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                font-weight: 400;
              }

              &.collapsed {
                background: $white;
                color: $dark;

                &:after {
                  content: "\f0a2";
                }
              }
            }
          }

          .card-body {
            background: $white;
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
            color: $mid-gray;
            padding: 15px 14px 15px !important;
            width: 100%;
          }
        }
      }

      &.new-style {
        background: #f3fcf8;
        padding: 49px 50px 70px;

        .faq-content .accordion .card {
          &:last-child {
            margin-bottom: 0;
          }

          .card-header .card-link {
            background: $green;

            &:after {
              position: absolute;
              font-family: FontAwesome;
              content: "\f107";
              left: 25px;
              top: 50%;
              transform: translateY(-50%);
              text-align: center;
              font-weight: 900;
            }

            &.collapsed {
              background: $white;
              color: $dark;

              &:after {
                content: "\f105";
              }
            }
          }

          .card-body {
            padding: 20px 14px 35px !important;
          }
        }
      }
    }

    .img-part {
      background: url(assets/images/faq/1.jpg);
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
      min-height: 615px;
    }
  }

  &.orange-color {
    .content-part .accordion .card .card-header .card-link, .main-part .faq-content .accordion .card .card-header .card-link {
      background: $orange;
    }

    .content-part .accordion .card .card-header .card-link.collapsed, .main-part .faq-content .accordion .card .card-header .card-link.collapse {
      background: #f9f8ff;
    }
  }
}

#faq-title {
  color: $light-blue;
  text-transform: uppercase;
  margin-bottom: 50px;
}