.teacher-container {
    .teacher-wrapper {
        display: flex;
    }
    .teacher-item {
        cursor: pointer;
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.13);
        padding: 35px 20px;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            object-fit: cover;
            object-position: top;
            margin-bottom: 34px;
        }
        .content-part {
            margin-top: 34px;
        }
    }
}