/* ------------------------------------
    08. Services Section CSS
---------------------------------------*/

.rs-services {
  &.style1 .service-item {
    position: relative;
    overflow: hidden;

    .scale-img {
      transition: all 0.3s ease;
      transform: scale(1);
    }

    .content-part {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 1;
      text-align: center;

      i {
        font-size: 40px;
        color: $white;

        &:before {
          font-size: 40px;
        }
      }

      .title {
        font-size: 22px;
        margin: 17px 0 0 0;

        a {
          color: $white;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    &:after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $middle-blue;
      opacity: 0.9;
    }

    &.overly1:after {
      background: $middle-blue;
    }

    &.overly2:after {
      background: $light-blue;
    }

    &.overly3:after {
      background: #772bea;
    }

    &.overly4:after {
      background: #16aaca;
    }

    &:hover .scale-img {
      transform: scale(1.1);
    }
  }

  &.style2 .service-item {
    text-align: center;
    background: #f3fafc;

    .content-part {
      position: unset;
      transform: unset;
      padding: 60px 35px 50px;

      .icon-part i {
        color: $light-blue;

        &:before {
          font-size: 75px;
        }
      }

      .title {
        font-size: 22px;
        margin: 20px 0 0 0;

        a {
          color: $dark;

          &:hover {
            color: $light-blue;
          }
        }
      }

      .desc {
        margin-bottom: 15px;
        margin-top: 5px;
      }

      .service-btn {
        position: relative;
        padding-right: 20px;
        color: $dark;

        i {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.3s ease;
        }

        &:hover {
          color: $light-blue;

          i {
            right: -5px;
          }
        }
      }
    }
  }

  &.style6 .services-wrap {
    text-align: center;
    padding: 50px 30px 50px;
    border-radius: 10px;

    .services-item {
      transition: all .8s ease;
      position: relative;

      .services-desc {
        .title {
          font-size: 22px;
          line-height: 26px;
          margin-top: 20px;
          margin-bottom: 20px;

          a {
            color: $dark;

            &:hover {
              color: $light-blue;
              transition: all 0.3s ease;
            }
          }
        }

        p {
          font-size: 16px;
          line-height: 26px;
          font-weight: 400;
          color: $mid-gray;
          margin: 0;
        }
      }
    }

    &.bg1 {
      background: #fdfcdb;
    }

    &.bg2 {
      background: #fbe7e4;
    }

    &.bg3 {
      background: #e7f6fa;
    }

    &.bg4 {
      background: #f3e9fe;
    }

    &:hover .services-item {
      transform: translateY(-10px);

      .services-desc .title a {
        color: $light-blue;
      }
    }
  }

  &.style7 .services-item {
    position: relative;

    .img-part img {
      width: 100%;
    }

    .content-part {
      position: absolute;
      top: 0;
      left: 0;
      padding: 50px 30px;
      border-radius: 4px;
      transition: all 0.3s ease;

      .title {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;

        a {
          color: $white;

          &:hover {
            color: $light-blue;
            transition: all 0.3s ease;
          }
        }
      }

      .desc {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: $white;
        margin: 0;
      }
    }

    &:hover .content-part {
      top: -10px;
    }
  }

  &.style8 .services-item {
    .services-icon {
      margin-bottom: 20px;

      img {
        width: 55px;
      }
    }

    .services-title .title {
      font-size: 22px;
      line-height: 32px;
      font-weight: 700;
      color: $white;
      margin-bottom: 5px;

      &:hover {
        color: $orange;
      }
    }

    .services-txt {
      color: $white;
      margin: 0;
    }
  }

  &.home12-style {
    background: url(assets/images/bg/home12/services-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0;

    .services-item {
      padding: 45px 15px 40px 15px;
      text-align: center;
      background: $white;
      box-shadow: 0 0 30px $box-shadow-gray;

      .services-image {
        .services-icons {
          padding-bottom: 20px;

          img {
            width: 100px;
          }
        }

        .services-text {
          .services-title .title {
            font-size: 24px;
            line-height: 36px;
            font-weight: 700;
            color: $dark-gray;
            padding-bottom: 15px;
            margin: 0;
          }

          .text {
            font-size: 16px;
            line-height: 27px;
            font-weight: 400;
            color: $darker-gray;
            margin: 0;
            padding-bottom: 10px;
          }
        }
      }

      &:hover .services-image .services-text .services-title .title {
        color: $green;
      }
    }
  }
}