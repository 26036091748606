/* -----------------------
    38. Scroll Up CSS
--------------------------*/

#scrollUp {
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  display: none;
  position: fixed;
  right: 20px;
  z-index: 999;
  border-radius: 50px 50px 4px 4px;

  i {
    background: $ips-dark-green;
    border-radius: 50%;
    height: 40px;
    font-size: 24px;
    font-weight: 600;
    width: 42px;
    color: $white;
    line-height: 36px;
    transition: all 0.3s ease;
    margin-left: 2px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);

    &:hover {
      background-color: $ips-middle-green;
    }
  }

  &.orange-color i {
    background: $orange;

    &:hover {
      background-color: $red;
    }
  }

  &.yellow-color i {
    background: $yellow;

    &:hover {
      background-color: #c19700;
    }
  }

  &.purple-color i {
    background: $deep-purple;

    &:hover {
      background-color: #3d397e;
    }
  }

  &.green-color i {
    background: $green;

    &:hover {
      background-color: #085c36;
    }
  }
}

/*Pulse Border Animation*/
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

/*Pulse Border Animation*/
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes circle-ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 84, 33, 0.3), 0 0 0 1em rgba(255, 84, 33, 0.3), 0 0 0 3em rgba(255, 84, 33, 0.3), 0 0 0 5em rgba(255, 84, 33, 0.3);
  }

  100% {
    box-shadow: 0 0 0 1em rgba(255, 84, 33, 0.3), 0 0 0 3em rgba(255, 84, 33, 0.3), 0 0 0 5em rgba(255, 84, 33, 0.3), 0 0 0 8em rgba(255, 84, 33, 0);
  }
}

@-webkit-keyframes circle-ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 84, 33, 0.3), 0 0 0 1em rgba(255, 84, 33, 0.3), 0 0 0 3em rgba(255, 84, 33, 0.3), 0 0 0 5em rgba(255, 84, 33, 0.3);
  }

  100% {
    box-shadow: 0 0 0 1em rgba(255, 84, 33, 0.3), 0 0 0 3em rgba(255, 84, 33, 0.3), 0 0 0 5em rgba(255, 84, 33, 0.3), 0 0 0 8em rgba(255, 84, 33, 0);
  }
}

.up-down {
  animation: up-down 4s alternate infinite;
  -webkit-animation: up-down 4s alternate infinite;
}

.up-down-new {
  animation: up-down 8s alternate infinite;
  -webkit-animation: up-down 8s alternate infinite;
}

@keyframes up-down {
  0% {
    transform: translateY(30px);
    -webkit-transform: translateY(0);
  }

  50% {
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(30px);
    -webkit-transform: translateY(0);
  }

  50% {
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}

.left-right {
  animation: left-right 5s cubic-bezier(0.41, 0.04, 0.03, 1.1) infinite;
  -webkit-animation: left-right 5s cubic-bezier(0.41, 0.04, 0.03, 1.1) infinite;
}

.left-right-new {
  animation: left-right 8s cubic-bezier(0.41, 0.04, 0.03, 1.1) infinite;
  -webkit-animation: left-right 8s cubic-bezier(0.41, 0.04, 0.03, 1.1) infinite;
}

@keyframes left-right {
  0% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }

  50% {
    transform: translateY(-50px);
    -webkit-transform: translatex(-50px);
  }

  100% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }
}

@-webkit-keyframes left-right {
  0% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }

  50% {
    transform: translatex(-50px);
    -webkit-transform: translatex(-50px);
  }

  100% {
    transform: translatex(0);
    -webkit-transform: translatex(0);
  }
}

.spine {
  animation: spine 5s linear infinite;
  -webkit-animation: spine 5s linear infinite;
}

@keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

.spine-r {
  animation: spine-r 5s linear infinite;
  -webkit-animation: spine-r 5s linear infinite;
}

@keyframes spine-r {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spine-r {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

.spine2 {
  animation: spine 8s linear infinite;
}

@keyframes spine2 {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spine2 {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

.rotated-style {
  -webkit-animation: rotated-style 30s linear infinite;
}

@keyframes rotated-style {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

.veritcal-two {
  animation-name: veritcal-two;
  animation-timing-function: linear;
  animation-duration: 12s;
  animation-iteration-count: infinite;
}

@keyframes veritcal-two {
  0% {
    transform: translateY(30px);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(30px);
  }
}