/* ------------------------------------
    06. Breadcrumbs Section CSS
---------------------------------------*/

.rs-breadcrumbs {
  position: relative;
  padding: 200px;
  background-color: $bc-gray;

  .breadcrumbs-img img {
    width: 100%;
  }

  .breadcrumbs-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;

    .page-title {
      font-size: 48px;
      margin-bottom: 20px;
      color: $light-blue;
    }

    ul {
      padding: 0;

      li {
        color: $gray-76;
        display: inline-block;
        font-size: 16px;
        font-weight: 400;

        a {
          position: relative;
          padding-right: 30px;
          -webkit-transition: 0.3s;
          transition: 0.3s;
          color: $gray-76;

          &:before, &:after {
            background-color: $gray-76;
            content: "";
            height: 15px;
            width: 2px;
            position: absolute;
            right: 7px;
            top: 2px;
            transform: rotate(26deg);
          }

          &:before {
            right: 13px;
          }

          &:hover {
            color: $light-blue;
          }
        }
      }
    }

    &.white-color {
      .page-title {
        color: $white;
      }

      ul li {
        color: $white;

        a {
          color: $white;

          &:before, &:after {
            background-color: $white;
          }

          &:before {
            right: 13px;
          }

          &:hover {
            color: $light-blue;
          }
        }
      }
    }

    &.padding {
      padding-top: 100px;
    }
  }
}

.breadcrumbs-overlay:after {
  content: '';
  position: absolute;
  background-color: rgba(17, 17, 17, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.breadcrumbs1 {
  background-image: url(assets/images/breadcrumbs/1.jpg);
  background-size: cover;
  background-position: center;
  background-position: center top;
}

.breadcrumbs-section {
  background-color: $light-gray4;

  .breadcrumb {
  background: transparent;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 0;

    li {
      a {
        color: $middle-blue;
      }

      a:hover {
        color: $ips-middle-blue;
      }
    }

    .active {
      color: $gray-76;
    }
  }
}


