/* ------------------------------------
    09. Subject Section CSS
---------------------------------------*/

.rs-subject.style1 .subject-wrap {
  text-align: center;
  background: #d3fcf8;
  border-radius: 10px;
  padding: 50px 30px 45px;

  img {
    transition: all 0.3s ease;
    transform: translateY(0);
  }

  .title {
    margin: 20px 0 5px 0;

    a {
      color: $dark;

      &:hover {
        opacity: .8;
      }
    }
  }

  &:hover img {
    transition: all 0.3s ease;
    transform: translateY(-7px);
  }

  &.bgc1 {
    background: #d3fcf8;
  }

  &.bgc2 {
    background: #16aaca;
  }

  &.bgc3 {
    background: #f9e8e8;
  }

  &.bgc4 {
    background: #f8f6d1;
  }

  &.bgc5 {
    background: #eaddf8;
  }

  &.bgc6 {
    background: #e9fbd5;
  }

  &.text-light {
    color: $white;

    .title a {
      color: $white;
    }
  }
}