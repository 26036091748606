/* ------------------------------------
    02. Global Class CSS
---------------------------------------*/

.y-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.y-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.readon {
  outline: none;
  padding: 12px 40px;
  border: 1px solid $light-blue;
  border-radius: 30px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  color: $dark;
  background: $light-gray;

  &.banner-style {
    color: $white;
    background: $light-blue;
    padding: 16px 58px;

    &:hover {
      background: $sea-blue;
    }
  }

  &.purple-btn {
    color: $white;
    background: $light-purple;
    border: none;
    padding: 16px 40px;

    &:hover {
      background: $deep-purple;
    }
  }

  &.green-btn {
    color: $white;
    background: $green;
    border-color: #02010100;
    border-radius: 30px;
    font-size: 14px;
    padding: 10px 30px 10px 30px;

    &:hover {
      opacity: 0.9;
      background: $green;
    }
  }

  &.green-banner {
    font-size: 16px;
    line-height: 38px;
    font-weight: 700;
    padding: 8px 30px;
    border-radius: 4px;
    background-color: $green;
    border-color: transparent;
    color: $white;

    &:hover {
      background: #08a355;
    }
  }

  &.white-color {
    color: $white !important;
    background: none !important;
    border: 1px solid $white;
    padding: 16px 40px;

    &:hover {
      background: $white !important;
      color: $deep-purple !important;
    }
  }

  &.orange-btn {
    color: $middle-blue;
    background: transparent;
    border-color: $middle-blue;
    border-radius: 5px 5px 5px 5px;
    outline: none;
    padding: 12px 35px;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;

    &.main-home {
      padding: 10px 20px 10px 20px !important;

      &:hover {
        color: $white;
        background: $dark-gray;
        border-color: $dark-gray;
      }
    }

    &:hover {
      color: $white;
      background: $middle-blue;
      border-color: $middle-blue;
    }

    &.transparent:hover {
      color: $dark-black;
      background: $white;
      border-color: $white;
    }
  }

  &.yellow-btn {
    background: $yellow;
    border-color: $yellow;
    border-radius: 4px 4px 4px 4px;

    &:hover {
      color: $white;
      background: $dark-gray;
      border-color: $dark-gray;
    }

    &.transparent3:hover {
      color: $warm-blue;
      background: $white;
      border-color: $white;
    }
  }

  &.transparent2 {
    background: $light-blue;
    color: $dark;
    border: 1px solid $light-blue;

    &:hover {
      color: $dark;
      background: transparent;
      border: 1px solid $light-blue;
    }
  }

  &.register-btn {
    padding: 10px 50px;
    color: $white;
    background: $orange;
    border-color: $orange;
    font-weight: 500;
    width: 100%;
    border-radius: 5px;

    &:hover {
      background: #ff4007;
    }
  }

  &.submit-btn {
    border: 2px solid;
    border-color: $orange;
    padding: 10px 50px;
    line-height: normal;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    background: $orange;
    color: $white;
    border-radius: 5px;
    margin-bottom: 12px;
    width: 100%;

    &:hover {
      background: transparent;
      color: $orange;
    }
  }

  &.submit-requset {
    background-color: #0FCB75;
    margin: 0px 0px 0px 0px;
    padding: 13px 35px 13px 35px;
    outline: none;
    border: none;
    padding: 12px 40px;
    border-radius: 3px;
    display: inline-block;
    text-transform: capitalize;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    color: $white;

    &:hover {
      opacity: 0.9;
      background-color: #0FCB75;
    }
  }

  &.border-less {
    border: none;

    &:hover {
      background: rgba(243, 248, 239, 0.8);
      color: $dark;
    }
  }

  &:hover {
    background: $light-blue;
    color: $white;
  }
}

.readon2 {
  outline: none;
  border: none;
  padding: 12px 40px;
  border-radius: 3px;
  display: inline-block;
  text-transform: capitalize;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  color: $white;
  background: $light-blue;
  position: relative;
  overflow: hidden;

  &.banner-style {
    padding: 16px 40px;
  }

  &.mod {
    text-transform: uppercase;
    padding: 14px 31px;
  }

  &.transparent {
    background: transparent;
    color: $light-blue;
    border: 1px solid $light-blue;

    &:hover {
      color: $white;
      background: $light-blue;
    }
  }

  &.orange-transparent {
    background: transparent;
    color: $orange;
    border: 1px solid $orange;

    &:hover {
      color: $white;
      background: $orange;
    }
  }

  &.orange {
    background: $orange;

    &:hover {
      background: #ff4007;
    }
  }

  &:hover {
    background: $ips-middle-blue;
    color: $white;
  }

  &.gym-btn {
    background: $orange;
    color: $white;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    padding: 17px 28px;

    &:hover {
      opacity: 0.80;
    }

    &.get-now:hover {
      background: $white;
      color: $orange;
    }
  }

  &.cta-btn {
    background: $orange;
    color: $white;
    border-radius: 3px 3px 3px 3px;
    font-family: "Rubik", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 12px 30px 12px 30px;

    &:hover {
      color: #F0E3E3;
      background: $orange;
      color: #F0E3E3;
      background: $orange;
    }

    background: $orange;
    color: $white;
    border-radius: 3px 3px 3px 3px;
    font-family: "Rubik", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 12px 30px 12px 30px;
  }

  &.apply {
    padding: 10px 30px 10px 30px;

    &:hover {
      color: unset;
    }
  }
}

.readon3 {
  outline: none;
  padding: 12px 50px;
  border-radius: 30px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  color: $white;
  background: $light-blue;
  position: relative;

  &:after, &:before {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s ease;
  }

  &:after {
    right: 30px;
  }

  &:before {
    left: 30px;
  }

  &.active {
    background: $light-gray;
    color: $light-blue;

    &:after, &:before {
      background: $light-blue;
    }

    &:hover {
      color: $white;
      background: $light-blue;

      &:after, &:before {
        background: $white;
      }
    }
  }

  &.dark-hov:hover {
    background: $dark;
    color: $white;

    &:after, &:before {
      background: $white;
    }
  }

  &:hover {
    background: $light-gray;
    color: $light-blue;

    &:after, &:before {
      background: $light-blue;
    }
  }
}

.readon-arrow {
  color: $dark;
  padding-right: 22px;
  position: relative;
  font-weight: 500;

  &:after {
    position: absolute;
    content: "\f136";
    font-family: Flaticon;
    font-style: normal;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    color: $dark;
    transition: all 0.3s ease;
    font-weight: 600;
    font-size: 14px;
  }

  &:hover {
    color: $light-blue;

    &:after {
      right: 0;
      color: $light-blue;
    }
  }
}

.btn-shop {
  outline: none;
  font-weight: 400;
  border: none;
  text-transform: uppercase;
  background: $light-blue;
  font-size: 15px;
  padding: 8px 25px;
  color: $white;
  border-radius: 5px;
  transition: all 0.3s ease 0s;

  &:hover {
    background: $middle-blue;
    color: $white;
  }

  &.orange-color {
    background: $orange;
    color: $white;

    &:hover {
      background: $red;
    }
  }
}

/*Pagination*/

.pagination-area {
  .pagination-part {
    display: inline-block;
    padding: 0 5px 0 10px;
    height: 60px;
    line-height: 60px;
    box-shadow: 0px 8px 26px 0px rgba(0, 0, 0, 0.1);

    li {
      position: relative;
      display: inline-block;
      padding: 0 20px 0 15px;
      text-align: center;
      cursor: pointer;

      a {
        display: inline-flex;
        align-items: center;
        color: $dark;

        &:hover {
          color: $light-blue;
        }
      }

      i {
        margin-left: 10px;

        &:before {
          font-size: 28px;
          margin: 0;
          line-height: 60px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #d6fbff;
      }

      &:last-child:before {
        display: none;
      }

      &:hover a, &.active a {
        color: $light-blue;
      }
    }
  }

  &.orange-color .pagination-part li {
    a:hover {
      color: $orange;
    }

    &:before {
      background: $orange;
    }

    &:hover a, &.active a {
      color: $orange;
    }
  }
}

/*Video Icon*/

.media-icon {
  position: relative;
  display: inline-block;
  z-index: 1;

  .popup-videos {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    background: $light-blue;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      animation: pulse-border 1500ms ease-out infinite;
      transition: all 0.3s ease;
      z-index: -1;
    }

    i {
      margin-left: 5px;

      &:before {
        font-size: 30px;
        line-height: 70px;
      }
    }

    &:hover:before {
      background: $light-blue;
    }
  }

  &.orange-color .popup-videos {
    color: $orange;
    background: $white;

    i:before {
      font-size: 30px;
      line-height: 70px;
    }

    &:hover:before {
      background: $white;
    }
  }

  &.orange-color2 .popup-videos {
    color: $white;
    background: $orange;
    animation: circle-ripple 3s linear linear infinite;
    -webkit-animation: circle-ripple 3s linear infinite;

    &:before {
      display: none;
    }

    &:hover:before {
      background: $orange;
    }
  }

  &.yellow-color .popup-videos {
    color: $middle-blue;
    background: $yellow;

    &:hover:before {
      background: $warm-blue;
    }
  }
}

.rs-video-home9 {
  background: url(assets/images/video/video2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 300px 0 300px;

  .media-icon {
    display: block;
  }
}

.rs-video .video-item {
  background: url(assets/images/bg/video-bg-15.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 299px 0 299px 0;
  transition: .3s;
  position: relative;

  .icon-area {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    a {
      width: 70px;
      height: 70px;
      border: 5px solid;
      display: inline-block;
      color: $orange;
      line-height: 70px;
      border-radius: 50px;

      i {
        position: relative;
        left: 24px;
        top: -23px;

        &:before {
          color: $orange;
        }
      }
    }
  }

  &:hover {
    opacity: .8;
  }
}

.rs-call-action {
  position: relative;

  .spiners {
    position: absolute;
    bottom: 0;

    &.one {
      top: 12%;
    }

    &.two {
      bottom: 18%;
      right: 56%;
    }

    &.three {
      top: 16%;
      right: 39%;
    }
  }
}

.margin-remove {
  margin: 0 !important;
}

.no-gutter {
  margin-left: 0;
  margin-right: 0;

  [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  margin: 0;
}

.container-fluid {
  padding-left: 60px;
  padding-right: 60px;
}

.container-fluid2 {
  padding-left: 0px;
  padding-right: 60px;
}

.display-table {
  display: table;
  height: 100%;
  width: 100%;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.relative {
  position: relative;
}

.body-color {
  color: $mid-gray !important;
}

.white-color {
  color: $white !important;
}

.title-color {
  color: $dark !important;
}

.title-color2 {
  color: $dark-blue !important;
}

.secondary-color {
  color: $middle-blue;
}

.primary-color {
  color: $light-blue !important;
}

.orangeColor {
  color: $orange !important;
}

.dark-parimary-bg {
  background: #203154 !important;
}

ul.listing-style {
  li {
    position: relative;
    padding-left: 30px;
    line-height: 34px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      content: "\f05d";
      font-family: 'FontAwesome';
      color: $light-blue;
      font-size: 20px;
    }
  }

  &.modify li {
    padding-left: 23px;

    &:before {
      font-size: 15px;
    }
  }
}

.rs-carousel {
  &.nav-style1 {
    position: relative;

    .owl-nav {
      display: block;

      .owl-next, .owl-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 50%;
        background: $light-blue;
        text-align: center;
        color: $white;
        transition: all 0.5s;
        transition-delay: 0.70s;
        opacity: 0;
        visibility: hidden;
      }

      .owl-next i:before, .owl-prev i:before {
        content: "\f138";
        font-family: Flaticon;
      }

      .owl-next {
        right: 30px;
        left: unset;

        i:before {
          content: "\f137";
        }
      }
    }

    &:hover .owl-nav {
      .owl-next, .owl-prev {
        left: -50px;
        transition-delay: 0s;
        visibility: visible;
        opacity: 1;
      }

      .owl-next {
        right: -50px;
        left: unset;
      }
    }
  }

  &.nav-style2 {
    position: relative;

    .owl-nav {
      display: block;
      position: absolute;
      top: -80px;
      right: 0;

      .owl-prev, .owl-next {
        display: inline-block;
      }

      .owl-prev i, .owl-next i {
        transition: all 0.3s ease;
      }

      .owl-prev i:before, .owl-next i:before {
        font-family: Flaticon;
        font-size: 22px;
      }

      .owl-prev:hover i, .owl-next:hover i {
        color: $light-blue;
      }

      .owl-prev {
        i:before {
          content: "\f134";
        }

        &:after {
          content: "/";
          padding: 0 5px 0 5px;
          position: relative;
          top: -3px;
        }
      }

      .owl-next i:before {
        content: "\f133";
      }
    }
  }

  &.orange-color .owl-nav {
    .owl-prev:hover i, .owl-next:hover i {
      color: $orange;
    }
  }

  .owl-dots {
    text-align: center;
    margin: 40px auto 0;
    line-height: 15px;
    display: block;

    .owl-dot {
      width: 30px;
      height: 10px;
      display: inline-block;
      margin: 0 6px;
      padding: 3px 0;
      border-radius: 30px;
      border: 1px solid $light-blue;
      background: transparent;
      opacity: 0.7;
      cursor: pointer;

      &:hover {
        background: $light-blue;
      }
    }

    .active {
      background: $light-blue;
      opacity: 1;
    }
  }
}

.owl-carousel .owl-item img {
  width: auto;
}

.sec-title {
  .sub-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px;

    &.primary {
      color: $light-blue;
    }

    &.secondary {
      color: $middle-blue;
    }

    &.white {
      color: $white;
    }

    &.dark {
      color: $dark;
    }

    &.orange {
      color: $orange;
    }
  }

  .desc.big {
    font-size: 18px;
    line-height: 30px;
  }

  .midline {
    font-size: 36px;
    color: $dark;
  }
}

.sec-title2 {
  .sub-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: $light-blue;
    text-transform: uppercase;
    margin-bottom: 10px;

    &.white-color {
      color: $white;
    }
  }

  .title {
    font-size: 42px;
    line-height: 52px;
    font-weight: 800;
    color: $ocean-blue;

    &.white-color {
      color: $white;
    }

    &.purple-color {
      color: $deep-purple !important;
    }
  }
}

.sec-title3 {
  .sub-title {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: $orange;
    text-transform: uppercase;
    margin-bottom: 10px;

    &.yellow-color {
      color: $yellow;
    }

    &.green-color {
      color: $green;
    }

    &.no-big {
      text-transform: capitalize;
      color: $white;
      letter-spacing: 3px;
    }

    &.big {
      font-size: 18px !important;
      color: $yellow !important;
      font-weight: 500;
    }
  }

  .title {
    font-size: 36px;
    line-height: 46px;
    font-weight: 800;
    color: $ocean-blue;

    &.white-color {
      color: $white;
    }

    &.black-color {
      color: $dark-black;
    }

    &.new-title {
      color: $dark-black;
      line-height: 1.2;
      font-weight: 700;
    }

    &.title2 {
      font-size: 42px;
      line-height: 55px;
      font-weight: 700;
      color: $white;
    }

    &.title3 {
      font-size: 42px;
      line-height: 55px;
      font-weight: 800;
      color: $white;
    }
  }

  .desc {
    font-size: 16px;
    line-height: 26px;
    color: $darker-gray;

    &.white-color {
      color: $white;
    }
  }

  .new-desc {
    font-size: 18px;
    line-height: 31px;
    color: $middle-gray;
  }
}

.sec-title4 {
  .sub-title {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: $green;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .title {
    font-size: 36px;
    line-height: 46px;
    font-weight: 700;
    color: $dark-black;
    position: relative;
    padding: 0px 0px 20px 0px;

    &:after {
      content: "";
      position: absolute;
      border: 0;
      width: 50px;
      height: 4px;
      z-index: 9;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
      background: $green;
    }
  }

  .desc {
    font-size: 16px;
    line-height: 26px;
    color: $darker-gray;
  }
}

.sec-title5 .description {
  color: $white;

  &.title-color {
    color: #7A7A7A;
    font-weight: 400;
  }

  span a {
    color: $orange;

    i {
      margin-left: 5px;

      &:before {
        top: 2.5px;
        position: relative;
      }
    }
  }
}

.sec-title6 {
  .sub-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: $orange;
    display: block;
    margin: 0;
  }

  .title {
    font-size: 36px;
    line-height: 46px;
    font-weight: 700;
    color: $ocean-blue;

    &.title2 {
      font-weight: 800;
      margin: 0;
    }
  }
}

.left-top-shape {
  position: absolute;
  left: -55px;
  top: 65px;
}

.right-top-shape {
  position: absolute;
  right: -115px;
  top: 25px;
}