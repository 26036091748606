/*-----------------------------------------------
    28. Woocommerce Section CSS
-----------------------------------------------*/

.woocommerce-mini-cart2 {
  line-height: 20px;
  background-color: $white;
  position: absolute;
  right: 11.3%;
  font-size: 14px;
  top: 100%;
  width: 278px;
  z-index: 10000;
  transition: all .2s ease 0s;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0 0;

  .cart-bottom-part {
    padding: 25px 10px 0;

    .widget-title {
      text-align: center;
      text-transform: uppercase;
      position: relative;
      padding-top: 50px;
      color: #0a0a0a;
      font-family: Nunito;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;

      &:before {
        position: absolute;
        content: "\f131";
        color: $dark-black;
        left: 50%;
        transform: translateX(-50%);
        bottom: 42px;
        font-family: Flaticon;
        font-size: 30px;
        font-style: normal;
      }
    }
  }
}

.cart-active2 {
  cursor: pointer;
}

.icon-bar.cart-active:hover .woocommerce-mini-cart2, .cart-active2:hover .woocommerce-mini-cart2, .icon-bar.cart-active:hover .woocommerce-mini-cart, .cart-active2:hover .woocommerce-mini-cart {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}