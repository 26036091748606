/* ------------------------------------
    19. Latest Events Section CSS
---------------------------------------*/

.rs-event {
  .event-item {
    transition: all 0.3s ease;
    position: relative;

    .event-short {
      text-align: left;
      box-shadow: 0 0 20px $box-shadow-gray;

      .featured-img {
        position: relative;

        img {
          border-radius: 5px 5px 0 0;
        }
      }

      .categorie {
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 1;

        a {
          background: $orange;
          padding: 5px 20px;
          display: inline-block;
          color: $white;
          border-radius: 4px;

          &:hover {
            opacity: .9;
          }
        }
      }

      .content-part {
        padding: 25px 30px 15px 30px;
        border-radius: 0 0 5px 5px;

        .address {
          padding-bottom: 15px;
          color: $darker-gray;
          font-size: 14px;

          i {
            font-size: 13px;
            margin-right: 4px;
          }
        }

        .title {
          font-size: 26px;
          line-height: 32px;
          transition: all .8s ease;

          a {
            color: $black;

            &:hover {
              color: $orange;
            }
          }
        }

        .text {
          color: $darker-gray;
          margin: 0;
          font-size: 14px;
        }

        .event-btm {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #efefef;
          margin-top: 34px;
          padding-top: 26px;

          .date-part {
            font-size: 13px;

            .date i {
              font-size: 13px;
              margin-right: 7px;
            }
          }

          .btn-part a {
            background: $orange;
            text-transform: capitalize;
            padding: 7px 15px;
            color: $white;
            border-radius: 2px;
            font-size: 14px;
            display: inline-block;

            &:hover {
              background: $red;
            }
          }
        }
      }
    }

    &:hover {
      transform: translateY(-10px);
    }

    &.home12-style {
      .event-short {
        text-align: left;
        background: $white;

        .featured-img {
          position: relative;

          img {
            border-radius: 5px 5px 0 0;
          }
        }

        .content-part {
          padding: 25px 30px 30px 30px;
          border-radius: 0 0 5px 5px;

          .all-dates-time {
            display: flex;

            .address {
              color: $darker-gray;
              padding-right: 20px;

              i:before {
                color: $green;
              }
            }

            .time {
              color: $darker-gray;
              font-size: 14px;

              i:before {
                color: $green;
                padding-right: 5px;
              }
            }
          }

          .title {
            font-size: 22px;
            line-height: 32px;
            transition: all .8s ease;

            a {
              color: $black;

              &:hover {
                color: $green;
              }
            }
          }

          .event-btm {
            border-top: 1px solid #efefef;

            .date-part {
              font-size: 13px;
              color: $darker-gray;

              .date i {
                font-size: 13px;
                margin-right: 7px;
                color: $green;
              }
            }

            .btn-part a {
              background: $green;

              &:hover {
                background: #085c36;
              }
            }
          }
        }
      }

      .rs-carousel .owl-dots {
        text-align: center;

        .owl-dot {
          width: 40px;
          height: 10px;
          display: inline-block;
          margin: 0 6px;
          padding: 3px 0;
          border-radius: 30px;
          background: red;
          border: none;

          &:hover {
            width: 40px;
            opacity: 1;
          }
        }

        .active {
          background: red;
        }
      }
    }
  }

  &.home12style {
    background: url(assets/images/bg/home12/event-bg.jpg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding: 150px 0;

    .owl-dots {
      text-align: center;
      margin-top: 47px;

      .owl-dot {
        width: 25px;
        height: 8px;
        display: inline-block;
        margin: 0 6px;
        padding: 3px 0;
        border-radius: 30px;
        background: #085c36;
        border: none;
        transition: all 0.3s ease;

        &:hover, &.active {
          width: 40px;
          background: $green;
          opacity: 0.5;
        }
      }
    }
  }

  &.modify1 .event-item .event-short {
    .featured-img .dates {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background: $light-blue;
      padding: 7px 20px;
      display: inline-block;
      color: $white;
      border-radius: 15px 15px 0 0;
      right: 0;
      margin: 0 auto;
      max-width: 220px;
      text-align: center;
    }

    .content-part {
      padding: 30px 30px 35px 30px;
      text-align: center;

      .title {
        font-size: 24px;
      }

      .time-sec {
        display: inline-flex;

        .timesec {
          margin-right: 15px;
          font-size: 14px;

          i:before {
            font-size: 16px;
          }
        }

        .address {
          color: $darker-gray;
          font-size: 14px;

          i {
            font-size: 13px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  &.modify2 .event-item .event-short {
    transition: all 500ms ease;
    box-shadow: 0 0 20px $box-shadow-gray;
    position: relative;

    .featured-img {
      position: relative;
      padding: 0px 0px 0px 0px;

      &:before {
        opacity: .85;
        background: 0 0;
        background: linear-gradient(transparent, $middle-gray), linear-gradient(transparent, $middle-gray);
        background: -moz-linear-gradient(transparent, $middle-gray), -moz-linear-gradient(transparent, $middle-gray);
        background: -o-linear-gradient(transparent, $middle-gray), -o-linear-gradient(transparent, $middle-gray);
        content: "";
        height: 100%;
        bottom: 0;
        left: 0px;
        right: 0px;
        position: absolute;
        z-index: 1;
        transition: all .7s ease 0s;
      }
    }

    .content-part {
      padding: 40px 40px 40px 40px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      border-radius: 0 0 5px 5px;

      .title a {
        color: $white;
      }

      .address {
        color: $white;
        font-size: 14px;

        i {
          font-size: 13px;
          margin-right: 4px;
        }
      }

      .date-part {
        padding: 0px 0px 10px 0px;

        .date {
          color: $white;
          font-size: 13px;

          i {
            font-size: 13px;
            margin-right: 7px;
          }
        }
      }

      .time {
        color: $white;
        font-size: 14px;

        i:before {
          margin: 0;
          font-size: 14px;
          color: $white;
          margin-right: 4px;
        }
      }
    }
  }

  &.home8-style1 {
    .rs-carousel .owl-dots {
      text-align: center;

      .owl-dot {
        width: 40px;
        height: 10px;
        display: inline-block;
        margin: 0 6px;
        padding: 3px 0;
        border-radius: 30px;
        background: $orange;
        border: none;

        &:hover {
          width: 40px;
          opacity: 1;
        }
      }

      .active {
        background: $orange;
      }
    }

    .owl-stage-outer {
      padding-bottom: 10px;
    }

    .event-item .event-short {
      .featured-img .dates {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: $orange;
        padding: 7px 20px;
        display: inline-block;
        color: $white;
        border-radius: 15px 15px 0 0;
        right: 0;
        margin: 0 auto;
        max-width: 220px;
        text-align: center;
      }

      .content-part {
        padding: 30px 30px 35px 30px;
        text-align: center;

        .title {
          font-size: 24px;
        }

        .time-sec {
          display: inline-flex;

          .timesec {
            padding-right: 4px;

            i:before {
              color: $orange;
              font-size: 14px;
            }
          }

          .address {
            font-size: 14px;

            i {
              font-size: 13px;
              margin-right: 5px;

              &:before {
                color: $orange;
              }
            }
          }
        }
      }
    }
  }

  &.orange-color.modify1 .event-item .event-short .featured-img .dates {
    background: $orange;
  }
}

.rs-latest-events {
  &.style1 {
    .single-img {
      z-index: 1;
      position: relative;

      img {
        border-radius: 3px;
      }
    }

    .event-wrap {
      z-index: 1;
      position: relative;

      .events-short {
        overflow: hidden;
        background: $white;
        padding: 31px 30px;
        border-radius: 3px;

        .date-part {
          width: 100px;
          float: left;
          text-align: center;
          padding: 11px 0 22px;
          border-radius: 3px;
          margin-right: 25px;

          .month {
            margin-bottom: 10px;
            display: block;
          }

          .date {
            font-size: 50px;
            font-family: 'Nunito', sans-serif;
            color: $dark-blue;
            font-weight: 700;
          }

          &.bgc1 {
            background: #dcf1fd;
          }

          &.bgc2 {
            background: #e9fbd5;
          }

          &.bgc3 {
            background: #fee2c0;
          }
        }

        .content-part {
          .categorie a {
            color: $mid-gray;

            &:hover {
              color: $light-blue;
            }
          }

          .title {
            line-height: 1.5;

            a {
              color: $dark-blue;

              &:hover {
                color: $light-blue;
              }
            }
          }
        }
      }

      .btn-part a {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        color: $dark-blue;
        position: relative;
        display: inline-block;

        &:after {
          position: absolute;
          content: '';
          right: 0;
          bottom: 3px;
          width: 0;
          height: 1px;
          background: $dark-blue;
          transition: all 0.3s ease;
        }

        &:hover:after {
          left: 0;
          right: auto;
          width: 100%;
        }
      }
    }
  }

  &.bg-wrap {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 63%;
      height: 100%;
      background: $light-gray;
    }
  }
}