/* This file should contain styles that modifies the popup layout. */
/* By layout, we mean the physical position of the elements on the popup window, and the margin / padding around those elements. */

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
.cc-revoke.cc-left {
  left: 3em;
  right: unset;
}
.cc-revoke.cc-right {
  right: 3em;
  left: unset;
}

/**************************************** FLOATING ****************************************/

/* these classes position the floating element */
.cc-top {
  top: 1em;
}
.cc-left {
  left: 1em;
}
.cc-right {
  right: 1em;
}
.cc-bottom {
  bottom: 1em;
}

/* links that are direct decendants should be displayed as block */
.cc-floating > .cc-link {
  margin-bottom: 1em;
}

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em;
}

.cc-window.cc-floating .cc-compliance {
  flex: 1 0 auto;
}

/**************************************** BANNER ****************************************/

.cc-window.cc-banner {
  align-items: center;
}

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0;
}
.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0;
}

.cc-banner .cc-message {
  display: block;
  flex: 1 1 auto;
  max-width: 100%;
  margin-right: 1em;
}

/* COMPLIANCE BOX */

.cc-compliance {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cc-floating .cc-compliance > .cc-btn {
  flex: 1;
}

.cc-btn + .cc-btn {
  margin-left: 0.5em;
}

/* Categories Layout */
.cc-window.cc-type-categories {
  display       : inline-flex;
  flex-direction: column;
  overflow      : visible;
  .form{
    text-align: right;
  }
  .cc-btn {
    margin: 0;
    &.cc-save {
      margin        : 0;
      display       : inline-block;
    }
  }
}
.cc-categories {
  display: inline-flex;
  .cc-category{
    display  : flex;
    max-width: 100%;
    margin   : 0 2px;
    position : relative;
  }
  .cc-btn {
    border-right: none;
    outline     : none;
    text-transform: capitalize;
    input[type=checkbox]{
      float: left;
      height: 26px;
      width: calc( 100% - 22px );
      display: block;
      position: absolute;
      top: 0;
      left: 2px;
      cursor: pointer;
    }
    &:not(.cc-info):not(.cc-save){
      padding-left: 26px;
    }
  }
  .cc-info {
    border-left : none;
    border-right: 2px solid lightgrey;
    padding     : 4px;
    font-variant: all-small-caps;
    &:focus + .cc-tooltip{
      display: block;
    }
  }
  .cc-tooltip{
    display      : none;
    position     : absolute;
    z-index      : 3;
    width        : 190px;
    bottom       : 46px;
    padding      : 8px;
    border       : thin solid lightgrey;
    box-shadow: 1px 1px 4px rgba( 150, 150, 150, .7 );
    &:after{
      content   : "";
      width     : 10px;
      height    : 10px;
      transform : rotate(45deg);
      position  : absolute;
      bottom    : -7px;
      left      : 10px;
      box-shadow: 2px 1px 1px rgba( 200, 200, 200, .5 );
    }
    p {
      margin: 0;
    }
  }
}