/* ------------------------------------
    25. Publication Section CSS
---------------------------------------*/

.rs-publication .product-list {
  .image-product {
    position: relative;
    transition: all 0.3s;

    .overley i {
      position: absolute;
      bottom: 0px;
      right: 22px;
      background: transparent;
      opacity: 0;
      visibility: hidden;
      transition: 0.4s;

      &:before {
        font-size: 30px;
        color: $white;
        font-weight: 400;
        background: $light-blue;
        padding: 0px 14px 4px;
        height: 60px;
        width: 60px;
        line-height: 60px;
        display: block;
        border-radius: 5px;
      }

      &:hover:before {
        background: $dark;
        color: $white;
        transition: all 0.3s ease;
      }
    }
  }

  .content-desc {
    background: $white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    padding: 30px 0;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    border-color: #16AACA2B;

    .product-title {
      font-size: 22px;
      font-weight: 800;
      line-height: 32px;
      margin-bottom: 12px;

      a {
        color: $ocean-blue;
      }
    }

    .price {
      font-size: 22px;
      font-weight: 400;
      line-height: 32px;
      color: $gray-blue;
    }
  }

  &:hover .image-product .overley i {
    opacity: 1;
    visibility: visible;
    bottom: 280px;
  }
}