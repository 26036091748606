/* ------------------------------------
    10. Categories Section CSS
---------------------------------------*/

.rs-categories {
  &.style1 .categories-item {
    border: 1px solid #dfe9eb;
    background: $white;
    overflow: hidden;
    padding: 30px;
    display: block;
    color: $mid-gray;
    border-radius: 5px;
    display: flex;
    align-items: center;

    .icon-part {
      float: left;
      margin-right: 25px;
      width: 70px;
      height: 70px;
      line-height: 70px;
      border-radius: 100%;
      background: rgba(22, 170, 202, 0.2);
      text-align: center;
      transition: all 0.3s ease;

      img {
        -webkit-transition: all 0.4s ease;
        transform: scale(1);
      }
    }

    .content-part .title {
      color: $dark;
      margin-bottom: 5px;
      font-size: 22px;
      transition: all 0.3s ease;
    }

    &:hover {
      background: $light-blue;
      color: $white;
      border-color: $light-blue;

      .icon-part {
        background: $white;

        img {
          transform: scale(0.9);
        }
      }

      .content-part .title {
        color: $white;
      }
    }
  }

  &.main-home .categories-items {
    position: relative;
    transition: all 0.3s ease;

    .cate-images a img {
      box-shadow: 0 0 30px $box-shadow-gray;
      background: $white;
      border-radius: 0 0 5px 5px;
      position: relative;
      transition: all 500ms ease;
      border-radius: 5px;
    }

    .contents {
      position: absolute;
      left: 0px;
      z-index: 3;
      width: 100%;
      text-align: left;
      transition: all 500ms ease;
      padding: 30px 40px;
      bottom: 0;
      display: flex;
      align-items: center;

      .img-part img {
        width: 42px;
        margin: 0 20px 8px 0;
      }

      .content-wrap {
        .title {
          font-size: 22px;
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 5px;

          a {
            color: $white;

            &:hover {
              color: $orange;
            }
          }
        }

        .course-qnty {
          font-size: 16px;
          line-height: 1.4;
          font-weight: 400;
          color: $white;
          transition: all 500ms ease;
          margin: 0px;
        }
      }
    }

    &:before {
      content: "";
      background: -moz-linear-gradient(bottom, $black 0%, rgba(0, 0, 0, 0) 50%);
      background: linear-gradient(bottom, $black 0%, rgba(0, 0, 0, 0) 50%);
      background: -webkit-linear-gradient(bottom, $black 0%, rgba(0, 0, 0, 0) 50%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 0;
      border-radius: 5px;
      transition: all 500ms ease;
      z-index: 1;
    }

    &:hover {
      transform: translateY(-10px);
    }
  }

  &.home9-style .categories-items {
    position: relative;
    transition: all 0.3s ease;

    .images-part a img {
      border-radius: 4px 4px 0px 0px;
    }

    .image-content {
      border-radius: 0px 0px 4px 4px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.18);
      padding: 70px 30px 25px 40px;
      background-color: $white;
      position: relative;

      .effect-icon {
        background: #1c335e;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: -44px;
        text-align: center;
        right: 0;
        margin: 0 auto;
        box-shadow: 0 0 50px $box-shadow-gray;

        i {
          color: $yellow;
          font-size: 35px;
        }
      }

      .title {
        font-size: 25px;
        line-height: 32px;
        font-weight: 700;
        color: $dark-black;
        margin-bottom: 17px;

        a {
          color: $dark-black;

          &:hover {
            color: $yellow;
          }
        }
      }

      .description p {
        font-weight: 400;
        color: $mid-gray;
        font-size: 16px;
        padding-right: 50px;
      }

      .button-bottom .button-effect {
        position: absolute;
        left: 50%;
        bottom: -14px;
        transform: translateX(-50%);
        transition: all .8s ease;
        width: 100%;
        text-align: center;
        visibility: hidden;
        opacity: 0;

        a {
          text-transform: uppercase;
          color: $warm-blue;
          padding: 13px 35px 13px 35px;
          background-color: $yellow;
          border-radius: 30px 30px 30px 30px;

          &:hover {
            color: $white;
            background: $warm-blue;
          }
        }
      }
    }

    &:hover {
      transform: translateY(-10px);

      .image-content {
        .title a {
          color: $yellow;
        }

        .button-bottom .button-effect {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &.home11-style {
    background-position: center;

    .img-part {
      background: url(assets/images/categories/home11/1.jpg);
      background-size: cover;
      background-repeat: no-repeat;
    }

    .main-part {
      background: #e7f8fb;
      padding: 100px 300px 100px 70px;

      .categories-item {
        display: flex;

        .icon-part {
          margin-right: 15px;
        }

        .content-part {
          .title {
            font-size: 22px;
            line-height: 32px;
            font-weight: 800;
            margin-bottom: 10px;

            a {
              color: $deep-purple;

              &:hover {
                color: $light-blue;
              }
            }
          }

          p {
            font-size: 16px;
            line-height: 30px;
            font-weight: 400;
            color: $gray-blue;
            margin: 0;
          }
        }
      }
    }
  }

  &.home-style14 .categories-items {
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;

    .cate-images {
      position: relative;
      transition: all 500ms ease;

      &:before {
        content: "";
        left: 0;
        display: block;
        background: rgba(23, 31, 50, 0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        border-radius: 5px;
      }

      a img {
        box-shadow: 0 0 30px $box-shadow-gray;
        background: $white;
        border-radius: 0 0 5px 5px;
        position: relative;
        transition: all 500ms ease;
        border-radius: 5px;
      }
    }

    .contents {
      position: absolute;
      left: 50%;
      bottom: -185px;
      z-index: 3;
      width: 100%;
      text-align: center;
      transform: translate(-50%, -50%);
      transition: all 500ms ease;
      padding: 26px 20px 20px 20px;

      .img-part img {
        width: 42px;
        margin: 0 0px 15px 0;
      }

      .content-wrap {
        .title {
          font-size: 22px;
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 5px;

          a {
            color: $white;

            &:hover {
              color: $orange;
            }
          }
        }

        .course-qnty {
          color: $white;
          opacity: 0;
          visibility: hidden;
          transition: all 500ms ease;
          margin: 7px 0;
          display: inline-block;
        }

        .btn2 a {
          opacity: 0;
          visibility: hidden;
          transition: all 500ms ease;
          margin-top: 12px;
          background: $orange;
          color: $white;
          display: inline-block;
          padding: 6px 22px;
          border-radius: 5px;
        }
      }
    }

    &:hover {
      transform: translateY(-10px);

      .contents {
        bottom: -80px;

        .content-wrap {
          .course-qnty, .btn2 a {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  &.style2 {
    .owl-stage-outer {
      padding: 20px;
      margin: -20px;
    }

    .categories-items .cate-item {
      position: relative;
      transition: all 500ms ease;
      box-shadow: 0 0 30px $box-shadow-gray;
      background: $white;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 0 10px #eae4e4;
      padding: 30px 35px 35px;
      text-align: center;

      .cate-img img {
        width: 60px;
        margin: 0 auto;
      }

      .cate-content {
        .title {
          font-size: 22px;
          line-height: 32px;
          font-weight: 700;
          margin: 25px 0px 20px 0px;

          a {
            color: $dark-black;

            &:hover {
              color: $orange;
            }
          }
        }

        .course-qnty {
          color: $dark-black;
        }
      }

      &:hover {
        transform: translateY(-10px);
      }
    }

    .owl-nav {
      .owl-next, .owl-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        border: 1px solid $dark-black;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        color: $dark-black;
        background: 0 0;
        transition: .4s;
        margin: 0 auto;
        text-align: center;
      }

      .owl-next i, .owl-prev i {
        margin-top: 13px;
      }

      .owl-next i:before, .owl-prev i:before {
        content: "\f133";
        font-family: Flaticon;
        font-size: 18px;
        line-height: 18px;
        font-weight: 400;
      }

      .owl-next:hover, .owl-prev:hover {
        background: $orange;
        color: $white;
        border: 1px solid $orange;
      }

      .owl-next {
        right: -75px;
        left: unset;

        i:before {
          content: "\f133";
          font-size: 18px;
          font-weight: 400;
        }
      }

      .owl-prev {
        right: unset;
        left: -105px;

        i:before {
          content: "\f134";
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
}