/* -----------------------------------
    01. General CSS
-------------------------------------*/

html, body {
  font-size: 15px;
  color: $mid-gray;
  font-family: 'Rubik', sans-serif;
  vertical-align: baseline;
  line-height: 26px;
  font-weight: 400;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0 0 26px;
  line-height: 1.8;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito', sans-serif;
  color: $dark;
  margin: 0 0 26px;
  line-height: 1.2;
}

h1 {
  font-size: 70px;
  font-weight: 700;
}

h2 {
  font-size: 36px;
  font-weight: 700;
}

h3 {
  font-size: 26px;
  font-weight: 700;
}

h4 {
  font-size: 24px;
  font-weight: 700;
}

h5 {
  font-size: 18px;
  font-weight: 700;
}

h6 {
  font-size: 16px;
  font-weight: 700;
}

.home-style2 {
  h1, h2, h3, h4, h5, h6 {
    color: $dark-blue;
  }
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.bold-text {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
}

.extra-bold {
  font-weight: 800 !important;
}

a {
  color: $light-blue;
  transition: all 0.3s ease;
  text-decoration: none !important;
  outline: none !important;

  &:active, &:hover {
    text-decoration: none;
    outline: 0 none;
    color: $light-blue;
  }
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
}

::-moz-selection, ::selection {
  background: $light-blue;
  text-shadow: none;
  //color: $white;
}

.border-none:last-child {
  border-right: none !important;
}

.width-unset {
  width: unset !important;
}

.white-bg {
  background-color: $white;
}

.primary-bg {
  background: $light-blue;
}

.secondary-bg {
  background: $middle-blue;
}

.title-bg {
  background: $dark;
}

.gray-bg {
  background: $light-gray;
}

.gray-bg3 {
  background: $light-gray3;
}

.gray-bg2 {
  background: #e7f6f9;
}

.gray-bg4 {
  background: $light-gray4;
}

.event-bg {
  background: $light-white;
}

.event2-bg {
  background: #F4F6F5;
}

.dark-red {
  background: #1D0E15;
}

.bg-light-gray {
  background: $lightest-gray;
}

.transparent-bg {
  background: transparent;
}

.home5color {
  color: $gray-blue !important;
}

.bg-fixed {
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.bg1 {
  background: url(assets/images/bg/bg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg2 {
  background: url(assets/images/bg/bg2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg3 {
  background: url(assets/images/bg/bg3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg4 {
  background: url(assets/images/bg/home6/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg5 {
  background: url(assets/images/bg/home7/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
}

.bg6 {
  background: url(assets/images/bg/home-8-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg7 {
  background: url(assets/images/bg/home8-bg2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg8 {
  background: url(assets/images/bg/home12/dotted.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 0 50px;
}

.bg9 {
  background: url(assets/images/bg/home13/counter-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg10 {
  background: url(assets/images/bg/home13/logo-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg11 {
  background: url(assets/images/bg/home13/subscribe-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg12 {
  background: url(assets/images/bg/home13/footer-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.bg13 {
  background: url(assets/images/bg/video-text-bg15.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 60px 140px 60px;
}

.course-shape-bg {
  background: url(assets/images/bg/course-shape-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-unset {
  background: unset !important;
}

.rs-menu-toggle {
  color: $light-blue;
  text-align: right;
}

button {
  cursor: pointer;
}

.glyph-icon:before {
  margin: 0;
}

input:focus, select:focus, textarea:focus, button:focus {
  outline: none !important;
}

.pagenav-link {
  ul {
    border: 1px solid $border-white;
    border-radius: 3px;
    display: inline-block;
    padding: 0 3px;

    li {
      display: inline-block;
      padding: 0 18px;
      margin: 14px 0;
      border-right: 1px solid $border-right-white;
      color: $light-blue;
      font-size: 16px;

      a {
        color: $mid-gray;

        i:before {
          color: $mid-gray;
          font-size: 13px;
          transition: all 0.3s ease;
        }

        &:hover {
          color: $light-blue;

          i:before {
            color: $light-blue;
          }
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  &.orange-color ul li {
    color: $orange;

    a:hover {
      color: $orange;

      i:before {
        color: $orange;
      }
    }
  }
}

.gridFilter {
  button {
    background: transparent;
    border: 0;
    font-size: 15px;
    font-weight: 700;
    outline: none;
    color: $mid-gray;
    cursor: pointer;

    &.active {
      color: $orange;
    }

    + button {
      margin-left: 20px;
    }
  }

  &.style2 {
    button {
      background: $light-gray4;
      border: none;
      border-radius: 3px;
      font-weight: 500;
      font-size: 16px;
      color: $dark-black;
      padding: 10px 30px;
      outline: none;
      margin: 0 10px 20px;

      &.active, &:hover {
        background: $white;
        color: $orange;
        box-shadow: 0 0 30px $box-shadow-gray;
      }
    }

    &.modify1 button {
      margin: 0 0px 20px;
    }
  }
}

blockquote {
  margin: 35px 0;
  padding: 40px;
  color: $lighter-gray;
  position: relative;
  background: $white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 55px;
  font-weight: 400;
  font-style: italic;
  text-align: left;
  clear: both;
  box-shadow: 0 0 150px $box-shadow-gray;

  &:before {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 0;
    height: 100%;
    width: 10px;
    background: $light-blue;
  }

  p {
    font-size: 16px;
    margin: 0;
  }
}

.check-square li {
  position: relative;
  padding-left: 35px;
  line-height: 34px;
  border: none;
  color: $white;

  &:before {
    position: absolute;
    top: 2px;
    left: 0;
    z-index: 0;
    content: "\f046";
    font-family: fontawesome;
    color: $orange;
    font-size: 20px;
  }
}

/*-- Blog Button Css --*/

.blog-btn {
  color: $dark;
  display: inline-block;
  position: relative;
  font-size: 15px;
  padding-right: 43px;
  font-weight: 600;

  &:after {
    position: absolute;
    content: "\f133";
    right: 20px;
    top: 50%;
    font-size: 15px;
    font-weight: 600;
    color: $dark;
    font-family: "Flaticon";
    transform: translateY(-50%);
    transition: all 0.3s ease;
  }

  &:hover {
    color: $light-blue;

    &:after {
      right: 17px;
      color: $light-blue;
    }
  }
}

h1.sub-title.primary {
  font-size: 36px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 800;
  line-height: 2.5rem;
}

#index-title {
  font-weight: 400;
}

.oipage-bg {
  padding-top: 40px;
  padding-bottom: 40px;

  .oipage-title {
    text-align: center;
    font-size: 50px;
  }

  .oipage-text {
    font-size: 16px;
  }
}

.full-size-image {
  width: 393px !important;
  height: 250px !important;
}

@media (min-width: 1367px) and (max-width: 1439px) {
  .full-size-image, .blog-img {
    width: 350px !important;
    height: 230px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .full-size-image, .blog-img {
    width: 360px !important;
    height: 230px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .full-size-image, .blog-img {
    width: 450px !important; //290
    height: 330px !important; //200
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .full-size-image, .blog-img {
    width: 690px !important;
    height: 530px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .full-size-image, .blog-img {
    width: 510px !important;
    height: 390px !important;
  }
}

@media (max-width: 576px)  {
  .full-size-image, .blog-img {
    width: auto !important;
    height: 300px !important;
  }
  li.first, li.last, li.next::before {
    display: none !important;
  }
}